import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Users } from "../../hooks/useUserHook";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { SearchBox } from "../../uis/SearchBox/SearchBox";
import Avatar from "../../uis/Avatar";
import { OrderStatusWithId } from "../../utils/types/general_type";
import { ReadonlyWood } from "../../hooks/Wood/type";
import { ReadonlyLog } from "../../hooks/Log/type";
import { GetBlueprint } from "../../hooks/Blueprint/type";
import { Title } from "../../uis/Title";
import { Talk } from "../../uis/Talk";
import { ItemEntity, ItemType } from "../../utils/types/item_type";
import { useCreateOrder } from "../../hooks/useOrderHook";
import { UUID } from "crypto";
import { Stock } from "../../uis/Stock";
import { ReadonlyFree } from "../../hooks/Free/type";
import { ORDER_BASE_PATH } from "../../utils/constant";
import { RenderDetails } from "../../uis/RenderDetails";
import { UserContext } from "../../contexts/UserContext";
import { Industry } from "../../utils/industry";

// 型ガード
function isLog(data: ItemEntity): data is ReadonlyLog {
  return (data as ReadonlyLog).log_details !== undefined;
}
function isWood(data: ItemEntity): data is ReadonlyWood {
  return (data as ReadonlyWood).wood_details !== undefined;
}
function isBlueprint(data: ItemEntity): data is GetBlueprint {
  return (data as GetBlueprint).blueprint_orders !== undefined;
}
function isFree(data: ItemEntity): data is ReadonlyFree {
  return (data as ReadonlyFree).free_details !== undefined;
}

interface MailingComponentProps<TData> {
  idParamName: string;
  useGetData: (params: OrderStatusWithId) => {
    data: TData | undefined;
    error: Error | null;
    isPending: boolean;
  };
  useSearchUsers: (params: { searchText: string; limit: number }) => {
    data: Users | undefined;
    error: Error | null;
  };
  itemName: ItemType;
}

export const MailingComponent = <TData extends ItemEntity>({
  idParamName,
  useGetData,
  useSearchUsers,
  itemName,
}: MailingComponentProps<TData>) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchText, setSearchText] = useState("");
  const [userIds, setUserIds] = useState<UUID[]>([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const params = useParams<{ [key: string]: string }>();
  const idString = params[idParamName];
  const id = Number(idString);

  // データ取得
  const { data, error, isPending } = useGetData({
    id,
    isTemporarilyStored: true,
    isOrdered: false,
  });
  // ユーザー検索
  const { data: searchUsers, error: searchError } = useSearchUsers({
    searchText,
    limit: 10,
  });

  // 注文作成フック
  const { mutateAsync, isPending: isMutatePending } = useCreateOrder();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };
  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  // 注文ボタン押下で発火
  const handleOrder = async () => {
    if (data) {
      try {
        await mutateAsync({
          itemName,
          itemId: data.id,
          isAll: allSelected,
          userIds,
        });
        navigate(ORDER_BASE_PATH);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // 全選択/全解除
  const handleAllOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && searchUsers) {
      const allUserIds = searchUsers
        .map((user) => user.user_id)
        .filter((id): id is UUID => id !== undefined);
      setUserIds(allUserIds);
    } else {
      setUserIds([]);
    }
  };

  // 個別のチェックボックス
  const handleUserCheckboxChange =
    (userId?: UUID) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && userId) {
        setUserIds((prev) => [...prev, userId]);
      } else {
        setUserIds((prev) => prev.filter((id) => id !== userId));
      }
    };

  // 全員選択状態の判定
  useEffect(() => {
    if (searchUsers) {
      const allSelectedNow = searchUsers.every((user) =>
        user.user_id ? userIds.includes(user.user_id) : false,
      );
      setAllSelected(allSelectedNow);
    }
  }, [userIds, searchUsers]);

  if (isPending) {
    return <Loading />;
  }
  if (error || searchError) {
    return <Error message={error?.message || searchError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* --- 修正ポイント：戻るリンク --- */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {/* ここで blueprint の場合は /order/create_blueprint_list/:id に戻る */}
        <Link to={`${ORDER_BASE_PATH}/create_${itemName}_list/${id}`}>
          作成に戻る
        </Link>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowDetails(!showDetails)}
        >
          リストの詳細を確認する
        </Button>
      </Box>
      {showDetails && data && (
        <RenderDetails
          item={data}
          detail={{
            log_details: isLog(data) ? data.log_details : undefined,
            free_details: isFree(data) ? data.free_details : undefined,
            blueprint_details: isBlueprint(data)
              ? data.blueprint_details
              : undefined,
            wood_details: isWood(data) ? data.wood_details : undefined,
          }}
          isShownAmount={
            itemName === "free" ||
            (itemName === "log" && user?.industry === Industry.Market)
          }
        />
      )}
      <Title title="送信先選択" />
      <SearchBox
        placeholder="会社を検索"
        isMobile={isMobile}
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                onChange={handleAllOrderChange}
                color="primary"
              />
            }
            label="全員に注文"
          />
        </ListItem>
        {searchUsers &&
          searchUsers.length > 0 &&
          searchUsers.map((user) => (
            <ListItem
              key={user.id}
              alignItems="flex-start"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox
                edge="start"
                checked={user.user_id ? userIds.includes(user.user_id) : false}
                onChange={handleUserCheckboxChange(user.user_id ?? undefined)}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": `checkbox-list-label-${user.id}`,
                }}
              />
              <ListItemAvatar>
                <Avatar user={user} isMobile={isMobile} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" component="span">
                        {user.company_name}
                      </Typography>
                      <Talk userId={user.user_id} />
                    </Box>
                    {(user.stock_log?.id || user.stock_wood?.id) && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" component="span">
                          {user.company_name}
                        </Typography>
                        <Stock
                          itemId={id}
                          stockId={user.stock_log?.id ?? user.stock_wood?.id}
                          itemName={itemName}
                        />
                      </Box>
                    )}
                  </Box>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {user.address}
                  </Typography>
                }
              />
            </ListItem>
          ))}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        {/* ログの場合は total_amount が 50円以上かどうかをチェック (例) */}
        {(data &&
          isLog(data) &&
          (data?.total_item_and_detail_amount ?? 0) >= 50) ||
        itemName !== "log" ? (
          userIds.length > 0 ? (
            <Button
              variant="contained"
              onClick={handleOrder}
              disabled={isMutatePending}
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              注文する
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "8px",
              }}
            >
              選択する
            </Button>
          )
        ) : (
          <Typography>
            合計金額は50円以上に設定するようにしてください。
          </Typography>
        )}
      </Box>
    </Box>
  );
};
