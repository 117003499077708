export interface OrderStatus {
  isTemporarilyStored: boolean;
  isOrdered: boolean;
}

export interface SearchRequest {
  searchText: string;
  limit?: number;
}

export interface SearchIdRequest extends SearchRequest {
  id?: number;
}

export interface OrderStatusWithId extends OrderStatus {
  id: number;
}

export enum OrderStatusEnum {
  is_pending = "is_pending",
  is_denied = "is_denied",
  is_received = "is_received",
  is_applying = "is_applying",
  is_contract_signed = "is_contract_signed",
}

enum StatusEnum {
  success = "success",
  pending = "pending",
  failure = "failure",
}
export interface Status {
  status: StatusEnum;
  failure_reason: string;
}

export enum OrderPaymentStatusEnum {
  isNotPayed = "is_not_payed",
  isRequired = "is_required",
  isSucceeded = "is_succeeded",
}

interface Response {
  message: string;
}

interface ResponseWithId extends Response {
  id: number;
}

interface ResponseWithURL extends Response {
  url?: string;
}

export type ReadonlyResponse = Readonly<Response>;
export type ReadonlyResponseWithId = Readonly<ResponseWithId>;
export type ReadonlyResponseWithURL = Readonly<ResponseWithURL>;

type IntersectionOf<T extends any[]> = T extends [infer First, ...infer Rest]
  ? First & IntersectionOf<Rest>
  : unknown;

// Helper type to extract common keys across all types
type CommonKeys<T extends any[]> = keyof IntersectionOf<T>;

// Helper type to ensure all types have the same property type for a given key
type ArePropertyTypesSame<T extends any[], K extends keyof any> = T extends [
  infer First,
  ...infer Rest,
]
  ? First extends Record<K, any>
    ? Rest extends Record<K, First[K]>[]
      ? First[K]
      : never
    : never
  : never;

// Main type to pick properties common to all types with identical types
export type PickSameProperties<T extends any[]> = Pick<
  IntersectionOf<T>,
  {
    [K in CommonKeys<T>]: ArePropertyTypesSame<T, K> extends never ? never : K;
  }[CommonKeys<T>]
>;

// // Helper type to ensure all types have the same property type for a given key
// type ArePropertyTypesSameByOptional<T extends any[], K extends keyof any> = T extends [
//   infer First,
//   ...infer Rest,
// ]
//   ? First extends { [P in K]?: infer PType }
//     ? Rest extends Array<{ [P in K]?: PType }>
//       ? PType
//       : never
//     : never
//   : never;

// // Main type to pick properties common to all types with identical types
// export type PickOptionalSameProperties<T extends any[]> = Pick<
//   IntersectionOf<T>,
//   {
//     [K in CommonKeys<T>]: ArePropertyTypesSameByOptional<T, K> extends never ? never : K;
//   }[CommonKeys<T>]
// >;

export interface TimeStamp {
  created_at: Date;
  updated_at: Date;
}

export type OmitStrict<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type OrderStatusType = "receiving" | "pending" | "denying";

export type OrderStatusWithApplying = OrderStatusType | "applying";

export interface PaginationParams {
  page: number;
  pageSize?: number;
}

export interface SearchTextWIthPaginationParams extends PaginationParams {
  searchText: string;
}
