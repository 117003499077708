import React, { useState, useMemo } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { SearchTransaction } from "../../../../utils/schema";
import { useLocation } from "react-router-dom";
import { SearchTransactionBox } from "../../../../uis/SearchBox/SearchTransactionBox";
import { useGetOrderTransactionQuantityByRange } from "../../../../hooks/useOrderHook";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const TransactionVolume: React.FC = () => {
  const location = useLocation();

  // 現在の年月を取得して、初期表示の範囲に設定
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // 1-12 → "01"-"12"

  const defaultStartDate = `${currentYear}-01`; // 今年の1月
  const defaultEndDate = `${currentYear}-${currentMonth}`; // 今年の現在月

  // URLのクエリパラメータを解析
  const getQueryParams = (): SearchTransaction => {
    const params = new URLSearchParams(location.search);
    const startDate = params.get("startDate") || defaultStartDate;
    const endDate = params.get("endDate") || defaultEndDate;
    const type = (params.get("type") as "order" | "receive" | "both") || "both";

    return {
      startDate,
      endDate,
      type,
    };
  };

  // 検索パラメータのステート（初期値を設定）
  const [searchParams, setSearchParams] =
    useState<SearchTransaction>(getQueryParams());

  // APIからデータを取得
  const { data, error, isPending } =
    useGetOrderTransactionQuantityByRange(searchParams);

  // Recharts用のデータを作成（月毎の合計取引数量）
  const chartData = useMemo(() => {
    if (!data) return [];

    // data は MonthlyTransactionQuantities の配列
    // 各月の transactionQuantities を合計し、グラフ用のオブジェクトを生成
    return data.map((item) => {
      const { year, month, transactionQuantities } = item;
      const totalQuantity = transactionQuantities.reduce(
        (sum, tq) => sum + (tq.transactionQuantity ?? 0),
        0,
      );
      return {
        // 月表記を分かりやすくするために、 2024年7月 → "2024-07" などに
        name: `${year}年 ${month}月`,
        totalQuantity,
      };
    });
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Box p={4} sx={{ width: "95%", margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        取引量
      </Typography>

      {/* フィルタリングフォーム */}
      <SearchTransactionBox
        setSearchParams={setSearchParams}
        getQueryParams={getQueryParams}
      />

      {/* グラフ描画 */}
      <Paper elevation={3} sx={{ padding: 3 }}>
        {chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend />
              <Bar dataKey="totalQuantity" name="取引量" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1" textAlign="center">
            該当するデータがありません
          </Typography>
        )}
      </Paper>
    </Box>
  );
};
