import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import { useGetImageFromS3 } from "../../../hooks/S3/useS3ImageHook";

interface ImageFormProps {
  selectedFile?: File;
  filePath: string | undefined;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const CommonImageForm: React.FC<ImageFormProps> = ({
  selectedFile,
  filePath,
  handleFileChange,
  disabled = false,
}) => {
  // S3 からファイルを取得するフック
  // imageData は画像や PDF の base64 データが返る想定
  const {
    data: fileData,
    error: fileError,
    isLoading: fileLoading,
  } = useGetImageFromS3(filePath);

  /**
   * PDF かどうかをファイル名や MIME タイプから判定
   */
  const isPdfFile = React.useMemo(() => {
    if (selectedFile) {
      return selectedFile.type === "application/pdf";
    }
    // S3 に格納されているファイルパスで判定（拡張子チェック）
    return filePath?.toLowerCase().endsWith(".pdf") ?? false;
  }, [selectedFile, filePath]);

  // プレビューをレンダリングする関数
  const renderPreview = () => {
    /**
     * 1. 選択中の File がない && filePath がある → S3 から取得したファイルを表示
     * 2. selectedFile がある → ローカルでアップロードしたファイルをプレビュー
     * 3. それ以外 → 未選択状態の表示
     */

    // ローディング／エラー
    if (!selectedFile && filePath) {
      if (fileLoading) {
        return <Loading />;
      }
      if (fileError) {
        return <Error message={fileError.message} />;
      }
    }

    // ファイルの種類によって表示を切り替え
    if (selectedFile) {
      // ローカルファイル
      if (selectedFile.type.startsWith("image/")) {
        // 画像プレビュー
        return (
          <Box
            component="img"
            src={URL.createObjectURL(selectedFile)}
            alt="Selected"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        );
      } else if (selectedFile.type === "application/pdf") {
        // PDF プレビュー
        return (
          <iframe
            src={URL.createObjectURL(selectedFile)}
            style={{
              width: "100%",
              height: "400px",
              border: "none",
              borderRadius: "10px",
            }}
            title="PDF Preview"
          />
        );
      } else {
        // その他ファイル（プレビューできない）
        return (
          <Typography variant="body1" color="textSecondary">
            プレビューできないファイルタイプです。
          </Typography>
        );
      }
    } else if (filePath && fileData) {
      // S3 から取得したファイルのプレビュー
      if (isPdfFile) {
        const fileName = filePath.split(/[\\/]/).pop() || "ファイル名不明";
        return (
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#f7f7f7",
              borderRadius: "10px",
              padding: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              アップロード済みファイル
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {fileName}
            </Typography>
          </Box>
        );
      } else {
        // 画像
        return (
          <Box
            component="img"
            src={fileData}
            alt="Selected"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        );
      }
    } else {
      // ファイル未選択時の表示
      return (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          {!disabled && (
            <>
              <IconButton color="primary" component="label">
                <PhotoCamera sx={{ fontSize: 50 }} />
                <input
                  hidden
                  // ここで PDF を受け付ける
                  accept="image/*,application/pdf"
                  type="file"
                  onChange={handleFileChange}
                />
              </IconButton>
              <Typography>画像／PDFを選択</Typography>
            </>
          )}
          {disabled && (
            <Typography color="textSecondary">
              アップロードされたファイルがありません
            </Typography>
          )}
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 3,
        marginBottom: 3,
        position: "relative",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        overflow: "hidden", // PDF表示時にスクロールが出る場合はこちらで制御
      }}
    >
      {/* 
        disabled でなければ、上から透明な <IconButton> をかぶせて
        いつでもファイルを変更できるようにする 
      */}
      {!disabled && (
        <IconButton
          color="primary"
          component="label"
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <input
            hidden
            accept="image/*,application/pdf"
            type="file"
            onChange={handleFileChange}
          />
        </IconButton>
      )}
      {renderPreview()}
    </Box>
  );
};
