import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { MenuItemComponent } from "../../../../uis/Item/MenuItem";
import { useCreateLogMarketPrice } from "../../../../hooks/useLogMarketPriceHook";
import { DATA_LOG_MARKET_PRICE_PATH } from "../../../../utils/constant";

export const LogMarketPrice = () => {
  const navigate = useNavigate();
  const { mutateAsync } = useCreateLogMarketPrice();
  const submitProcessing = useRef(false); // ① 送信処理を管理するuseRefを作成する

  const menuItems = [
    {
      title: "丸太相場",
      items: [
        {
          text: "現在の丸太相場",
          link: `${DATA_LOG_MARKET_PRICE_PATH}/latest`,
        },
        { text: "過去の丸太相場", link: `${DATA_LOG_MARKET_PRICE_PATH}/past` },
      ],
    },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    if (submitProcessing.current) return; // ② 送信処理中かを判定する（trueなら抜ける）

    switch (link) {
      case `${DATA_LOG_MARKET_PRICE_PATH}/latest`:
        submitProcessing.current = true; // ③ 送信処理中フラグを立てる
        try {
          await mutateAsync();
          navigate(link);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      default:
        navigate(link);
    }
  };

  return (
    <MenuItemComponent
      menuItems={menuItems}
      handleClick={handleClick}
      disabled={submitProcessing.current}
    />
  );
};
