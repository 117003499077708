import React, { useContext } from "react";
import { Tabs, Tab, SxProps, TabsProps } from "@mui/material";
import { UserContext } from "../../contexts/UserContext";
import { UnreadBadge } from "../Badge";

export interface TabDefinition {
  label: string;
}

export interface TabProps<T extends TabDefinition> {
  tabs: ReadonlyArray<T>;
  tabIndicatorProps?: TabsProps["TabIndicatorProps"];
  tabsSx?: SxProps;
  tabSx?: (selected: boolean) => SxProps;
  spanStyle?: (selected: boolean) => React.CSSProperties;
}

interface TabBarProps<T extends TabDefinition> extends TabProps<T> {
  selectedTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const TabBar = ({
  tabs,
  tabSx,
  tabsSx,
  tabIndicatorProps,
  handleTabChange,
  selectedTab,
  spanStyle,
}: TabBarProps<TabDefinition>) => {
  const user = useContext(UserContext);
  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      aria-label="navigation tabs"
      variant="fullWidth"
      sx={{
        width: "100%",
        ...tabsSx,
      }}
      TabIndicatorProps={
        tabIndicatorProps || { style: { backgroundColor: "green" } }
      }
    >
      {tabs.map((tab, index) => {
        const isSelected = selectedTab === index;
        const defaultTabSx: SxProps = {
          color: isSelected ? "green" : "inherit",
          border: "1px solid",
          borderColor: isSelected ? "green" : "lightgray",
          // 重複しないよう、全てのタブで右側の枠線は除外（最終タブのみ例外）
          ...(index !== tabs.length - 1 && !isSelected
            ? { borderRight: "none" }
            : {}),
          // ホバー時の視覚的フィードバック
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          transition: "background-color 0.3s ease",
        };

        return (
          <Tab
            key={tab.label}
            label={
              <span style={spanStyle ? spanStyle(isSelected) : undefined}>
                {tab.label}
                {tab.label === "メッセージルーム" &&
                  (user?.unread_message_count ?? 0) > 0 && (
                    <UnreadBadge
                      badgeContent={user?.unread_message_count ?? 0}
                      color="error"
                      overlap="circular"
                    />
                  )}
                {tab.label === "契約関連のメッセージルーム" &&
                  (user?.unread_order_message_count ?? 0) > 0 && (
                    <UnreadBadge
                      badgeContent={user?.unread_order_message_count ?? 0}
                      color="error"
                      overlap="circular"
                    />
                  )}
              </span>
            }
            sx={tabSx ? tabSx(isSelected) : defaultTabSx}
          />
        );
      })}
    </Tabs>
  );
};
