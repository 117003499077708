import { LogOrder } from "../../../hooks/Log/type";
import {
  ColumnDefinitionForTable,
  TableComponent,
  TableComponentProps,
} from "../../../uis/Table";
import { logWithQuotationAmountColumnsForTable } from "../../../utils/columns";
import { defaultLogRow } from "../../../utils/rows";
import { useWatch } from "react-hook-form";

export const LogQuotationTable = ({
  register,
  errors,
  control,
}: TableComponentProps<LogOrder>) => {
  const watchedTotalOrderAmount = useWatch({
    control,
    name: "total_order_amount",
  });
  const watchedTotalItemAmount = useWatch({
    control,
    name: "item.total_item_amount",
  });
  const modifiedColumns = logWithQuotationAmountColumnsForTable.map((column) =>
    column.field === "amount"
      ? { ...column, label: "丸太相場または相手ユーザーが算出した金額" }
      : column,
  );
  const filteredColumns = modifiedColumns.filter((col) => {
    if (watchedTotalOrderAmount && col.field === "quotation_amount") {
      return false;
    }
    if (watchedTotalItemAmount && col.field === "amount") {
      return false;
    }
    return true;
  });
  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="item.log_details"
      columns={filteredColumns as ColumnDefinitionForTable<unknown>[]}
      defaultRow={defaultLogRow}
      disabled={true}
      control={control}
    />
  );
};
