import { useContext, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { Error } from "../../uis/Error";
import { SearchBox } from "../../uis/SearchBox/SearchBox";
import Avatar from "../../uis/Avatar";
import { OrderDetailModal } from "../../uis/Modals/OrderDetailModal";
import { ItemType, OrderDetailModalType } from "../../utils/types/item_type";
import { toast } from "react-toastify";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Color } from "../../utils/color";
import { FileAndDetailsComponent } from "../../uis/FileAndDetailsComponent";
import { Title } from "../../uis/Title";
import { conversionItemName } from "../../utils/conversion";
import {
  useApplyForOrderAgain,
  useContractOrder,
  useSearchOrderStatuses,
} from "../../hooks/useOrderHook";
import { PaginationComponent } from "../../uis/Pagination";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";
import { useNavigate } from "react-router-dom";
import { UnreadBadge } from "../../uis/Badge";
import { BaseOrder } from "../../utils/types/base_type";
import { OrderStatusEnum } from "../../utils/types/general_type";
import { TotalAmount } from "../../uis/TotalAmount";
import { Industry } from "../../utils/industry";
import { UserContext } from "../../contexts/UserContext";

interface OrderComponentProps {
  itemName: ItemType;
}

/**
 * 状態ごとのボタンを返す関数
 */
const getOrderActionButton = (
  order: BaseOrder,
  itemId: number | undefined,
  handleApplyForOrderAgain: (
    orderId: number,
    isContract: boolean,
    itemId?: number,
  ) => Promise<void> | void,
  isPending: boolean,
  isContractPending: boolean,
) => {
  // デフォルトは「承諾」→「発注する」
  let buttonText = "発注する";
  let buttonStyle = {
    backgroundColor: "red",
    color: "white",
    marginRight: "8px",
  };
  let isContract = true;
  let isDisabled = false;

  // 2度注文を保留・辞退したユーザー
  if (order.is_denied_sended && order.status !== OrderStatusEnum.is_received) {
    buttonText = "再度辞退しました";
    buttonStyle = {
      backgroundColor: "black",
      color: "white",
      marginRight: "8px",
    };
    isContract = false;
    isDisabled = true;
  } else if (order.status === OrderStatusEnum.is_denied) {
    // 保留・辞退 -> 「再度注文する」
    buttonText = "再度注文する";
    buttonStyle = {
      backgroundColor: "black",
      color: "white",
      marginRight: "8px",
    };
    isContract = false;
    isDisabled = false;
  } else if (order.status !== OrderStatusEnum.is_received) {
    // 申請中 -> 「契約する」ボタン
    buttonText = "契約する";
    buttonStyle = {
      backgroundColor: Color.Main,
      color: "white",
      marginRight: "8px",
    };
    isContract = true;
  }

  return (
    <Button
      variant="contained"
      size="small"
      sx={buttonStyle}
      onClick={() => handleApplyForOrderAgain(order.id, isContract, itemId)}
      disabled={isPending || isContractPending || isDisabled}
    >
      {buttonText}
    </Button>
  );
};

/**
 * メインコンポーネント
 */
export const OrderComponent = ({ itemName }: OrderComponentProps) => {
  const user = useContext(UserContext);
  // カスタムフック
  const {
    page,
    searchText,
    statuses,
    handlePageChange,
    handleSearchChange,
    handleClick,
  } = useSearchAndPaginationHook();
  const navigate = useNavigate();

  const [searchClicked, setSearchClicked] = useState(false);
  const [order, setOrder] = useState<OrderDetailModalType | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, error } = useSearchOrderStatuses({
    type: itemName,
    searchText,
    statuses,
    page,
  });
  const { mutateAsync: mutateApplyAgainAsync, isPending } =
    useApplyForOrderAgain();
  const { mutateAsync: mutateContractAsync, isPending: isContractPending } =
    useContractOrder();

  // 開閉状態を管理
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});

  // タイトル行の開閉ボタン
  const handleToggle = (id: number) => {
    setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // 検索ボックスのクリックハンドラ
  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  // 再注文 or 契約等のハンドラ
  const handleApplyForOrderAgain = async (
    orderId: number,
    isContract: boolean,
    itemId?: number,
  ) => {
    if (!itemId) {
      toast.error("エラーが発生しました");
      return;
    }
    try {
      if (isContract) {
        const res = await mutateContractAsync({
          type: itemName,
          orderId,
          itemId,
        });
        navigate(`/talk/${itemName}_order_message_room/${res.id}`);
      } else {
        await mutateApplyAgainAsync({ type: itemName, orderId });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "95%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        height: "90vh", // 必要に応じて調整
      }}
    >
      {/* タイトル */}
      <Title title={`${conversionItemName(itemName)}リスト　注文申請中`} />
      {/* 検索ボックス */}
      <SearchBox
        placeholder="タイトルを検索"
        isMobile={isMobile}
        onClick={handleSearchBoxClick}
        onChange={handleSearchChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      {/* ステータスフィルタ */}
      <Box sx={{ mb: 2 }}>
        <Button onClick={() => handleClick("applying")}>
          {statuses.includes("applying") ? "申請中を取り消す" : "申請中"}
        </Button>
        <Button onClick={() => handleClick("receiving")}>
          {statuses.includes("receiving") ? "承諾を取り消す" : "承諾"}
        </Button>
        <Button onClick={() => handleClick("pending")}>
          {statuses.includes("pending") ? "保留を取り消す" : "保留"}
        </Button>
        <Button onClick={() => handleClick("denying")}>
          {statuses.includes("denying") ? "辞退を取り消す" : "辞退"}
        </Button>
      </Box>
      {/* リスト部分（スクロール領域） */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {data &&
            data.items.length > 0 &&
            data.items.map((item) => {
              const open = openItems[item?.id || 0] || false;
              return (
                <List
                  key={item.id}
                  sx={{
                    border: "1px solid gray",
                    borderRadius: "8px",
                    mb: 2,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  {/* タイトル行 */}
                  <ListItem
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "8px",
                      borderBottomRightRadius: open ? 0 : "8px",
                      borderBottomLeftRadius: open ? 0 : "8px",
                      background: open ? Color.Main : "#f0f0f0",
                      mb: open && item.orders.length > 0 ? 2 : 0,
                    }}
                  >
                    {/* タイトル */}
                    <Box
                      sx={{
                        color: open ? "white" : "black",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.title || "タイトルが存在しません"}
                      {item.orders.some(
                        (it) =>
                          (it.status === OrderStatusEnum.is_denied &&
                            !it.is_denied_sended) ||
                          it.status === OrderStatusEnum.is_received,
                      ) && (
                        <UnreadBadge
                          badgeContent={
                            item.orders.filter(
                              (it) =>
                                (it.status === OrderStatusEnum.is_denied &&
                                  !it.is_denied_sended) ||
                                it.status === OrderStatusEnum.is_received,
                            ).length
                          }
                          color="error"
                          overlap="circular"
                        />
                      )}
                    </Box>

                    {/* 開閉ボタン */}
                    <IconButton onClick={() => handleToggle(item.id)}>
                      {open ? (
                        <ExpandLess sx={{ color: open ? "white" : "black" }} />
                      ) : (
                        <ExpandMore sx={{ color: open ? "white" : "black" }} />
                      )}
                    </IconButton>
                  </ListItem>
                  {/* 開いている場合のみ、内部の注文情報を表示 */}
                  {open && item.orders.length > 0 && (
                    <Box>
                      {item.orders.map((order) => (
                        <ListItem
                          key={order.id}
                          alignItems="center"
                          sx={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 2,
                          }}
                        >
                          {/* ユーザー名 & 金額 & 再注文ボタン */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              width: isMobile ? "100%" : "45%",
                              mb: isMobile ? 1 : 0,
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar user={order.user} isMobile={isMobile} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`会社名:${order.user.company_name}`}
                              secondary={
                                <Box>
                                  <TotalAmount
                                    item={item}
                                    order={order}
                                    isTotalItemAmountShown={
                                      (itemName === "log" &&
                                        user?.industry === Industry.Market) ||
                                      itemName !== "log"
                                    }
                                  />
                                  {/* 発注/契約/再度注文ボタン */}
                                  {order.status ===
                                    OrderStatusEnum.is_received ||
                                  order.status === OrderStatusEnum.is_denied
                                    ? getOrderActionButton(
                                        order,
                                        item.id,
                                        handleApplyForOrderAgain,
                                        isPending,
                                        isContractPending,
                                      )
                                    : null}
                                </Box>
                              }
                              secondaryTypographyProps={{ component: "div" }}
                              sx={{ ml: 2 }}
                            />
                          </Box>
                          {/* 詳細・ファイルダウンロードセクション（右側） */}
                          <Box sx={{ width: "45%" }}>
                            <FileAndDetailsComponent
                              order={order}
                              itemType={itemName}
                              item={item}
                              detail={{
                                log_details: item.log_details,
                                wood_details: item.wood_details,
                                blueprint_details: order.blueprint_details,
                                free_details: item.free_details,
                              }}
                              setOrder={setOrder}
                              isShown={
                                order.status === OrderStatusEnum.is_received
                              }
                              isMobile={isMobile}
                            />
                          </Box>
                        </ListItem>
                      ))}
                    </Box>
                  )}
                </List>
              );
            })}
        </List>
      </Box>
      {/* ページネーション */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isMobile={isMobile}
        />
      )}
      {/* 詳細モーダル */}
      {order !== null && order.item && (
        <OrderDetailModal
          open={order !== null}
          onClose={() => setOrder(null)}
          itemType={order.itemType}
          item={order.item}
          order={order.order}
          detail={order.detail}
          isTotalItemAmountShown={
            itemName !== "log" ||
            (itemName === "log" && user?.industry === Industry.Market)
          }
        />
      )}
    </Box>
  );
};
