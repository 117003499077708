import { Box, Button } from "@mui/material";
import { Color } from "../../utils/color";

interface BlackButtonProps {
  isMobile: boolean;
  text: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

export const BlackButton = ({
  isMobile,
  text,
  onClick,
  type,
  disabled = false,
}: BlackButtonProps) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "black",
        color: "white",
        borderRadius: "20px",
        marginTop: isMobile ? 1 : 2,
        padding: isMobile ? "8px 16px" : "10px 20px",
        fontSize: isMobile ? "0.8rem" : "1rem",
        width: "45%",
      }}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

interface WhiteButtonProps {
  isMobile: boolean;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const WhiteButton = ({
  isMobile,
  text,
  onClick,
  disabled = false,
}: WhiteButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: "white",
        color: "black",
        borderColor: "black",
        borderRadius: "20px",
        marginTop: isMobile ? 1 : 2,
        padding: isMobile ? "8px 16px" : "10px 20px",
        fontSize: isMobile ? "0.8rem" : "1rem",
        width: "45%",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

interface ButtonsProps {
  isMobile: boolean;
  blackButtonText: string;
  blackButtonClick?: () => void;
  type?: "button" | "submit" | "reset";
  blackButtonDisabled?: boolean;
  whiteButtonText: string;
  whiteButtonClick?: () => void;
  whiteButtonDisabled?: boolean;
}
export const Buttons = ({
  isMobile,
  blackButtonText,
  blackButtonClick,
  type,
  blackButtonDisabled = false,
  whiteButtonText,
  whiteButtonClick,
  whiteButtonDisabled = false,
}: ButtonsProps) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "60%",
        }}
      >
        <WhiteButton
          isMobile={isMobile}
          text={whiteButtonText}
          onClick={whiteButtonClick}
          disabled={whiteButtonDisabled}
        />
        <BlackButton
          isMobile={isMobile}
          text={blackButtonText}
          type={type}
          disabled={blackButtonDisabled}
          onClick={blackButtonClick}
        />
      </Box>
    </Box>
  );
};

interface ChangeButtonProps {
  isMobile: boolean;
  text: string;
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
}
export const ChangeButton = ({
  isMobile,
  text,
  isActive,
  onClick,
  disabled = false,
}: ChangeButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: isActive ? Color.Main : "white",
        color: isActive ? "white" : "black",
        borderColor: isActive ? "none" : "black",
        borderRadius: "8px",
        marginTop: isMobile ? 1 : 2,
        padding: isMobile ? "8px 16px" : "16px 24px",
        fontSize: isMobile ? "1rem" : "1.2rem",
        width: "100%",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
