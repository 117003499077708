import { Box, Typography } from "@mui/material";
import { useGetImageFromS3 } from "../hooks/S3/useS3ImageHook";
import { Loading } from "./Loading";
import { Error } from "./Error";
import { BlueprintImage } from "../hooks/Blueprint/type";

export const BlueprintImageComponent = ({
  blueprintImage,
}: {
  blueprintImage: BlueprintImage;
}) => {
  const { data, error, isLoading } = useGetImageFromS3(
    blueprintImage.file_path,
  );
  if (isLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }
  const isPdfFile =
    blueprintImage.file_path?.toLowerCase().endsWith(".pdf") || false;
  const fileName =
    blueprintImage.file_path.split(/[\\/]/).pop() || "ファイル名不明";

  return (
    <Box>
      {isPdfFile ? (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#f7f7f7",
            borderRadius: "10px",
            padding: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            アップロード済みファイル
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {fileName}
          </Typography>
        </Box>
      ) : (
        <Box
          component="img"
          src={data}
          alt="設計図リストの画像です"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
      )}
      <Box>
        <Typography>
          画像の説明：
          {blueprintImage.description && blueprintImage.description !== ""
            ? blueprintImage.description
            : "説明なし"}
        </Typography>
      </Box>
    </Box>
  );
};
