import { useEffect } from "react";
import {
  StockLog,
  StockLogDetail,
} from "../../../../../hooks/Log/useStockLogHook";
import {
  TableComponent,
  TableComponentExtendedProps,
} from "../../../../../uis/Table";
import { stockLogColumnsForTable } from "../../../../../utils/columns";
import { defaultStockLogRow } from "../../../../../utils/rows";

export const StockLogTable = ({
  register,
  errors,
  disabled,
  setValue,
  watchedDetails,
  control,
}: TableComponentExtendedProps<StockLog, StockLogDetail>) => {
  useEffect(() => {
    watchedDetails?.forEach((detail, index) => {
      if (!detail) return;
      const top_diameter = detail.top_diameter ?? 0; // mm
      const length = detail.length ?? 0; // mm

      // 単材積(㎥) = (mm^3) / (1,000,000,000)
      const newUnitVolume =
        (top_diameter * top_diameter * length) / 1_000_000_000;
      if (detail.unit_volume !== newUnitVolume) {
        setValue(`stock_log_details.${index}.unit_volume`, newUnitVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDetails]);

  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="stock_log_details"
      columns={stockLogColumnsForTable}
      defaultRow={defaultStockLogRow}
      disabled={disabled}
      control={control}
    />
  );
};
