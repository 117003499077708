import {
  DATA_LOG_MARKET_PRICE_PATH,
  DATA_ORDER_QUANTITY_PATH,
} from "../../utils/constant";
import { CommonTabbedLayout } from "./common/CommonTabbedLayout";

export const DataLayout = () => {
  const tabs = [
    { label: "丸太相場", path: DATA_LOG_MARKET_PRICE_PATH },
    { label: "注文・受注量", path: DATA_ORDER_QUANTITY_PATH },
  ] as const;

  return <CommonTabbedLayout tabs={tabs} />;
};
