import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Error } from "../../uis/Error";
import { Loading } from "../../uis/Loading";
import { Title } from "../../uis/Title";
import { Buttons } from "../../uis/Button/Button";
import {
  useForm,
  useWatch,
  Path,
  UseFormRegister,
  FieldErrors,
  FieldArrayPath,
  Control,
  UseFormSetValue,
  DefaultValues,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { StockWoodTable } from "../Home/Management/Stock/Wood/stock_wood_table";
import { StockLogTable } from "../Home/Management/Stock/Log/stock_log_table";
import { useUploadFileById } from "../../hooks/S3/useSignedURLHook";
import { toast } from "react-toastify";
import {
  ReadonlyStockLog,
  StockLog,
  StockLogDetail,
} from "../../hooks/Log/useStockLogHook";
import {
  ReadonlyStockWood,
  StockWood,
  StockWoodDetail,
} from "../../hooks/Wood/useStockWoodHook";
import { ReadonlyResponse } from "../../utils/types/general_type";
import { TemplateExcelDownload } from "../../uis/File/TemplateExcelDownload";
import ExcelFileUpload from "../../uis/File/ExcelFileUpload";

export function isStockLog(
  data?: ReadonlyStockWood | ReadonlyStockLog,
): data is ReadonlyStockLog {
  return (data as ReadonlyStockLog).stock_log_details !== undefined;
}

export function isStockWood(
  data?: ReadonlyStockWood | ReadonlyStockLog,
): data is ReadonlyStockWood {
  return (data as ReadonlyStockWood).stock_wood_details !== undefined;
}
type StockDataType = StockWood | StockLog;
type ReadonlyItemType<T extends StockDataType> = T extends StockWood
  ? ReadonlyStockWood
  : ReadonlyStockLog;
type ItemNameType<T extends StockWood | StockLog> = T extends StockWood
  ? "stock_wood"
  : "stock_log";

/**
 * GenericItemList コンポーネントの Props
 */
export interface GenericItemListProps<
  TStock extends StockLog | StockWood,
  TFieldArrayName extends FieldArrayPath<TStock> = FieldArrayPath<TStock>,
> {
  /** 画面タイトルに表示する文言例: "木材リスト作成", "丸太リスト作成" */
  formTitle: string;
  /**
   * データ取得用フック。
   * 例: useGetStockWood, useGetStockLog
   * 引数は { id, isOrdered, isTemporarilyStored } などを想定。
   */
  useGetStock: (id: number) => UseQueryResult<ReadonlyItemType<TStock>, Error>;
  /**
   * アイテム更新用フック。
   * 例: useUpdateStockWood, useUpdateStockLog
   */
  useUpdateStock: () => UseMutationResult<
    ReadonlyResponse,
    Error,
    TStock,
    unknown
  >;
  /**
   * 詳細行の配列キー。
   * 例: "stock_wood_details" | "stock_log_details"
   */
  detailsKey: TFieldArrayName;
  itemName: ItemNameType<TStock>;
}

export const InputStock = <
  TStock extends StockLog | StockWood,
  TDetail extends StockLogDetail | StockWoodDetail,
  TFieldArrayName extends FieldArrayPath<TStock> = FieldArrayPath<TStock>,
>({
  /** 画面タイトルに表示する文言例: "木材リスト作成", "丸太リスト作成" */
  formTitle,

  /**
   * データ取得用フック。
   * 例: useGetStockWood, useGetStockLog
   * 引数は { id, isOrdered, isTemporarilyStored } などを想定。
   */
  useGetStock,

  /**
   * アイテム更新用フック。
   * 例: useUpdateStockWood, useUpdateStockLog
   */
  useUpdateStock,

  /**
   * 詳細行の配列キー。
   * 例: "stock_wood_details" | "stock_log_details"
   */
  detailsKey,
  itemName,
}: GenericItemListProps<TStock, TFieldArrayName>) => {
  // ----- ポーリング関連 -----
  const [isPolling, setIsPolling] = useState(false);

  // ----- 取得データ -----
  const [data, setData] = useState<ReadonlyItemType<TStock> | null>(null);

  // ----- S3 ファイルアップロード関連 -----
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
  } = useForm<TStock>(); // フォームの値を管理する

  const { mutateAsync } = useUpdateStock();
  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetStock(Number(id));

  const watchedDetails = useWatch({
    control,
    name: detailsKey as Path<TStock>,
  }) as TDetail[];

  // 全明細が空でないかどうかのフラグ
  const areAllDetailsComplete =
    watchedDetails &&
    watchedDetails.length > 0 &&
    watchedDetails.every((detail) =>
      Object.entries(detail).every(
        ([key, value]) =>
          key === "id" || key === "amount" || (value !== "" && value !== 0),
      ),
    );

  const { mutateAsync: mutateUploadFileToS3, isPending: isMutatePending } =
    useUploadFileById();

  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
      const status = isStockLog(fetchedData)
        ? fetchedData?.stock_log_status
        : isStockWood(fetchedData)
          ? fetchedData?.stock_wood_status
          : undefined;
      if (status?.status === "pending") {
        setIsPolling(true);
      }
    }
  }, [fetchedData]);

  // ----- ポーリング関連 -----
  // アイテムの詳細に関するファイルをアップロードした際に、実装する
  useEffect(() => {
    let intervalId: number; // NodeJS.Timeout から number に変更

    if (isPolling) {
      intervalId = window.setInterval(async () => {
        // window.setInterval を使用
        try {
          const newData = await refetch(); // データを再取得
          if (newData && newData.data) {
            setData(newData.data); // dataを更新
            const status = isStockLog(newData.data)
              ? newData.data?.stock_log_status
              : isStockWood(newData.data)
                ? newData.data?.stock_wood_status
                : undefined;
            if (status?.status === "success") {
              toast.success("ファイルを正しく読み込むことができました。");
              setIsPolling(false); // ポーリングを停止
            } else if (status?.status === "failure") {
              toast.error(status.failure_reason);
              if (!newData.data.file_path) {
                setIsPolling(false); // ポーリングを停止
              }
            }
          }
        } catch (error) {
          console.error(error);
          setIsPolling(false); // エラー時もポーリングを停止
        }
      }, 1000); // 1秒ごとに実行
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  // ------ イベントハンドラ群 ------
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !data) {
      toast.error("アップロード対象のファイルがありません");
      return;
    }

    try {
      await mutateUploadFileToS3({
        id: data.id,
        file: selectedFile,
        path: `excel_csv/${itemName}`, // 例: "excel_csv/wood"
      });
      setSelectedFile(null);
      setIsPolling(true); // CSV/Excel の取りこみを監視するためポーリング開始
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData: TStock) => {
    try {
      formData.id = Number(id);
      // 「送付先選択へ」遷移
      await mutateAsync(formData);
    } catch (error) {
      console.error(error);
    }
  };

  // dataの変更時にフォームの値を更新
  // タイトルをsetするため、独自に実装。
  useEffect(() => {
    if (data) {
      reset(
        data as
          | TStock
          | DefaultValues<TStock>
          // | ResetAction<TStock>
          | undefined,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isBlackButtonDisabled = !areAllDetailsComplete;

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* タイトル */}
      <Title title={formTitle} />
      {/* フォーム */}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <Box sx={{ margin: "1rem auto" }}>
          <TemplateExcelDownload category={itemName} />
        </Box>
        {/* ファイルアップロード部分 */}
        <ExcelFileUpload
          selectedFile={selectedFile}
          filePath={data?.file_path}
          handleFileChange={handleFileChange}
          isPolling={isPolling}
        />
        {/* 明細テーブル: 型に応じて StockLogTable または StockWoodTable を表示 */}
        {data && (
          <>
            {isStockLog(data) ? (
              <StockLogTable
                register={register as unknown as UseFormRegister<StockLog>}
                errors={errors as FieldErrors<StockLog>}
                watchedDetails={watchedDetails as StockLogDetail[]}
                setValue={setValue as unknown as UseFormSetValue<StockLog>}
                control={control as unknown as Control<StockLog>}
              />
            ) : isStockWood(data) ? (
              <StockWoodTable
                register={register as unknown as UseFormRegister<StockWood>}
                errors={errors as FieldErrors<StockWood>}
                control={control as unknown as Control<StockWood>}
                setValue={setValue as unknown as UseFormSetValue<StockWood>}
                watchedDetails={watchedDetails as StockWoodDetail[]}
              />
            ) : null}
          </>
        )}
        <Buttons
          isMobile
          whiteButtonText="CSV/Excelファイルを更新する"
          whiteButtonClick={handleFileUpload} // handleSaveTemporarily関数を使用
          whiteButtonDisabled={!selectedFile || isMutatePending} // ボタンの有効/無効を制御
          blackButtonText="行を更新"
          type="submit"
          blackButtonDisabled={isBlackButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
        />
      </Box>
    </Box>
  );
};
