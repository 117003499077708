import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Stack,
  FormControl,
  Avatar,
  FormLabel,
  Input,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useUpdateUser } from "../../../hooks/useUserHook";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import FormField from "../../../uis/Forms/FormField";
import { useForm, Controller } from "react-hook-form";
import {
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../hooks/S3/useSignedURLHook";
import { UpdateUserSchema, UpdateUserType } from "../../../utils/schema";
import { industries } from "../../../utils/industry";
import { UserContext } from "../../../contexts/UserContext";
import { Color } from "../../../utils/color";
import { zodResolver } from "@hookform/resolvers/zod";
import { useStripeAccountLinkCreate } from "../../../hooks/useStripeHook";
import { AdminPasswordModal } from "../../../uis/Modals/AdminPasswordModal";
import { getCookie } from "../../../utils/cookie";
import { toast } from "react-toastify";

export const UserForm: React.FC = () => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [stripeAccountLinkCreate, setStripeAccountLinkCreate] =
    useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useContext(UserContext);
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const {
    data: imageData,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: user?.company_image,
  });
  const { mutateAsync } = useUpdateUser();
  const { mutateAsync: mutateStripeAccountLinkCreateAsync } =
    useStripeAccountLinkCreate();

  // React Hook Form
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setFocus,
  } = useForm<UpdateUserType>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues: {
      isStripeAccountCreated: false,
    },
  });

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  // エラーが発生したら最初のエラーフィールドにフォーカス
  useEffect(() => {
    const errorFields = Object.keys(errors) as Array<keyof UpdateUserType>;
    if (errorFields.length > 0) {
      setFocus(errorFields[0]);
    }
  }, [errors, setFocus]);

  // user情報をフォームに初期セット
  useEffect(() => {
    if (user) {
      if (user.industry !== undefined) {
        setValue("industry", user.industry);
      }
      if (user.company_name !== undefined) {
        setValue("companyName", user.company_name);
      }
      if (user.phone_number !== undefined) {
        setValue("phoneNumber", user.phone_number);
      }
      if (user.company_image !== undefined) {
        setValue("companyImage", user.company_image);
      }
      if (user.address !== undefined) {
        setValue("address", user.address);
      }
      if (user.postal_code !== undefined) {
        setValue("postalCode", user.postal_code);
      }
      if (
        user.corporate_number !== undefined &&
        user.corporate_number !== null
      ) {
        setValue("corporateNumber", user.corporate_number);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // 編集内容更新
  const onSubmit = async (data: UpdateUserType) => {
    let res: string | undefined = undefined;
    try {
      if (file !== null) {
        res = await mutateUploadFileToS3({
          file,
          path: "img/user",
        });
      }
      await mutateAsync({
        ...data,
        companyImage: res,
      });
      setIsEditMode(false);
    } catch (error) {
      console.error(error);
    }
  };

  // 電話番号をハイフン無しの数字だけに制限
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phoneNumber = e.target.value;
    let hasHyphens = false;
    let hasNonNumeric = false;

    // ハイフンを削除
    if (phoneNumber.includes("-")) {
      phoneNumber = phoneNumber.replace(/-/g, "");
      hasHyphens = true;
    }
    // 数字以外の文字を削除
    const cleanedNumber = phoneNumber.replace(/[^0-9]/g, "");
    if (cleanedNumber !== phoneNumber) {
      phoneNumber = cleanedNumber;
      hasNonNumeric = true;
    }

    // クリーンな電話番号をセット
    setValue("phoneNumber", phoneNumber);

    if (hasHyphens) {
      toast.error("電話番号にハイフンを含めないでください");
      return;
    }
    if (hasNonNumeric) {
      toast.error("電話番号には数字のみを含めてください");
      return;
    }
  };

  // 郵便番号に自動でハイフンを挿入
  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // 数字以外を除去
    if (value.length > 8) {
      // 7桁を超えた場合は制限
      value = value.slice(0, 3) + "-" + value.slice(3, 7);
      toast.error("郵便番号は7桁以内で入力してください。");
    } else if (value.length > 3) {
      // 4文字目以降でハイフンを挿入
      value = value.slice(0, 3) + "-" + value.slice(3);
    }
    setValue("postalCode", value);
  };

  // 画像変更
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0] && user) {
      const file = event.target.files[0];
      setFile(file);
      const fileUrl = URL.createObjectURL(file);
      setAvatarUrl(fileUrl);
    }
  };

  const handleAvatarClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  /**
   * Stripe アカウントリンク作成ボタンを押下したときの処理
   * admin_session_id が存在しなければモーダルを表示し、
   * 存在すれば直接 Stripe アカウントリンク作成APIをコール
   */
  const handleStripeAccountLinkCreate = async () => {
    const adminSessionId = getCookie("admin_session_id");
    if (!adminSessionId) {
      setOpenModal(true);
      setStripeAccountLinkCreate(true);
      return;
    }
    try {
      const res = await mutateStripeAccountLinkCreateAsync();
      if (res.url) {
        window.open(res.url, "_blank");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Stripeアカウントのモーダル完了時の処理
  const handleSuccessClose = async () => {
    setOpenModal(false);
    if (stripeAccountLinkCreate) {
      try {
        const res = await mutateStripeAccountLinkCreateAsync();
        if (res.url) {
          window.open(res.url, "_blank");
        }
        setStripeAccountLinkCreate(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleErrorClose = () => {
    setOpenModal(false);
    setValue("isStripeAccountCreated", false);
  };

  if (getLoading) {
    return <Loading />;
  }
  if (getError) {
    return <Error message={getError?.message} />;
  }

  return (
    <Box sx={{ width: "95%", margin: "0 auto" }}>
      {/* 見出しエリア */}
      <Box
        sx={{
          backgroundColor: "#f0f0f0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5rem",
          width: "100%",
        }}
      >
        <Typography variant="h6" sx={{ color: Color.Main, fontWeight: "bold" }}>
          アカウント情報
        </Typography>
        <Button
          onClick={isEditMode ? handleSubmit(onSubmit) : toggleEditMode}
          sx={{
            backgroundColor: isEditMode ? "#B80003" : Color.Main,
            color: "white",
            padding: "6px 1.5rem",
            "&:hover": {
              backgroundColor: isEditMode ? "#B80003" : Color.Main,
            },
          }}
          disabled={isSubmitting}
        >
          {isEditMode ? "編集内容を更新する" : "編集する"}
        </Button>
      </Box>

      {/* フォーム */}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: "100%",
        }}
      >
        <Stack alignItems="stretch" sx={{ width: "100%", margin: "0 auto" }}>
          {/* 画像アップロード */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormLabel>プロフィール画像</FormLabel>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                {...register("companyImage", {
                  onChange: handleImageChange,
                })}
                ref={(e) => {
                  register("companyImage").ref(e);
                  inputFileRef.current = e;
                }}
              />
              <Avatar
                alt="Company Icon"
                src={avatarUrl || imageData?.objectURL}
                sx={{
                  width: isMobile ? 90 : 100,
                  height: isMobile ? 90 : 100,
                  marginBottom: isMobile ? 1 : 2,
                }}
                onClick={() =>
                  toast.error(
                    "画像を変更するには、編集するボタンを押してください",
                  )
                }
              />
            </FormControl>

            {/* アップロード用の領域 */}
            {isEditMode && (
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  width: 100,
                  height: 100,
                  marginLeft: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={handleAvatarClick}
              >
                <Typography variant="h6" color="#ccc">
                  + upload
                </Typography>
              </Box>
            )}
          </Box>
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField
            control={control}
            register={register}
            name="industry"
            isError={!!errors.industry}
            errorMessage={errors.industry?.message}
            formType="select"
            options={industries
              .filter((it) => (!user?.is_admin ? it !== "管理者" : it))
              .map((industry, index) => ({
                label: industry,
                value: index,
              }))}
            placeholder="業種を選択してください"
            labelName="業種（必須）"
            disabled={!isEditMode}
            defaultValue={user?.industry}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField
            placeholder="会社名（必須）"
            register={register}
            name="companyName"
            isError={!!errors.companyName}
            errorMessage={errors.companyName?.message}
            labelName="会社名（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          {/* メールアドレス（編集不可） */}
          <FormControl
            disabled={true}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <FormLabel sx={{ width: "30%" }}>担当者メールアドレス</FormLabel>
            <Input
              placeholder="担当者メールアドレス(必須)"
              type="email"
              sx={{
                width: "70%",
                borderRadius: "0px",
                border: "none",
                paddingLeft: "1rem",
              }}
              defaultValue={user?.email}
            />
          </FormControl>
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField
            placeholder="担当者電話番号 / ハイフンなし（必須）"
            register={register}
            name="phoneNumber"
            isError={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber?.message}
            pattern={{
              value: /^[0-9]+$/,
              message: "有効な電話番号を入力してください(ハイフンなし)",
            }}
            labelName="担当者電話番号 / ハイフンなし（必須）"
            disabled={!isEditMode}
            onChange={handlePhoneNumberChange}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />
          <FormField
            placeholder="郵便番号（必須）"
            register={register}
            name="postalCode"
            isError={!!errors.postalCode}
            errorMessage={errors.postalCode?.message}
            pattern={{
              value: /^\d{3}-?\d{4}$/,
              message:
                "有効な郵便番号を入力してください（例: 123-4567 または 1234567）",
            }}
            labelName="郵便番号（必須）"
            disabled={!isEditMode}
            onChange={() => handlePostalCodeChange}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField
            placeholder="住所（必須）"
            register={register}
            name="address"
            isError={!!errors.address}
            errorMessage={errors.address?.message}
            labelName="住所（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField
            placeholder="法人番号（任意）"
            register={register}
            name="corporateNumber"
            isError={!!errors.corporateNumber}
            errorMessage={errors.corporateNumber?.message}
            pattern={{
              value: /^\d{13}$/,
              message: "法人番号は13桁の数字で入力してください",
            }}
            labelName="法人番号（任意）"
            required={false}
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          {/* Stripeアカウント関連 */}
          {user?.stripe_account_status === "created" ? (
            <Box
              sx={{
                padding: "1rem",
                backgroundColor: "#e8f5e9",
                borderRadius: "4px",
                marginTop: "1rem",
              }}
            >
              <Button onClick={handleStripeAccountLinkCreate}>
                Stripe Connect アカウント情報を提出する
              </Button>
            </Box>
          ) : user?.stripe_account_status === "completed" ? (
            <Box
              sx={{
                padding: "1rem",
                backgroundColor: "#e8f5e9",
                borderRadius: "4px",
                marginTop: "1rem",
              }}
            >
              <Button onClick={handleStripeAccountLinkCreate}>
                Stripe Connect アカウント情報を更新する
              </Button>
            </Box>
          ) : (
            <FormControl
              component="fieldset"
              sx={{ padding: "1rem" }}
              disabled={!isEditMode}
            >
              <FormLabel component="legend">
                Stripeアカウントを作成しますか？
              </FormLabel>
              <Controller
                name="isStripeAccountCreated"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <RadioGroup
                    row
                    aria-label="isStripeAccountCreated"
                    {...field}
                    value={field.value ? "true" : "false"} // booleanを文字列に変換
                    onChange={(e) => {
                      const value = e.target.value === "true";
                      field.onChange(value);
                      const adminSessionId = getCookie("admin_session_id");
                      if (value && !adminSessionId) setOpenModal(true);
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="はい"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="いいえ"
                    />
                  </RadioGroup>
                )}
              />
              {errors.isStripeAccountCreated && (
                <Typography color="error" variant="body2">
                  {errors.isStripeAccountCreated.message}
                </Typography>
              )}
            </FormControl>
          )}
          <Divider sx={{ backgroundColor: "#ccc" }} />
        </Stack>
      </Box>

      {openModal && (
        <AdminPasswordModal
          open={openModal}
          onSuccessClose={handleSuccessClose}
          onErrorClose={handleErrorClose}
        />
      )}
    </Box>
  );
};
