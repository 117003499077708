import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OrderDetailModalType } from "../../utils/types/item_type";
import { getUserLists } from "../../utils/user";
import { UserInfo } from "../UserInfo";
import { FileDownload } from "../File/FileDownload";
import Avatar from "../Avatar"; // Avatarコンポーネントをインポート
import { Color } from "../../utils/color";
import { RenderDetails, RenderDetailsProps } from "../RenderDetails";

interface OrderDetailModalProps extends OrderDetailModalType {
  open: boolean;
  onClose: () => void;
  isUserShown?: boolean;
}

export const OrderDetailModal: React.FC<
  OrderDetailModalProps & RenderDetailsProps
> = ({
  open,
  onClose,
  isUserShown = true,
  itemType,
  item,
  detail,
  order,
  isTotalItemAmountShown,
}) => {
  const handleClose = () => {
    // バックドロップクリックやエスケープキーによる閉鎖を無効化
    // 何もしない
  };

  // 使用例
  const {
    isContractingUser,
    isReceivingUsers,
    isPendingUsers,
    isDenyingUsers,
    isApplyingUsers,
  } = getUserLists({ orders: item.orders });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 0, // ヘッダーとコンテンツを分けるためにパディングを0に
          width: { xs: "90%", sm: "80%" }, // レスポンシブ対応
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: 2,
        }}
      >
        {/* ヘッダー */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: Color.Main,
            color: "white",
            p: 2,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Typography variant="h6">詳細確認</Typography>
          <IconButton
            onClick={onClose}
            sx={{ color: "white" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* コンテンツ */}
        <Box sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            注文の詳細
          </Typography>
          {isUserShown && (
            <Box>
              {/* ユーザーセクション */}
              <Box sx={{ mb: 4 }}>
                {/* 契約しているユーザー */}
                {isContractingUser && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      契約しているユーザー
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Avatar
                        user={isContractingUser}
                        isMobile={false} // 必要に応じて調整
                      />
                      <UserInfo
                        key={isContractingUser.user_id}
                        user={isContractingUser}
                        status="契約中"
                      />
                    </Box>
                  </Box>
                )}
                {/* 受注したユーザー */}
                {isReceivingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      受注したユーザー
                    </Typography>
                    {isReceivingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isMobile={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="受注中"
                        />
                      </Box>
                    ))}
                  </Box>
                )}
                {/* 保留中のユーザー */}
                {isPendingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      保留中のユーザー
                    </Typography>
                    {isPendingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isMobile={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="保留中"
                        />
                      </Box>
                    ))}
                  </Box>
                )}
                {/* 拒否したユーザー */}
                {isDenyingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      拒否したユーザー
                    </Typography>
                    {isDenyingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isMobile={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="拒否済み"
                        />
                      </Box>
                    ))}
                  </Box>
                )}
                {/* 申請中のユーザー */}
                {isApplyingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      申請中のユーザー
                    </Typography>
                    {isApplyingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isMobile={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="申請中"
                        />
                      </Box>
                    ))}
                  </Box>
                )}
                {/* すべてのユーザーが表示された場合の注意 */}
                {!isContractingUser &&
                  [
                    isReceivingUsers,
                    isPendingUsers,
                    isDenyingUsers,
                    isApplyingUsers,
                  ].every((list) => list.length === 0) && (
                    <Typography variant="body1">
                      ユーザーが存在しません。
                    </Typography>
                  )}
              </Box>
              <Box sx={{ mb: 4 }}>
                {/* Quotationの表示 */}
                {order?.quotation && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      見積書
                    </Typography>
                    <FileDownload
                      file={{
                        id: order?.quotation.id,
                        itemType: itemType,
                      }}
                      category="quotation"
                    />
                  </>
                )}
                {/* Order Formの表示 */}
                {order?.order_form && (
                  <FileDownload
                    file={{
                      id: order?.order_form.id, // 修正
                      itemType: itemType,
                    }}
                    category="order_form"
                  />
                )}
                {/* Delivery Noteの表示 */}
                {order?.delivery_note && (
                  <FileDownload
                    file={{
                      id: order?.delivery_note.id, // 修正
                      itemType: itemType,
                    }}
                    category="delivery_note"
                  />
                )}
                {/* Invoiceの表示 */}
                {order?.invoice && (
                  <FileDownload
                    file={{
                      id: order?.invoice.id, // 修正
                      itemType: itemType,
                    }}
                    category="invoice"
                  />
                )}
              </Box>
            </Box>
          )}
          <RenderDetails
            item={item}
            detail={detail}
            order={order}
            isTotalItemAmountShown={isTotalItemAmountShown}
          />
        </Box>
      </Box>
    </Modal>
  );
};
