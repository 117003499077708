import { Box, Button } from "@mui/material";
import {
  TemplateFileCategory,
  useDownloadTemplateFileFromS3,
} from "../../hooks/S3/useSignedURLHook";

export const TemplateExcelDownload = ({
  category,
}: {
  category: TemplateFileCategory;
}) => {
  const { mutateAsync: mutateDownloadAsync, isPending: isDownloadPending } =
    useDownloadTemplateFileFromS3();

  const downloadFile = async () => {
    try {
      const res = await mutateDownloadAsync(category);
      const link = document.createElement("a");
      link.href = res;
      link.download = `${category}_template.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
      <Button
        variant="outlined"
        onClick={downloadFile}
        disabled={isDownloadPending}
        sx={{
          backgroundColor: "#ffffff",
          color: "#000000",
          borderColor: "#000000",
          width: "45%",
          "&:hover": {
            backgroundColor: "#f5f5f5",
            borderColor: "#000000",
          },
        }}
      >
        Excelのテンプレートをダウンロードする
      </Button>
    </Box>
  );
};
