import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { BlueprintImageComponent } from "./BlueprintImage";
import { toDecimalString } from "../utils/number";
import { BaseItem, BaseOrder } from "../utils/types/base_type";
import {
  BlueprintFields,
  FreeFields,
  LogFields,
  WoodFields,
} from "../utils/fields";
import { DetailsType } from "../utils/types/item_type";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { Industry } from "../utils/industry";
import { TotalAmount } from "./TotalAmount";

function formatDetailValue(value: unknown): string {
  if (value === null || value === undefined) {
    return "データが存在しません";
  }

  if (typeof value === "number") {
    // 必要に応じて小数点以下の桁数を変えてください
    return toDecimalString(value, 12);
  }

  // 文字列等はそのまま返す
  return String(value);
}

export interface RenderDetailsProps {
  item: BaseItem;
  // ItemListで表示する際にデータが存在しない可能性があるのでoptional chainをつける
  // MailingComponentでは、orderのデータが存在しないので、optional chainをつける
  order?: BaseOrder;
  detail: DetailsType;
  isShownAmount?: boolean;
  isTotalItemAmountShown?: boolean;
}
// 共通の詳細表示コンポーネントをジェネリックに修正
export const RenderDetails = ({
  item,
  detail,
  order,
  isShownAmount = true,
  isTotalItemAmountShown = true,
}: RenderDetailsProps) => {
  const user = useContext(UserContext);
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        タイトル: {item.title || "タイトルが存在しません"}
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        コメント: {item.comment || "コメントが存在しません"}
      </Typography>
      {isShownAmount && (
        <TotalAmount
          item={item}
          order={order}
          isTotalItemAmountShown={isTotalItemAmountShown}
        />
      )}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {detail.wood_details && detail.wood_details.length > 0
                ? Object.values(WoodFields).map((field) => (
                    <TableCell key={String(field)}>{field}</TableCell>
                  ))
                : detail.log_details && detail.log_details.length > 0
                  ? Object.values(LogFields)
                      .filter((it) =>
                        Boolean(item.total_item_amount) ||
                        user?.industry !== Industry.Market
                          ? it !== "金額"
                          : true,
                      )
                      .map((field) => (
                        <TableCell key={String(field)}>{field}</TableCell>
                      ))
                  : detail.blueprint_details &&
                      detail.blueprint_details.length > 0
                    ? Object.values(BlueprintFields).map((field) => (
                        <TableCell key={String(field)}>{field}</TableCell>
                      ))
                    : detail.free_details && detail.free_details.length > 0
                      ? Object.values(FreeFields)
                          .filter((it) => {
                            const isCubicMeterZero = item.free_details.some(
                              (it) => !it.cubic_meter,
                            );
                            const isQuantityZero = item.free_details.some(
                              (it) => !it.quantity,
                            );
                            if (
                              Boolean(item.total_item_amount) ||
                              user?.industry !== Industry.Market
                            ) {
                              if (isQuantityZero) {
                                return it !== "金額" && it !== "本数";
                              }
                              if (isCubicMeterZero) {
                                return it !== "金額" && it !== "立米";
                              }
                              return it !== "金額";
                            }
                            if (isQuantityZero) {
                              return it !== "本数";
                            }
                            if (isCubicMeterZero) {
                              return it !== "立米";
                            }
                            return true;
                          })
                          .map((field) => (
                            <TableCell key={String(field)}>{field}</TableCell>
                          ))
                      : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {detail.log_details
              ? detail.log_details
                  .map((detail) => {
                    // totalAmount が truthy の場合、amount プロパティを除いた新たなオブジェクトを生成
                    if (
                      Boolean(item.total_item_amount) ||
                      user?.industry !== Industry.Market
                    ) {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { amount, ...rest } = detail;
                      return rest;
                    }
                    // それ以外の場合は元のオブジェクトをそのまま返す
                    return detail;
                  })
                  .map((detail, index) => (
                    <TableRow key={index}>
                      {Object.keys(LogFields)
                        .filter((it) =>
                          Boolean(item.total_item_amount) ||
                          user?.industry !== Industry.Market
                            ? it !== "amount"
                            : true,
                        )
                        .map((field) => (
                          <TableCell key={String(field)}>
                            {formatDetailValue(
                              detail[field as keyof typeof detail],
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))
              : detail.wood_details
                ? detail.wood_details.map((detail, index) => (
                    <TableRow key={index}>
                      {Object.keys(WoodFields).map((field) => (
                        <TableCell key={String(field)}>
                          {formatDetailValue(
                            detail[field as keyof typeof detail],
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : detail.free_details
                  ? detail.free_details
                      .map((detail) => {
                        const { amount, quantity, cubic_meter, ...rest } =
                          detail;
                        const isCubicMeterZero = item.free_details.some(
                          (it) => !it.cubic_meter,
                        );
                        const isQuantityZero = item.free_details.some(
                          (it) => !it.quantity,
                        );
                        if (
                          Boolean(item.total_item_amount) ||
                          user?.industry !== Industry.Market
                        ) {
                          if (isQuantityZero) {
                            return { cubic_meter, ...rest };
                          }
                          if (isCubicMeterZero) {
                            return { quantity, ...rest };
                          }
                          return rest;
                        }
                        if (isCubicMeterZero) {
                          return { amount, quantity, ...rest };
                        }
                        if (isQuantityZero) {
                          return { amount, cubic_meter, ...rest };
                        }
                        // それ以外の場合は元のオブジェクトをそのまま返す
                        return detail;
                      })
                      .map((detail, index) => (
                        <TableRow key={index}>
                          {Object.keys(FreeFields)
                            .filter((it) => {
                              const isCubicMeterZero = item.free_details.some(
                                (it) => !it.cubic_meter,
                              );
                              const isQuantityZero = item.free_details.some(
                                (it) => !it.quantity,
                              );
                              if (
                                Boolean(item.total_item_amount) ||
                                user?.industry !== Industry.Market
                              ) {
                                if (isQuantityZero) {
                                  return it !== "amount" && it !== "quantity";
                                }
                                if (isCubicMeterZero) {
                                  return (
                                    it !== "amount" && it !== "cubic_meter"
                                  );
                                }
                                return it !== "amount";
                              }
                              if (isQuantityZero) {
                                return it !== "quantity";
                              }
                              if (isCubicMeterZero) {
                                return it !== "cubic_meter";
                              }
                              return true;
                            })
                            .map((field) => (
                              <TableCell key={String(field)}>
                                {formatDetailValue(
                                  detail[field as keyof typeof detail],
                                )}
                              </TableCell>
                            ))}
                        </TableRow>
                      ))
                  : detail.blueprint_details
                    ? detail.blueprint_details.map((detail, index) => (
                        <TableRow key={index}>
                          {Object.keys(BlueprintFields).map((field) => (
                            <TableCell key={String(field)}>
                              {formatDetailValue(
                                detail[field as keyof typeof detail],
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    : null}
          </TableBody>
        </Table>
      </TableContainer>
      {item.blueprint_images?.map((it, idx) => (
        <BlueprintImageComponent key={idx} blueprintImage={it} />
      ))}
    </>
  );
};
