import { useQuery } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { orderMessageRoomKeys } from "../utils/query-key";
import { Messages } from "./Message/useMessageHook";
import { SearchTextWIthPaginationParams } from "../utils/types/general_type";
import { FIVE_MINUTES, PAGE_SIZE } from "../utils/constant";
import { ItemType, OrderMessageRoomType } from "../utils/types/item_type";
import { BaseOrder } from "../utils/types/base_type";
import { WoodDetails } from "./Wood/type";
import { LogDetails } from "./Log/type";
import { BlueprintDetails } from "./Blueprint/type";
import { FreeDetails } from "./Free/type";
import { ReadonlyGetUser } from "./useUserHook";

interface OrderMessageRoom {
  order: BaseOrder;
  messages: Messages;
  isPaymentShown: boolean;
  isInvoiceButtonShown: boolean;
  isDeliveryNoteButtonShown: boolean;
  isStripeAccountCompletedShown: boolean;
  isPaymentActionRequired: boolean;
  wood_details?: WoodDetails;
  log_details?: LogDetails;
  blueprint_details?: BlueprintDetails;
  free_details?: FreeDetails;
}
export type ReadonlyOrderMessageRoom = Readonly<OrderMessageRoom>;

export function useGetOrderMessageRoom({
  messageRoomId,
  type,
}: OrderMessageRoomType) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: orderMessageRoomKeys.orderMessageRoom(messageRoomId),
    queryFn: async () => {
      const { token } = await getAccessToken();
      if (!type) {
        throw new Error("Typeは必須です");
      }
      const response = await fetch(
        `${config.backendUrl}/api/${type}_order_message_room/${messageRoomId}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyOrderMessageRoom>;
    },
    enabled: !!type,
  });
}

interface SearchOrderMessageRooms {
  id: number;
  type: ItemType;
  user: ReadonlyGetUser;
  message?: {
    content: string;
    createdAt: Date;
  };
  title: string;
  unreadCount: number;
}
interface OrderMessageRoomResponse {
  orderMessageRooms: ReadonlyArray<SearchOrderMessageRooms>;
  totalPages: number;
}
export function useSearchOrderMessageRooms({
  searchText,
  page,
  pageSize = PAGE_SIZE,
}: SearchTextWIthPaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: orderMessageRoomKeys.searchWithPagination({
      searchText,
      page,
      pageSize,
    }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/order_message_rooms/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        Readonly<OrderMessageRoomResponse>
      >;
    },
    staleTime: FIVE_MINUTES,
  });
}
