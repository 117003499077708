import { WoodOrder } from "../../../hooks/Wood/type";
import {
  ColumnDefinitionForTable,
  TableComponent,
  TableComponentProps,
} from "../../../uis/Table";
import { amountColumn, woodColumnsForTable } from "../../../utils/columns";
import { defaultWoodRow } from "../../../utils/rows";
import { useWatch } from "react-hook-form";

export const WoodQuotationTable = ({
  register,
  errors,
  control,
}: TableComponentProps<WoodOrder>) => {
  const watchedTotalOrderAmount = useWatch({
    control,
    name: "total_order_amount",
  });
  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="item.wood_details"
      columns={woodColumnsForTable as ColumnDefinitionForTable<unknown>[]}
      defaultRow={defaultWoodRow}
      additionalColumns={
        Boolean(watchedTotalOrderAmount)
          ? []
          : ([
              { ...amountColumn, canBeEdited: true },
            ] as ColumnDefinitionForTable<unknown>[])
      }
      disabled={true}
      control={control}
    />
  );
};
