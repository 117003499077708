import { Box, Typography } from "@mui/material";
import { BaseItem, BaseOrder } from "../utils/types/base_type";

interface TotalAmountProps {
  isTotalItemAmountShown?: boolean;
  item: BaseItem;
  order?: BaseOrder;
}

export const TotalAmount = ({
  isTotalItemAmountShown = true,
  item,
  order,
}: TotalAmountProps) => {
  return (
    <Box>
      {isTotalItemAmountShown && !!item.total_item_and_detail_amount && (
        <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 1 }}>
          発注者が算出した見積もり金額（合計）: ¥
          {item.total_item_and_detail_amount.toLocaleString()}
        </Typography>
      )}
      <Typography variant="subtitle1" color="textSecondary" sx={{ mt: 1 }}>
        受注者の見積もり金額（合計）: ¥
        {order?.total_order_and_detail_amount
          ? order.total_order_and_detail_amount.toLocaleString()
          : "データが存在しません"}
      </Typography>
    </Box>
  );
};
