import { useEffect } from "react";
import { BlueprintDetail } from "../../../../hooks/Blueprint/type";
import {
  TableComponent,
  TableComponentExtendedProps,
} from "../../../../uis/Table";
import { blueprintColumnsForTable } from "../../../../utils/columns";
import { defaultBlueprintRow } from "../../../../utils/rows";
import { BaseOrder } from "../../../../utils/types/base_type";

export const BlueprintTable = ({
  register,
  errors,
  setValue,
  watchedDetails,
  control,
  watchedTotalAmount,
}: TableComponentExtendedProps<BaseOrder, BlueprintDetail>) => {
  const filteredBlueprintColumnsForTable = blueprintColumnsForTable.filter(
    (col) => (Boolean(watchedTotalAmount) ? col.field !== "amount" : true),
  );
  const filteredDefaultBlueprintRow = {
    ...defaultBlueprintRow,
    amount: Boolean(watchedTotalAmount) ? 0 : defaultBlueprintRow.amount,
  };
  useEffect(() => {
    watchedDetails?.forEach((detail, index) => {
      if (!detail) return;
      const width = detail.width ?? 0; // mm
      const height = detail.height ?? 0; // mm
      const length = detail.length ?? 0; // mmっt
      const quantity = detail.quantity ?? 0;

      // 単材積(㎥) = (mm^3) / (1,000,000,000)
      const newUnitVolume = (width * height * length) / 1_000_000_000;
      if (detail.unit_volume !== newUnitVolume) {
        setValue(`blueprint_details.${index}.unit_volume`, newUnitVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      // 材積(㎥) = 単材積(㎥) × 本数
      const newTotalVolume = newUnitVolume * quantity;
      if (detail.total_volume !== newTotalVolume) {
        setValue(`blueprint_details.${index}.total_volume`, newTotalVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDetails]);

  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="blueprint_details"
      columns={filteredBlueprintColumnsForTable}
      defaultRow={filteredDefaultBlueprintRow}
      control={control}
    />
  );
};
