import { Box } from "@mui/material";
import { useGetFree, useUpdateFree } from "../../../../hooks/Free/useFreeHook";
import { ItemSearchBox } from "../../../../uis/SearchBox/ItemSearchBox";
import { useNavigate, useParams } from "react-router-dom";
import { Title } from "../../../../uis/Title";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { useForm, useWatch } from "react-hook-form";
import { useContext, useEffect } from "react";
import { Buttons } from "../../../../uis/Button/Button";
import { useSearchItems } from "../../../../hooks/useItemHook";
import { TitleAndCommentField } from "../../../../uis/Forms/TitleAndCommentField";
import {
  ORDER_BASE_PATH,
  ORDER_MAILING_PATH,
} from "../../../../utils/constant";
import { Free } from "../../../../hooks/Free/type";
import { UserContext } from "../../../../contexts/UserContext";
import { Industry } from "../../../../utils/industry";
import { FreeTable } from "./free_table";
import { TotalAmountField } from "../../../../uis/TextField/TotalAmountField";

export const CreateFreeList = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<Free>(); // フォームの値を管理する
  const watchedDetails = useWatch({
    control,
    name: "free_details",
  });

  // タイトルをウォッチして一時保存ボタンの disabled 制御に活用
  const watchedTitle = useWatch({
    control,
    name: "title",
  });
  const watchedComment = useWatch({
    control,
    name: "comment",
  });
  const watchedTotalAmount = useWatch({
    control,
    name: "total_item_amount",
  });

  const { mutateAsync } = useUpdateFree();
  const { data, error, isPending } = useGetFree({
    id: Number(id),
    isOrdered: false,
    isTemporarilyStored: true,
  });

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  const isWhiteButtonDisabled =
    !watchedTitle &&
    !watchedComment &&
    !watchedTotalAmount &&
    !(
      watchedDetails &&
      watchedDetails[0] &&
      Object.values(watchedDetails[0]).some(
        (value) => value !== "" && value !== 0,
      )
    );

  const isAmountHigherThan50 = Boolean(watchedTotalAmount)
    ? watchedTotalAmount! >= 50
    : Array.isArray(watchedDetails)
      ? watchedDetails.reduce((sum, detail) => sum + (detail.amount ?? 0), 0) >=
        50
      : false;

  // 入力欄に空欄がある場合はtrue
  // タイトル、コメント欄が埋められている→false
  // タイトル、詳細(本数、立米のどちらかは空欄)が埋められている→false
  // ユーザーが市場の場合は、合計金額が50円以下の場合はfalse
  const isBlackButtonDisabled =
    // watchedTitleが存在しなければ無効
    !watchedTitle ||
    (!watchedComment &&
      !(
        Array.isArray(watchedDetails) &&
        watchedDetails.length > 0 &&
        // 各detailについて検証
        watchedDetails.every((detail) => {
          // ① "quantity" または "cubic_meter" に有効な値があるかチェック
          const hasQuantityOrCubicMeter =
            detail.quantity !== 0 || detail.cubic_meter !== 0;
          if (!hasQuantityOrCubicMeter) {
            // どちらかにデータがあれば、このdetailは有効と判断する
            return false;
          }
          // ② 上記以外の場合は、従来の条件に基づく検証を行う
          return Object.entries(detail).every(
            ([key, value]) =>
              // ユーザーがMarket業界でなく、かつキーが "amount" であれば検証対象外
              (user?.industry !== Industry.Market && key === "amount") ||
              (user?.industry === Industry.Market &&
                key === "amount" &&
                Boolean(watchedTotalAmount)) ||
              // それ以外は値が存在する（空文字・0でない）ことを確認
              (value !== "" && value !== 0) ||
              key === "quantity" ||
              key === "cubic_meter",
          );
        })
      )) ||
    // もしユーザーがMarket業界の場合、isAmountHigherThan50がfalseなら無効
    (!isAmountHigherThan50 && user?.industry === Industry.Market);

  const onSaveTemporarily = async (formData: Free) => {
    try {
      formData.id = Number(id);
      formData.is_temporarily_stored = true;
      formData.is_ordered = false;
      await mutateAsync(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData: Free) => {
    try {
      formData.id = Number(id);
      // 「送付先選択へ」遷移
      await mutateAsync(formData);
      navigate(`${ORDER_MAILING_PATH}/free/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* タイトル */}
      <Title title="自由記述" />
      {/* 3. 「丸太モード」の場合は <ItemSearchBox<Log>> を使い、検索関数は Logs を返す */}
      <ItemSearchBox
        id={Number(id)}
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) =>
          navigate(`${ORDER_BASE_PATH}/create_free_list/${id}`)
        }
        useSearchItems={useSearchItems}
        itemName="free"
      />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
      >
        {/* タイトル入力 */}
        <TitleAndCommentField register={register} errors={errors} />
        {/* 合計金額の入力欄を追加 */}
        {user?.industry === Industry.Market && (
          <TotalAmountField register={register} errors={errors} />
        )}
        <FreeTable
          register={register}
          errors={errors}
          control={control}
          watchedTotalAmount={watchedTotalAmount}
        />
        {/* ボタン群 */}
        <Buttons
          isMobile
          whiteButtonText="作成内容を一時保存"
          whiteButtonClick={handleSubmit(onSaveTemporarily)} // handleSaveTemporarily関数を使用
          whiteButtonDisabled={isWhiteButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
          blackButtonText="送付先選択へ"
          type="submit"
          blackButtonDisabled={isBlackButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
        />
      </Box>
    </Box>
  );
};
