import { Box, Button, Typography } from "@mui/material";
import {
  useDeliveryNoteOrder,
  useInvoiceOrder,
} from "../../hooks/useOrderHook";
import { useParams } from "react-router-dom";
import { ItemType } from "../../utils/types/item_type";
import { useState } from "react";
import { CleanWoodLawModal } from "../Modals/CleanWoodLawModal";
import { BaseOrder } from "../../utils/types/base_type";

interface IssueButtonsProps {
  type?: ItemType;
  isDeliveryNoteButtonShown: boolean;
  isInvoiceButtonShown: boolean;
  isGreeWoodApplied: boolean;
  order?: BaseOrder;
}

export const IssueButtons = ({
  type,
  isDeliveryNoteButtonShown,
  isGreeWoodApplied,
  isInvoiceButtonShown,
  order,
}: IssueButtonsProps) => {
  const [open, setOpen] = useState(false);
  const { order_message_room_id } = useParams<{
    order_message_room_id: string;
  }>();
  const { mutateAsync, isPending } = useDeliveryNoteOrder();
  const { mutateAsync: mutateInvoiceAsync, isPending: isInvoicePending } =
    useInvoiceOrder();
  const handleInvoiceClick = async () => {
    try {
      await mutateInvoiceAsync({
        messageRoomId: Number(order_message_room_id),
        type,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeliveryNoteClick = async () => {
    try {
      await mutateAsync({
        messageRoomId: Number(order_message_room_id),
        type,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        bgcolor: "background.paper",
        p: 2,
        borderTop: "1px solid #ccc",
        position: "sticky",
        bottom: 0,
      }}
    >
      {isGreeWoodApplied && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            クリーンウッド法に準拠する
          </Button>
          {order?.logging_notice_or_forest_management_plan_number && (
            <Typography>
              伐採届No.もしくは森林経営計画No.は
              {order?.logging_notice_or_forest_management_plan_number}です
            </Typography>
          )}
        </Box>
      )}
      {isDeliveryNoteButtonShown && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDeliveryNoteClick}
          disabled={isPending}
        >
          納品書発行
        </Button>
      )}
      {isInvoiceButtonShown && (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleInvoiceClick}
          disabled={isInvoicePending}
        >
          請求書発行
        </Button>
      )}
      {open && (
        <CleanWoodLawModal
          open={open}
          onClose={() => setOpen(false)}
          order={order}
        />
      )}
    </Box>
  );
};
