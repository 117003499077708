import { Color } from "../../utils/color";
import { CommonTabbedLayout } from "./common/CommonTabbedLayout";

export const messageTabs = [
  { label: "メッセージルーム", path: "/talk/message_rooms" },
  { label: "契約関連のメッセージルーム", path: "/talk/order_message_rooms" },
] as const;

export const MessageRoomsLayout = () => {
  return (
    <CommonTabbedLayout
      tabs={messageTabs}
      tabSx={(selected) => ({
        backgroundColor: selected ? Color.Main : "#FFFFFF",
        color: selected ? "#FFFFFF" : Color.Main,
        borderRadius: "4px", // Optional styling
        border: "1px solid #CCCCCC", // Add border to create a frame around each tab
        transition: "background-color 0.3s, color 0.3s", // Smooth transition
        "&:hover": {
          backgroundColor: selected ? Color.Main : "#F0F0F0",
        },
      })}
      spanStyle={(selected) => ({
        color: selected ? "#FFFFFF" : Color.Main,
        fontWeight: "bold",
        fontSize: "1.2rem",
      })}
    />
  );
};
