import React from "react";
import { CommonSplitLayout } from "./common/CommonSplitLayout";
import { BlueprintRoute } from "../BlueprintRoute";
import { Order } from "../../pages/Home/Order";

export const BlueprintLayout: React.FC = () => {
  return (
    <CommonSplitLayout
      routeWrapper={BlueprintRoute}
      headerTitle="設計図"
      leftPanel={<Order />}
    />
  );
};
