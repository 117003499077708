import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import { StockLogTable } from "../Management/Stock/Log/stock_log_table";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useGetUserStocks } from "../../../hooks/useUserHook";
import { StockLog } from "../../../hooks/Log/useStockLogHook";
import { useForm } from "react-hook-form";
import { UserProfile } from "../../../uis/UserProfile";
import { useEffect } from "react";
import { Talk } from "../../../uis/Talk";
import { useCreateOrder } from "../../../hooks/useOrderHook";
import { ReadonlyLog } from "../../../hooks/Log/type";
import { ORDER_MAILING_PATH } from "../../../utils/constant";
import { formatJapaneseDate } from "../../../utils/time";

export const UserStockLogs = () => {
  const { stock_log_id: stockLogIdParam, log_id: logIdParam } = useParams<{
    stock_log_id: string;
    log_id: string;
  }>();
  const navigate = useNavigate();

  // パラメータを数値に変換。無効な場合はNaNとなる
  const numericLogId = logIdParam ? parseInt(logIdParam, 10) : NaN;
  const numericStockLogId = stockLogIdParam
    ? parseInt(stockLogIdParam, 10)
    : NaN;

  const { data, error, isPending } = useGetUserStocks<ReadonlyLog>(
    {
      woodLog: "log",
      itemId: numericLogId,
      stockId: numericStockLogId,
      enabled: !isNaN(numericLogId) && !isNaN(numericStockLogId),
    }, // IDが有効な場合のみ実行
  );
  const {
    register,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<StockLog>(); // フォームの値を管理する
  const { mutateAsync, isPending: isMutatePending } = useCreateOrder();

  useEffect(() => {
    if (data?.user.stock_log) {
      reset(data.user.stock_log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  // データが存在しない、または必要な情報が欠けている場合の表示
  if (!data || !data.user || !data.item || !data.user.stock_log) {
    return <Error message="表示に必要なデータが見つかりませんでした。" />;
  }

  const handleOrder = async () => {
    if (data && data.user.user_id) {
      try {
        await mutateAsync({
          itemName: "log",
          itemId: numericLogId,
          isAll: false,
          userIds: [data.user.user_id],
        });
        navigate("/order");
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Box sx={{ width: "90%", margin: "0 auto" }}>
      <UserProfile user={data.user} />
      <Talk userId={data.user.user_id} />
      <Box sx={{ marginY: 2 }}>
        {/* Linkコンポーネントで画面遷移 */}
        <Link to={`${ORDER_MAILING_PATH}/log/${numericLogId}`}>
          送信先選択に戻る
        </Link>
      </Box>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{
          marginTop: 2,
          marginBottom: 1,
          color: "text.secondary",
          textAlign: "right",
        }} // 右寄せにする
      >
        在庫最終更新: {formatJapaneseDate(data.user.stock_log.updated_at)}
      </Typography>
      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        {data.item.total_item_and_detail_amount &&
        data.item.total_item_and_detail_amount >= 50 ? (
          <Button
            variant="contained"
            onClick={handleOrder}
            // 注文処理中、またはデータ取得中(再検証中含む)はボタンを無効化
            disabled={isMutatePending}
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              borderRadius: "8px",
              padding: "10px 24px", // パディング調整
              fontWeight: "bold",
              minWidth: "120px", // 最小幅設定
              "&:hover": {
                backgroundColor: "primary.dark",
              },
              "&:disabled": {
                opacity: 0.7, // 無効時の見た目を調整
              },
            }}
            // 処理中の視覚的フィードバック
            startIcon={
              isMutatePending ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
          >
            {isMutatePending ? "注文処理中..." : "注文する"}
          </Button>
        ) : (
          // 注文できない場合のメッセージ
          <Alert
            severity="info"
            sx={{ justifyContent: "center", marginTop: 2 }}
          >
            {typeof data.item?.total_item_and_detail_amount === "number"
              ? `現在の合計金額は ${data.item?.total_item_and_detail_amount}円です。合計50円以上から注文可能です。`
              : "合計金額が取得できないため注文できません。合計50円以上から注文可能です。"}
          </Alert>
        )}
      </Box>
      <StockLogTable
        register={register}
        errors={errors}
        disabled={true}
        control={control}
        setValue={setValue}
      />
    </Box>
  );
};
