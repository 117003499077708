import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { handleResponse } from "../../utils/response";
import { freeOrderKeys, itemKeys, userKeys } from "../../utils/query-key";
import {
  OrderStatusWithId,
  ReadonlyResponse,
  ReadonlyResponseWithId,
} from "../../utils/types/general_type";
import { useAuth } from "../useAuth";
import { toast } from "react-toastify";
import { Free, ReadonlyFree, ReadonlyFreeOrder } from "./type";

// リストを自由に入力できる関数
export function useCreateFree() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/free`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: itemKeys.itemsByFilter({
          itemName: "free",
          isTemporarilyStored: true,
          isOrdered: false,
        }),
      });
    },
  });
}

export function useUpdateFree() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Free) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/free`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          title: data.title,
          comment: data.comment,
          is_temporarily_stored: data.is_temporarily_stored,
          is_ordered: data.is_ordered,
          total_item_amount: data.total_item_amount,
          free_details: data.free_details.map((detail) => ({
            name: detail.name,
            amount:
              data.total_item_amount && data.total_item_amount > 0
                ? undefined
                : detail.amount,
            quantity: detail.quantity,
            cubic_meter: detail.cubic_meter,
          })),
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_temporarily_stored) {
        toast.success("自由記述を一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: itemKeys.item("free", variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: itemKeys.itemsByFilter({
          itemName: "free",
          isTemporarilyStored: true,
          isOrdered: false,
        }),
      });
    },
  });
}

export function useGetFree({
  id,
  isTemporarilyStored,
  isOrdered,
}: OrderStatusWithId) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.item("free", id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/free/${id}?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyFree>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useUpdateFreeTotalAmount() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      total_order_amount,
    }: {
      id: number;
      total_order_amount: number;
    }) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/free_order/total_amount`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id,
            total_order_amount,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      toast.success("金額を入力しました");
      await queryClient.invalidateQueries({
        queryKey: freeOrderKeys.free_receiver(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.item({
          filterType: "applying",
        }),
      });
    },
  });
}

export function useGetFreeOrderByReceiver(free_order_id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: freeOrderKeys.free_receiver(free_order_id),
    queryFn: async () => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/free_order/receiver/${free_order_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyFreeOrder>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
