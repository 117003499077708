import React from "react";
import Avatar from "./Avatar";
import { User } from "../hooks/useUserHook";
import { useMediaQuery, useTheme, Box, Typography } from "@mui/material";

interface UserProfileProps {
  user: User;
}

export const UserProfile: React.FC<UserProfileProps> = ({ user }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={2}
      sx={{ backgroundColor: "background.paper", borderRadius: 2 }}
    >
      <Avatar user={user} isMobile={isMobile} />
      <Typography
        variant="subtitle1"
        component="p"
        mt={1}
        sx={{ fontWeight: 500 }}
      >
        {user.company_name || "会社名が設定されていません"}
      </Typography>
    </Box>
  );
};
