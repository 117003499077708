import { useGetFree } from "../../../hooks/Free/useFreeHook";
import { useSearchFreeUsers } from "../../../hooks/useUserHook";
import { MailingComponent } from "../../common/MailingComponent";

export const FreeMailing = () => {
  return (
    <MailingComponent
      idParamName="free_id"
      useGetData={useGetFree}
      useSearchUsers={useSearchFreeUsers}
      itemName="free"
    />
  );
};
