import React from "react";
import { Paper, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useLocation } from "react-router-dom";
import { menubarAuthenticatedItems, menubarLoginItems } from "./barItems";
import { useBarNavigation } from "../../hooks/useBarNavigationHook";
import { Color } from "../../utils/color";

interface BottomBarProps {
  isLoggedIn: boolean;
}

const BottomBar: React.FC<BottomBarProps> = ({ isLoggedIn }) => {
  const location = useLocation();
  const { handleNavigation } = useBarNavigation();

  // ログイン有無に応じて切り替え
  const items = isLoggedIn
    ? menubarAuthenticatedItems.filter(
        (it) => it.label !== "マイページ" && it.label !== "ログアウト",
      )
    : menubarLoginItems;

  // 現在のパスに合うメニューのindexを探してBottomNavigationのvalueにする
  const currentIndex = items.findIndex(
    (item) => location.pathname.split("/")[1] === item.link.split("/")[1],
  );
  const [value, setValue] = React.useState(currentIndex < 0 ? 0 : currentIndex);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleNavigation(items[newValue].link);
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        {items.map((item, index) => {
          const isActive = location.pathname === item.link;
          return (
            <BottomNavigationAction
              key={index}
              label={item.label}
              icon={item.children}
              sx={{
                width: "100%",
                color: isActive ? "white" : Color.Main,
                backgroundColor: isActive ? Color.Main : "#F2F6F5",
                justifyContent: "start",
                borderRadius: isActive ? "50%" : 0,
                padding: "1.5rem 1rem",
                fontSize: "1rem",
                "&:hover": {
                  backgroundColor: isActive ? "#54c291" : "#F2F6F5",
                },
              }}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomBar;
