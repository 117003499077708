import { BaseOrders } from "./types/base_type";
import { OrderStatusEnum } from "./types/general_type";

interface GetUserListsProps {
  // 一つのItemに関連するすべてのOrder
  orders: BaseOrders;
}

// 一つのアイテムに関連するすべてのordersから、受注者側のステータスを判別する関数
export function getUserLists({ orders }: GetUserListsProps) {
  const isContractingUser = orders.find(
    (order) => order.status === OrderStatusEnum.is_contract_signed,
  )?.user;

  const isReceivingUsers = orders
    .filter((order) => order.status === OrderStatusEnum.is_received)
    .map((it) => it.user);

  const isPendingUsers = orders
    .filter((order) => order.status === OrderStatusEnum.is_pending)
    .map((it) => it.user);

  const isDenyingUsers = orders
    .filter((order) => order.status === OrderStatusEnum.is_denied)
    .map((it) => it.user);

  const isApplyingUsers = orders
    .filter((order) => order.status === OrderStatusEnum.is_applying)
    .map((it) => it.user);

  return {
    isContractingUser,
    isReceivingUsers,
    isPendingUsers,
    isDenyingUsers,
    isApplyingUsers,
  };
}
