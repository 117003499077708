import { SignedURLRequestToGetById } from "../hooks/S3/useSignedURLHook";
import {
  OrderStatusWithSearchParams,
  SearchIdRequestWithItemName,
} from "../hooks/useItemHook";
import { SearchTextWIthPaginationParamsWithIsTemporallyStored } from "../hooks/useNotificationHook";
import { SearchPageRequestWithStatus } from "../hooks/useOrderHook";
import { SetupIntent } from "../hooks/useOrderPaymentHook";
import { GetItemUsersRequest, GetUserStocks } from "../hooks/useUserHook";
import { PAGE_SIZE, SEARCH_BOX_ITEMS } from "./constant";
import { SearchTransaction } from "./schema";
import {
  OrderStatusWithApplying,
  PaginationParams,
  SearchTextWIthPaginationParams,
} from "./types/general_type";
import { ItemType } from "./types/item_type";

export const userKeysBase = {
  user: ["user"],
  users: ["users"],
} as const;

export const userKeys = {
  ...userKeysBase,
  searchMessageRoom: (searchText: string) => [
    ...userKeysBase.users,
    "searchMessageRoom",
    searchText,
  ],
  searchWood: (searchText: string) => [
    ...userKeysBase.users,
    "searchWood",
    searchText,
  ],
  searchLog: (searchText: string) => [
    ...userKeysBase.users,
    "searchLog",
    searchText,
  ],
  searchBlueprint: (searchText: string) => [
    ...userKeysBase.users,
    "searchBlueprint",
    searchText,
  ],
  searchFree: (searchText: string) => [
    ...userKeysBase.users,
    "searchFree",
    searchText,
  ],
  item: ({ filterType }: { filterType: OrderStatusWithApplying }) => [
    ...userKeysBase.users,
    filterType,
    "item",
  ],
  itemWithPagination: (params: GetItemUsersRequest) => [
    ...userKeys.item({ filterType: params.filterType }),
    params.page,
    params.pageSize,
  ],
  stocks: (woodLog: GetUserStocks) => [...userKeysBase.user, woodLog, "stocks"],
} as const;

export const notificationKeys = {
  notification: (notificationId: number) => ["notification", notificationId],
  notifications: ["notifications"],
  myNotifications: ["myNotifications"],
  notificationsWithSearchText: ({
    searchText,
    page,
    pageSize,
  }: SearchTextWIthPaginationParams) => [
    ...notificationKeys.notifications,
    searchText,
    page,
    pageSize,
  ],
  myNotificationsWithSearchText: ({
    searchText,
    page,
    pageSize,
    isTemporarilyStored,
    isPublished,
  }: SearchTextWIthPaginationParamsWithIsTemporallyStored) => [
    ...notificationKeys.myNotifications,
    searchText,
    page,
    pageSize,
    isTemporarilyStored,
    isPublished,
  ],
  search: (searchText: string, notificationId?: number) => [
    "notifications",
    searchText,
    "search",
    notificationId,
  ],
} as const;

export const messageRoomKeys = {
  messageRoom: (roomId: number) => ["messageRoom", roomId],
  messageRooms: ["messageRooms"],
} as const;

const baseOrdersMessageRoomKeys = {
  orderMessageRooms: ["orderMessageRooms"],
} as const;
export const orderMessageRoomKeys = {
  orderMessageRoom: (roomId: number) => ["orderMessageRoom", roomId],
  search: [...baseOrdersMessageRoomKeys.orderMessageRooms, "search"],
  searchWithPagination: ({
    searchText,
    page,
    pageSize,
  }: SearchTextWIthPaginationParams) => [
    ...baseOrdersMessageRoomKeys.orderMessageRooms,
    "search",
    searchText,
    page,
    pageSize,
  ],
} as const;

export const s3Keys = {
  image: (imagePath?: string) => ["image", imagePath],
  file: (dataPath?: string) => ["file", dataPath],
  file_by_id: (data: SignedURLRequestToGetById) => ["file_by_id", data],
} as const;

export const sessionKeys = {
  session: ["session"],
} as const;

export const woodOrderKeys = {
  wood_receiver: (woodOrderId: number) => ["woodReceiver", woodOrderId],
} as const;

export const logOrderKeys = {
  log_receiver: (logId: number) => ["logReceiver", logId],
} as const;

export const blueprintOrderKeys = {
  receiver: (blueprintOrderId: number) => ["receiver", blueprintOrderId],
} as const;

export const freeOrderKeys = {
  free_receiver: (freeOrderId: number) => ["freeReceiver", freeOrderId],
} as const;

export const stockKeys = {
  stock: (stockId: number) => ["stock", stockId],
} as const;

export const logMarketPriceKeys = {
  logMarketPrice: ["logMarketPrice"],
  log_market_price_by_id: (id: number) => ["logMarketPrice", id],
  logMarketPrices: ["logMarketPrices"],
  search: (searchText: string, logMarketPriceId?: number) => [
    ...logMarketPriceKeys.logMarketPrices,
    "search",
    searchText,
    logMarketPriceId,
  ],
} as const;

export const documentKeys = {
  quotations: ({ page, pageSize }: PaginationParams) => [
    "quotations",
    page,
    pageSize,
  ],
  orderForms: ({ page, pageSize }: PaginationParams) => [
    "orderForms",
    page,
    pageSize,
  ],
  deliveryNotes: ["deliveryNotes"],
  deliveryNotesWithPagination: ({ page, pageSize }: PaginationParams) => [
    ...documentKeys.deliveryNotes,
    page,
    pageSize,
  ],
  deliveryNotesByReceiver: ["deliveryNotesByReceiver"],
  deliveryNotesByReceiverWithPagination: ({
    page,
    pageSize,
  }: PaginationParams) => [
    ...documentKeys.deliveryNotesByReceiver,
    page,
    pageSize,
  ],
  invoices: ({ page, pageSize }: PaginationParams) => [
    "invoices",
    page,
    pageSize,
  ],
} as const;

export const paymentKeys = {
  paymentByRange: ({ startDate, endDate, type }: SearchTransaction) => [
    "paymentByRange",
    startDate,
    endDate,
    type,
  ],
  progressOrderPayments: ({
    page,
    searchText,
    pageSize,
  }: SearchTextWIthPaginationParams) => [
    "progressOrderPayments",
    page,
    searchText,
    pageSize,
  ],
  setupIntent: (setupIntent: SetupIntent) => ["setupIntent", setupIntent],
} as const;

export const itemKeys = {
  item: (itemName: ItemType, id: number) => [itemName, id],
  search: ({
    searchText,
    id,
    itemName,
    limit = SEARCH_BOX_ITEMS,
  }: SearchIdRequestWithItemName) => [searchText, limit, id, itemName],
  items: ({ itemName }: { itemName: ItemType }) => [itemName],
  itemsByFilter: ({
    itemName,
    isTemporarilyStored,
    isOrdered,
  }: {
    itemName: ItemType;
    isTemporarilyStored: boolean;
    isOrdered: boolean;
  }) => [...itemKeys.items({ itemName }), isTemporarilyStored, isOrdered],
  itemsByFilterWithSearchText: ({
    itemName,
    isTemporarilyStored,
    isOrdered,
    page,
    pageSize = PAGE_SIZE,
  }: OrderStatusWithSearchParams) => [
    ...itemKeys.itemsByFilter({ itemName, isTemporarilyStored, isOrdered }),
    page,
    pageSize,
  ],
} as const;

export const orderKeys = {
  searchStatuses: ({ type }: { type: ItemType }) => [type],
  searchStatusesWithPagination: ({
    type,
    searchText,
    page,
    pageSize = PAGE_SIZE,
    statuses,
  }: SearchPageRequestWithStatus) => [
    "orders",
    "searchStatus",
    type,
    searchText,
    page,
    pageSize,
    statuses,
  ],
  transactionQuantityByRange: ({
    startDate,
    endDate,
    type,
  }: SearchTransaction) => ["orderByRange", startDate, endDate, type],
} as const;

export const stockWoodKeys = {
  stockWood: ["stockWood"],
  stockWoods: ["stockWoods"],
} as const;

export const stockLogKeys = {
  stockLog: ["stockLog"],
  stockLogs: ["stockLogs"],
} as const;
