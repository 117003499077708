import { Box, Button, Divider, Typography } from "@mui/material";
import { FileDownload } from "./File/FileDownload";
import {
  DetailsType,
  ItemType,
  OrderDetailModalType,
} from "../utils/types/item_type";
import { BaseItem, BaseOrder } from "../utils/types/base_type";

export const FileAndDetailsComponent = ({
  order,
  item,
  itemType,
  isShown,
  setOrder,
  // isMobile,
  detail,
}: {
  order?: BaseOrder;
  item?: BaseItem;
  itemType: ItemType;
  isShown: boolean;
  setOrder: (value: React.SetStateAction<OrderDetailModalType | null>) => void;
  isMobile: boolean;
  detail: DetailsType;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#e0e0e0",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "4px 8px",
          borderRadius: "4px",
        }}
      >
        <Box>
          {/* 詳細を確認するテキスト */}
          <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
            詳細を確認する
          </Typography>
          {/* 詳細確認ボタン */}
          {order && item && (
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#f4f0f0",
                color: "black",
                border: "1px solid black",
              }}
              onClick={() => {
                setOrder({
                  itemType: itemType,
                  item: item,
                  order: order,
                  detail: {
                    log_details: detail.log_details,
                    wood_details: detail.wood_details,
                    free_details: detail.free_details,
                    blueprint_details: detail.blueprint_details,
                  },
                });
              }}
            >
              詳細確認
            </Button>
          )}
        </Box>
        {/* 「詳細を確認する」ボタンとFileDownload部分をひとまとまりにする。
  グレー背景にして「詳細を確認する」の下に「詳細確認」ボタン、さらにFileDownloadがあれば黒い縦線で区切る。
  全ては右寄せ、上下中央揃えを想定するため、Flexコンテナで対応。 */}
        {isShown && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 1, borderColor: "black" }}
            />
            <FileDownload
              file={{
                id: order?.quotation?.id,
                itemType,
              }}
              category="quotation"
            />
          </>
        )}
      </Box>
    </Box>
  );
};
