import { ProtectedRoute } from "./ProtectedRoute";
import { LogReceivers } from "../utils/industry";

export const LogReceiverRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ProtectedRoute
      requiredIndustries={LogReceivers}
      industryErrorMessage="丸太に関する権限が存在しません。市場、製材所、製材所&プレカットのユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
