import React, { useContext } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Color } from "../../utils/color";
import Avatar from "../Avatar";
import { UserContext } from "../../contexts/UserContext";
import { Logo } from "../svg";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  title?: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
  const user = useContext(UserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between", // 左右の要素を両端に配置
        alignItems: "center", // 垂直方向に中央揃え
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        position: "relative",
        zIndex: "10",
        height: "7vh",
        padding: "0 1rem", // 左右に余白を追加
      }}
    >
      {isMobile ? (
        user !== null && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Logo />
            <Typography variant="h6" sx={{}}>
              EFF木材流通システム
            </Typography>
            <Box onClick={() => navigate("/profile")}>
              <Avatar user={user} isMobile />
              <Typography variant="h6" sx={{ fontSize: "0.6rem" }}>
                マイページ
              </Typography>
            </Box>
          </Box>
        )
      ) : (
        <Typography sx={{ fontSize: "1.5rem", color: Color.Main }}>
          {title}
        </Typography>
      )}
    </Box>
  );
};
