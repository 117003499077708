import { ProtectedRoute } from "./ProtectedRoute";
import { SawmillPrecutUsers } from "../utils/industry";

export const SawmillPrecutRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ProtectedRoute
      requiredIndustries={SawmillPrecutUsers}
      industryErrorMessage="木材の見積もりに関する権限が存在しません。製材所、プレカット、製材所&プレカットのユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
