import { useEffect } from "react";
import { Box } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { WoodTable } from "./wood_table";
import { TitleAndCommentField } from "../../../../uis/Forms/TitleAndCommentField";
import { TemplateExcelDownload } from "../../../../uis/File/TemplateExcelDownload";
import { Buttons, WhiteButton } from "../../../../uis/Button/Button";
import { Wood } from "../../../../hooks/Wood/type";
import { useGetWood, useUpdateWood } from "../../../../hooks/Wood/useWoodHook";
import { ORDER_MAILING_PATH } from "../../../../utils/constant";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { useFileUpload } from "../../../../hooks/File/useFileUpload";
import { useFilePolling } from "../../../../hooks/File/useFilePolling";
import ExcelFileUpload from "../../../../uis/File/ExcelFileUpload";

export const Form = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, error, isPending, refetch } = useGetWood({
    id: Number(id),
    isOrdered: false,
    isTemporarilyStored: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    control,
  } = useForm<Wood>(); // フォームの値を管理する

  // ファイルアップロード処理
  const {
    selectedFile,
    handleFileChange,
    clearSelectedFile,
    handleFileUploadClick,
    isPending: isUploadMutatePending,
  } = useFileUpload({
    id: Number(id),
    filePath: "excel_csv/wood",
    onUploadSuccess: () => {
      startPolling(); // アップロード成功でポーリング開始
    },
  });

  // ファイルステータスのポーリング処理
  const { isPolling, startPolling } = useFilePolling({
    refetch,
  });

  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (data) {
      reset(data);
      const status = data.file_status;
      if (status?.status === "pending" && !isPolling) {
        startPolling();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // --- フォーム値の監視 ---
  const watchedDetails = useWatch({ control, name: "wood_details" });
  const watchedTitle = useWatch({ control, name: "title" });
  const watchedComment = useWatch({ control, name: "comment" });

  // --- API Mutation ---
  const { mutateAsync: updateWoodMutate } = useUpdateWood();

  if (isPending) {
    return <Loading />;
  }
  if (error) {
    return <Error message={error?.message} />;
  }

  const onSubmit = async (formData: Wood) => {
    try {
      await updateWoodMutate({
        ...formData,
        id: Number(id),
      }); // 送付先へ行くので確定状態
      clearSelectedFile(); // 念のためクリア
      navigate(`${ORDER_MAILING_PATH}/wood/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveTemporarily = async (formData: Wood) => {
    try {
      // isDirty をチェックして変更がない場合は実行しないなどの制御も可能
      await updateWoodMutate({
        ...formData,
        id: Number(id),
        is_temporarily_stored: true,
        is_ordered: false,
      });
    } catch (error) {
      console.error("Save temporarily error:", error);
    }
  };

  // --- ボタンの有効/無効判定 ---
  // 詳細が少なくとも1行あり、全ての行の必須項目（id, amount以外）が入力されているか
  const areAllDetailsComplete =
    Array.isArray(watchedDetails) &&
    watchedDetails.length > 0 &&
    watchedDetails.every((detail) =>
      Object.entries(detail ?? {}).every(
        ([key, value]) =>
          key === "id" ||
          key === "amount" ||
          (value !== "" &&
            value !== 0 &&
            value !== null &&
            value !== undefined),
      ),
    );
  const isBlackButtonDisabled =
    !watchedTitle || !areAllDetailsComplete || isSubmitting;

  const isWhiteButtonDisabled =
    isSubmitting ||
    (!watchedComment &&
      !watchedTitle &&
      !(
        watchedDetails &&
        watchedDetails[0] &&
        Object.values(watchedDetails[0]).some(
          (value) => value !== "" && value !== 0,
        )
      ));
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%" }}
    >
      <Box sx={{ margin: "1rem auto" }}>
        <TemplateExcelDownload category="wood" />
      </Box>
      {/* ファイルアップロード */}
      <ExcelFileUpload
        selectedFile={selectedFile}
        filePath={data?.file_path}
        handleFileChange={handleFileChange}
        isPolling={isPolling}
      />
      {/* タイトル入力 */}
      <TitleAndCommentField register={register} errors={errors} />
      {/* 明細テーブル: 型に応じて WoodTable または WoodTable を表示 */}
      {data && (
        <WoodTable
          register={register}
          errors={errors}
          watchedDetails={watchedDetails}
          setValue={setValue}
          control={control}
        />
      )}
      <WhiteButton
        isMobile
        text="CSV/Excelファイルをアップロードする"
        onClick={handleFileUploadClick}
        disabled={!selectedFile || isUploadMutatePending}
      />
      {/* 操作ボタン */}
      <Buttons
        isMobile
        whiteButtonText="作成内容を一時保存"
        whiteButtonClick={handleSubmit(onSaveTemporarily)} // バリデーション後に実行
        whiteButtonDisabled={isWhiteButtonDisabled} // 一時保存無効条件 + 処理中
        blackButtonText="送付先選択へ"
        type="submit" // フォームの送信トリガー
        blackButtonDisabled={isBlackButtonDisabled} // 送信無効条件 + 処理中
      />
    </Box>
  );
};
