import { FormHelperText, FormLabel, TextField } from "@mui/material";
import {
  UseFormRegister,
  FieldValues,
  Path,
  FieldErrors,
} from "react-hook-form";

interface TitleAndCommentFieldProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  disabled?: boolean;
  titleField?: Path<T>;
  commentField?: Path<T>;
}

export const TitleAndCommentField = <T extends FieldValues>({
  register,
  errors,
  disabled = false,
  titleField = "title" as Path<T>,
  commentField = "comment" as Path<T>,
}: TitleAndCommentFieldProps<T>) => {
  return (
    <>
      {disabled && (
        <FormLabel sx={{ width: "30%" }} htmlFor="タイトル">
          タイトル
        </FormLabel>
      )}
      {/* タイトル入力 */}
      <TextField
        label={disabled ? undefined : "タイトル"}
        placeholder={disabled ? undefined : "タイトルを入力してください"}
        variant="outlined"
        fullWidth
        {...register(titleField)}
        error={!!errors[titleField]}
        sx={{
          marginBottom: 3,
          borderRadius: "10px",
        }}
        disabled={disabled}
      />
      {errors[titleField] && (
        <FormHelperText sx={{ color: "red" }}>
          タイトルを入力してください
        </FormHelperText>
      )}
      {disabled && (
        <FormLabel sx={{ width: "30%" }} htmlFor="コメント">
          コメント
        </FormLabel>
      )}
      {/* コメント入力 */}
      <TextField
        label={disabled ? undefined : "コメント"}
        placeholder={disabled ? undefined : "コメントを入力してください"}
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        {...register(commentField)}
        error={!!errors[commentField]}
        sx={{
          marginBottom: 3,
        }}
        disabled={disabled}
      />
      {errors[commentField] && (
        <FormHelperText sx={{ color: "red" }}>
          コメントを入力してください
        </FormHelperText>
      )}
    </>
  );
};
