import React from "react";
import { CommonSplitLayout } from "./common/CommonSplitLayout";
import { PrivateRoute } from "../PrivateRoute";
import { Home } from "../../pages/Home/Home";
import { Document } from "../../pages/Home/Management/Document";
import { Money } from "../../pages/Home/Management/Money";
import { Order } from "../../pages/Home/Order";
import {
  DATA_LOG_MARKET_PRICE_PATH,
  DATA_ORDER_QUANTITY_PATH,
  MANAGEMENT_DOCUMENT_PATH,
  MANAGEMENT_MONEY_PATH,
  ORDER_BASE_PATH,
} from "../../utils/constant";
import { OrderQuantity } from "../../pages/Home/Data/OrderQuantity";
import { LogMarketPrice } from "../../pages/Home/Data/LogMarketPrice/LogMarketPrice";

const headerTitleByPath = (path: string) => {
  if (path === "/home") {
    return "ホーム";
  } else if (path.includes("order")) {
    return "注文";
  } else {
    return "お知らせ";
  }
};

const leftPanelByPath = (path: string) => {
  if (path.includes("home")) {
    return <Home />;
  } else if (path.includes(MANAGEMENT_DOCUMENT_PATH)) {
    return <Document />;
  } else if (path.includes(DATA_ORDER_QUANTITY_PATH)) {
    return <OrderQuantity />;
  } else if (path.includes(DATA_LOG_MARKET_PRICE_PATH)) {
    return <LogMarketPrice />;
  } else if (path.includes(MANAGEMENT_MONEY_PATH)) {
    return <Money />;
  } else if (path.includes(ORDER_BASE_PATH)) {
    return <Order />;
  }
  return null;
};

export const ThreeSplitScreenLayout: React.FC = () => {
  return (
    <CommonSplitLayout
      routeWrapper={PrivateRoute}
      headerTitle={headerTitleByPath}
      leftPanel={leftPanelByPath}
    />
  );
};
