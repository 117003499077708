import React from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  SxProps,
  Theme,
  Select,
  MenuItem,
  TextareaAutosize,
} from "@mui/material";
import {
  UseFormRegister,
  FieldValues,
  Path,
  Controller,
  Control,
  PathValue,
  UseFormRegisterReturn,
} from "react-hook-form";

interface FormFieldProps<T extends FieldValues> {
  control?: Control<T, any>;
  register: UseFormRegister<T>;
  name: Path<T>;
  isError: boolean;
  errorMessage?: string;
  type?: React.HTMLInputTypeAttribute;
  options?: ReadonlyArray<{ label: string; value: string | number }>;
  pattern?: { value: RegExp; message: string };
  placeholder: string;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  labelName?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  formType?: "select" | "input" | "textarea";
  defaultValue?: PathValue<T, Path<T>>;
}

const FormField = <T extends FieldValues>({
  control,
  register,
  name,
  isError,
  errorMessage,
  type = "text",
  options = [],
  pattern,
  placeholder,
  required = true,
  onChange: externalOnChange, // 外部からの onChange を externalOnChange として受け取る
  labelName,
  disabled = false,
  sx,
  formType = "input",
  defaultValue,
}: FormFieldProps<T>) => {
  const requiredText = required
    ? `${placeholder}を${formType === "select" ? "選択" : "入力"}してください`
    : undefined;

  // register を一度呼び出して、プロパティを取得
  // Input と Textarea で共通の register オプションを使用
  const registerProps: UseFormRegisterReturn = register(name, {
    required: requiredText,
    pattern,
    // setValueAs は Input の場合にのみ適用したいかもしれない
    // 必要であれば formType === 'input' の条件分岐内で register を呼び分ける
    setValueAs:
      formType === "input"
        ? (value) => (value === "" ? undefined : value)
        : undefined,
  });

  // register から提供される onChange ハンドラを取得
  const rhfOnChange = registerProps.onChange;

  // 合成 onChange ハンドラを作成 (Input 用)
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // まず react-hook-form の onChange を実行 (必須)
    await rhfOnChange(e);
    // 外部から渡された onChange があれば実行
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  // 合成 onChange ハンドラを作成 (Textarea 用)
  const handleTextareaChange = async (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // まず react-hook-form の onChange を実行 (必須)
    await rhfOnChange(e);
    // 外部から渡された onChange があれば実行
    if (externalOnChange) {
      externalOnChange(e);
    }
  };

  return (
    <FormControl
      error={isError}
      disabled={disabled}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "1rem",
        ...sx,
      }}
    >
      {labelName && (
        <FormLabel sx={{ width: "30%" }} htmlFor={labelName}>
          {labelName}
        </FormLabel>
      )}
      {formType === "textarea" ? (
        <TextareaAutosize
          id={labelName}
          placeholder={placeholder}
          {...register(name, {
            required: requiredText,
            pattern,
          })}
          onChange={handleTextareaChange}
          minRows={3}
          style={{
            width: "70%",
            padding: "1rem",
            borderRadius: "8px",
            border: "1px solid #ccc",
            resize: "vertical",
          }}
          disabled={disabled}
        />
      ) : formType === "select" ? (
        <Controller
          name={name}
          control={control}
          rules={{ required: requiredText }}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Select
              {...field}
              id={labelName}
              sx={{
                width: "70%",
                borderRadius: "0px",
                border: "none",
                paddingLeft: "1rem",
              }}
              disabled={disabled}
            >
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
              {options.map((option, index) => (
                <MenuItem value={option.value} key={index}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      ) : (
        <Input
          id={labelName}
          placeholder={placeholder}
          type={type}
          {...register(name, {
            required: requiredText,
            setValueAs: (value) => (value === "" ? undefined : value),
            pattern,
          })}
          onChange={handleInputChange}
          sx={{
            width: "70%",
            borderRadius: "0px",
            border: "none",
            paddingLeft: "1rem",
          }}
          disabled={disabled}
        />
      )}
      {errorMessage && (
        <FormHelperText sx={{ color: "red" }}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormField;
