import React from "react";
import { CommonSplitLayout } from "./common/CommonSplitLayout";
import { LogOrderRoute } from "../LogOrderRoute";
import { Order } from "../../pages/Home/Order";

export const LogOrderLayout: React.FC = () => {
  return (
    <CommonSplitLayout
      routeWrapper={LogOrderRoute}
      headerTitle="丸太"
      leftPanel={<Order />}
    />
  );
};
