import { Box } from "@mui/material";
import { ChangeButton } from "./Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCreateNotification } from "../../hooks/useNotificationHook";

export const ChangeButtons = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 現在のルートを取得
  const { mutateAsync } = useCreateNotification();
  const [disabled, setDisabled] = useState(false);

  const handleNotificationClick = async () => {
    setDisabled(true);
    try {
      const res = await mutateAsync();
      navigate(`/home/notification/${res.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  // 現在のパスを取得
  const currentPath = location.pathname;
  // ボタンごとのリンク先を定義
  const homePath = "/home";
  const notificationPathPrefix = "/home/notification/";
  const notificationsListPath = "/home/notifications";
  const temporallyStoredNotificationsListPath =
    "/home/temporarily_stored/notifications";
  const messageRoomsPath = "/talk/message_rooms";

  // 各ボタンがアクティブかどうかを判定
  const isHomeActive = currentPath === homePath;
  const isNotificationActive =
    currentPath.startsWith(notificationPathPrefix) &&
    !currentPath.includes("/detail");
  const isNotificationsListActive = currentPath === notificationsListPath;
  const isTemporarilyStoredNotificationsListActive =
    currentPath === temporallyStoredNotificationsListPath;
  const isMessageRoomsActive = currentPath === messageRoomsPath;

  return (
    <Box sx={{ width: "80%" }}>
      <ChangeButton
        isMobile
        text="ホーム"
        onClick={() => navigate("/home")}
        isActive={isHomeActive} // 色を動的に変更
      />
      <ChangeButton
        isMobile
        text="会社お知らせを作成"
        onClick={handleNotificationClick}
        disabled={disabled}
        isActive={isNotificationActive} // 色を動的に変更
      />
      <ChangeButton
        isMobile
        text="作成済みお知らせ一覧"
        onClick={() => navigate("/home/notifications")}
        isActive={isNotificationsListActive} // 色を動的に変更
      />
      <ChangeButton
        isMobile
        text="一時保存お知らせ一覧"
        onClick={() => navigate("/home/temporarily_stored/notifications")}
        isActive={isTemporarilyStoredNotificationsListActive} // 色を動的に変更
      />
      {/* 3: メッセージ一覧ボタン */}
      <ChangeButton
        isMobile
        text="メッセージ一覧"
        onClick={() => navigate("/talk/message_rooms")}
        isActive={isMessageRoomsActive} // 色を動的に変更
      />
    </Box>
  );
};
