import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { BlackButton } from "../../../uis/Button/Button";
import { useForm, useWatch } from "react-hook-form";
import {
  useGetFreeOrderByReceiver,
  useUpdateFreeTotalAmount,
} from "../../../hooks/Free/useFreeHook";
import { useParams } from "react-router-dom";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import { FreeQuotationTable } from "./free_quotation_table";
import { FreeOrder } from "../../../hooks/Free/type";
import { MenuComponent } from "../../../uis/Menu";
import { TitleAndCommentField } from "../../../uis/Forms/TitleAndCommentField";
import { toast } from "react-toastify";
import { TotalAmountField } from "../../../uis/TextField/TotalAmountField";

export const FreeQuotation = () => {
  // メニューの開閉状態
  const { mutateAsync } = useUpdateFreeTotalAmount();
  const { free_order_id } = useParams<{ free_order_id: string }>();
  const { data, error, isPending } = useGetFreeOrderByReceiver(
    Number(free_order_id),
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FreeOrder>();

  const watchedFreeDetails = useWatch({
    control,
    name: "item.free_details",
  });

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  const onSubmit = async (data: FreeOrder) => {
    if (!data.total_order_amount) {
      toast.error("合計金額が存在しません");
      return;
    }
    try {
      await mutateAsync({
        id: Number(free_order_id),
        total_order_amount: data.total_order_amount,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%", paddingTop: "10%" }}
      >
        <TitleAndCommentField
          register={register}
          errors={errors}
          disabled={true}
          titleField="item.title"
          commentField="item.comment"
        />
        {watchedFreeDetails && watchedFreeDetails.length > 0 && (
          <FreeQuotationTable
            register={register}
            errors={errors}
            control={control}
          />
        )}
        {data.item.total_item_and_detail_amount ? (
          <Box>
            <Typography>
              発注者の見積もり金額:{data.item.total_item_and_detail_amount}円
            </Typography>
          </Box>
        ) : null}
        <TotalAmountField
          register={register}
          errors={errors}
          totalAmountField="total_order_amount"
        />
        {(data.total_order_amount ?? 0) >= 50 && (
          <MenuComponent
            itemType="free"
            status={data.status}
            userId={data.item.orderer.user_id}
            itemId={data.item.id}
          />
        )}
        <BlackButton
          isMobile
          text="金額を入力"
          type="submit"
          disabled={isSubmitting}
        />
      </Box>
    </Box>
  );
};
