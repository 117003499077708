import React from "react";
import { CommonSplitLayout } from "./common/CommonSplitLayout";
import { PrecutRoute } from "../PrecutRoute";
import { Order } from "../../pages/Home/Order";

export const PrecutLayout: React.FC = () => {
  return (
    <CommonSplitLayout
      routeWrapper={PrecutRoute}
      headerTitle="木材"
      leftPanel={<Order />}
    />
  );
};
