import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { PrivateRoute } from "../PrivateRoute";
import { TabBar } from "../../uis/Bars/Tab";
import Sidebar from "../../uis/Bars/Sidebar";
import { Header } from "../../uis/Bars/Header";
import { MessageRooms } from "../../pages/Home/MessageRooms/MessageRooms";
import { messageTabs } from "./MessageRoomsLayout";
import { OrderMessageRooms } from "../../pages/Home/MessageRooms/OrderMessageRooms";
import { Color } from "../../utils/color";
import BottomBar from "../../uis/Bars/BottomBar";

export const MessageRoomLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  // 画面幅が sm 以下かどうかを判定
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // パスからタブのインデックスを取得
  const getTabIndexFromPath = (path: string) => {
    return path.includes("order") ? 1 : 0; // デフォルトは最初のタブ
  };

  // インデックスからパスを取得
  const getPathFromTabIndex = (index: number) => {
    return messageTabs[index]?.path || messageTabs[0].path; // デフォルトは最初のパス
  };

  const [selectedTab, setSelectedTab] = useState<number>(
    getTabIndexFromPath(location.pathname),
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const newPath = getPathFromTabIndex(newValue);
    navigate(newPath);
  };

  return (
    <PrivateRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        {!isMobile && <Sidebar isLoggedIn={true} />}
        <Box
          sx={{
            width: isMobile ? "100%" : "80%",
          }}
        >
          <Header title="トーク" />
          {!isMobile && (
            <TabBar
              tabs={messageTabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
              tabSx={(selected) => ({
                backgroundColor: selected ? Color.Main : "#FFFFFF",
                color: selected ? "#FFFFFF" : Color.Main,
                borderRadius: "4px", // Optional styling
                border: "1px solid #CCCCCC", // Add border to create a frame around each tab
                transition: "background-color 0.3s, color 0.3s", // Smooth transition
                "&:hover": {
                  backgroundColor: selected ? Color.Main : "#F0F0F0",
                },
              })}
              spanStyle={(selected) => ({
                color: selected ? "#FFFFFF" : Color.Main,
                fontWeight: "bold",
                fontSize: "1.2rem",
              })}
            />
          )}
          <Box sx={{ display: "flex", width: "100%" }}>
            {!isMobile && (
              <Box
                sx={{
                  width: "30%",
                }}
              >
                {location.pathname.includes("order") ? (
                  <OrderMessageRooms /> // orderが含まれている場合はOrderMessageRoomsを表示
                ) : (
                  <MessageRooms /> // それ以外はMessageRoomsを表示
                )}
              </Box>
            )}
            <Box sx={{ width: isMobile ? "100%" : "70%", height: "100%" }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
        {/* スマホ画面ならBottomBarを表示 */}
        {isMobile && <BottomBar isLoggedIn={true} />}
      </Box>
    </PrivateRoute>
  );
};
