import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ItemType } from "../utils/types/item_type";

export const Stock = ({
  itemId,
  stockId,
  itemName,
}: {
  itemId: number;
  stockId?: number;
  itemName: ItemType;
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "30%", display: "flex", alignItems: "center" }}>
      {/* テキスト */}
      <Typography
        onClick={() =>
          navigate(`/user/stock_${itemName}s/${stockId}/${itemId}`)
        }
        sx={{
          color: "blue", // 変化する色
          textDecoration: "underline",
          cursor: "pointer", // 変化するカーソル
          "&:hover": {
            textDecoration: "underline", // ホバー時の下線
          },
        }}
      >
        在庫情報を確認する
      </Typography>
    </Box>
  );
};
