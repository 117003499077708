import React, { useRef, useEffect, useState, useContext } from "react";
import {
  Box,
  List,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Button,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { MessageItem } from "../../uis/Item/MessageItem";
import { ReadonlyResponse } from "../../utils/types/general_type";
import { Messages, SendMessage } from "../../hooks/Message/useMessageHook";
import { ReadonlyGetUser } from "../../hooks/useUserHook";
import { IssueButtons } from "../../uis/Button/IssueButtons";
import { ItemMessageType } from "../../utils/types/item_type";
import { StripeDialog } from "../../uis/Dialog/StripeDialog";
import { UserContext } from "../../contexts/UserContext";
import { Industry } from "../../utils/industry";
import { BaseOrder } from "../../utils/types/base_type";

// 新しいコンポーネント: DateSeparator
interface DateSeparatorProps {
  date: Date;
}

const DateSeparator: React.FC<DateSeparatorProps> = ({ date }) => {
  const formattedDate = `${date.getFullYear()}年${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}月${date.getDate().toString().padStart(2, "0")}日`;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#e0e0e0",
        color: "#ffffff",
        borderRadius: "12px",
        padding: "4px 12px",
        marginY: "16px",
        alignSelf: "center",
      }}
    >
      <Typography variant="body2">{formattedDate}</Typography>
    </Box>
  );
};

interface MessageTabProps {
  messages: Messages;
  partner: ReadonlyGetUser;
  mutateAsync: (data: SendMessage) => Promise<ReadonlyResponse>;
  isMutatePending: boolean;
  type: ItemMessageType | undefined;
  isDeliveryNoteButtonShown: boolean;
  isInvoiceButtonShown: boolean;
  isPaymentShown: boolean;
  order?: BaseOrder;
  isStripeAccountCompletedShown?: boolean;
  isPaymentActionRequired?: boolean;
}

export const MessageTab = ({
  messages,
  partner,
  mutateAsync,
  isMutatePending,
  type,
  isDeliveryNoteButtonShown,
  isInvoiceButtonShown,
  isPaymentShown,
  order,
  isStripeAccountCompletedShown = false,
  isPaymentActionRequired = false,
}: MessageTabProps) => {
  const user = useContext(UserContext);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // 自動スクロール
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleSendMessage = async () => {
    if (isMutatePending) return;
    if (!inputMessage && !selectedFile) return;

    const sendMessageData: SendMessage = {
      content: inputMessage,
      file: selectedFile,
    };

    try {
      await mutateAsync(sendMessageData);
      setInputMessage("");
      setSelectedFile(null); // ファイル選択をリセット
    } catch (error) {
      console.error(error);
    }
  };

  // 日付セパレーターを挿入したメッセージリストを生成
  const renderMessagesWithDate = () => {
    const elements: React.ReactNode[] = [];
    let lastMessageDate: string | null = null;

    messages.forEach((message) => {
      const messageDate = new Date(message.created_at);
      const formattedMessageDate = messageDate.toDateString(); // 比較用

      if (formattedMessageDate !== lastMessageDate) {
        // 日付が変わったらセパレーターを挿入
        elements.push(
          <DateSeparator key={`separator-${message.id}`} date={messageDate} />,
        );
        lastMessageDate = formattedMessageDate;
      }

      elements.push(
        <MessageItem
          key={message.id}
          message={message}
          user={user}
          partner={partner}
          type={type}
        />,
      );
    });

    return elements;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "80vh", // 画面全体の高さを使用
        width: "100%",
        bgcolor: "background.paper",
        borderLeft: !order ? "1px solid #e0e0e0" : undefined,
      }}
    >
      {/* トップセクション */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        {/* 左側: company_name */}
        <Typography variant="h6">
          {partner?.company_name || "会社名がありません"}
        </Typography>
        {/* 右側: 電話するボタン */}
        {partner?.phone_number && (
          <Button
            component="a"
            href={`tel:${partner.phone_number}`}
            variant="contained"
            sx={{
              backgroundColor: "red",
              borderRadius: "30px",
              color: "white",
              "&:hover": {
                backgroundColor: "#cc0000",
              },
            }}
          >
            電話する
          </Button>
        )}
      </Box>

      <Box sx={{ flex: 1, overflowY: "auto", p: 2 }}>
        <List>
          {renderMessagesWithDate()}
          <div ref={messagesEndRef} />
        </List>
      </Box>

      {/* ファイルプレビュー */}
      {selectedFile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#f5f5f5",
            p: 1,
            mb: 1,
            borderRadius: 1,
          }}
        >
          {selectedFile.type.startsWith("image/") ? (
            <img
              src={URL.createObjectURL(selectedFile)}
              alt={selectedFile.name}
              style={{ maxHeight: 100, marginRight: 16, borderRadius: 4 }}
            />
          ) : (
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {selectedFile.name}
            </Typography>
          )}
          <IconButton color="error" onClick={handleRemoveFile}>
            <Delete />
          </IconButton>
        </Box>
      )}
      {type !== "message" && (
        <IssueButtons
          type={type}
          isGreeWoodApplied={
            isDeliveryNoteButtonShown && user?.industry === Industry.Forestry
          }
          isDeliveryNoteButtonShown={isDeliveryNoteButtonShown}
          isInvoiceButtonShown={isInvoiceButtonShown}
          order={order}
        />
      )}
      {isPaymentActionRequired ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            p: 2,
            borderTop: "1px solid #ccc",
          }}
        >
          <Typography>支払い待ちです</Typography>
        </Box>
      ) : isPaymentShown ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            p: 2,
            borderTop: "1px solid #ccc",
          }}
        >
          {/* 支払いボタン */}
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setDialogOpen(true)}
            sx={{ mt: 1 }}
          >
            支払いをする
          </Button>
        </Box>
      ) : isStripeAccountCompletedShown && type !== "message" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            p: 2,
            borderTop: "1px solid #ccc",
          }}
        >
          <Typography>
            相手ユーザーがStripeを登録していないため支払いをすることができません
          </Typography>
        </Box>
      ) : null}
      {/* メッセージ入力セクション */}
      <Box
        sx={{
          borderTop: "1px solid #e0e0e0",
          p: 2,
          bgcolor: "background.paper",
        }}
      >
        {/* 下記のBoxをrelativeにして、TextField内部（右下）に送信ボタンを重ねる */}
        <Box sx={{ position: "relative" }}>
          <TextField
            fullWidth
            value={inputMessage}
            onChange={handleInputChange}
            placeholder="メッセージを入力"
            multiline
            minRows={3} // 高さを大きく
            maxRows={6}
            variant="outlined"
            // 右下ボタンとの重なりを考慮して、右側に余白を追加
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton component="label" disabled={isMutatePending}>
                    {/* +アップロードという表示を追加 */}
                    <Typography variant="body2" sx={{ ml: 0.5 }}>
                      +アップロード
                    </Typography>
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange}
                      accept="*"
                    />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                pr: 10, // 送信ボタンが重ならないように右側にパディング
              },
            }}
          />
          {/* TextField の右下に重ねて送信ボタンを配置 */}
          <Button
            onClick={handleSendMessage}
            disabled={isMutatePending || (!inputMessage && !selectedFile)}
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "20px",
              px: 3,
              py: 1,
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            送信する
          </Button>
        </Box>
      </Box>
      {dialogOpen && order && type && type !== "message" && (
        <StripeDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          type={type}
          orderId={order.id}
        />
      )}
    </Box>
  );
};
