import React from "react";
import { CommonSplitLayout } from "./common/CommonSplitLayout";
import { MarketRoute } from "../MarketRoute";
import { Order } from "../../pages/Home/Order";

export const MarketLayout: React.FC = () => {
  return (
    <CommonSplitLayout
      routeWrapper={MarketRoute}
      headerTitle="丸太"
      leftPanel={<Order />}
    />
  );
};
