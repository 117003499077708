import { BlueprintDetail } from "../hooks/Blueprint/type";
import { LogDetail } from "../hooks/Log/type";
import { StockLogDetail } from "../hooks/Log/useStockLogHook";
import { StockWoodDetail } from "../hooks/Wood/useStockWoodHook";
import { WoodDetail } from "../hooks/Wood/type";
import { PickSameDetailProperties } from "./types/item_type";
import { FreeDetail } from "../hooks/Free/type";
import { LogMarketPriceDetail } from "../hooks/useLogMarketPriceHook";

const defaultRow: PickSameDetailProperties = {
  origin: "",
  species: "",
  grade: "",
  length: 0,
  unit_volume: 0,
};

export const defaultWoodRow: WoodDetail = {
  ...defaultRow,
  id: 0,
  name: "",
  width: 0,
  material: "",
  height: 0,
  total_volume: 0,
  quantity: 0,
};

export const defaultLogRow: LogDetail = {
  ...defaultRow,
  top_diameter: 0,
  total_volume: 0,
  quantity: 0,
  amount: 0,
};

export const defaultLogMarketPriceRow: LogMarketPriceDetail = {
  ...defaultRow,
  top_diameter: 0,
  total_volume: 0,
  market_price: 0,
};

export const defaultBlueprintRow: BlueprintDetail = {
  ...defaultRow,
  name: "",
  material: "",
  width: 0,
  height: 0,
  amount: 0,
  total_volume: 0,
  quantity: 0,
};

export const defaultStockWoodRow: StockWoodDetail = {
  ...defaultRow,
  name: "",
  material: "",
  width: 0,
  height: 0,
  quantity: 0,
};

export const defaultStockLogRow: StockLogDetail = {
  ...defaultRow,
  top_diameter: 0,
  quantity: 0,
};

export const defaultFreeRow: FreeDetail = {
  name: "",
  amount: 0,
  quantity: 0,
  cubic_meter: 0,
};
