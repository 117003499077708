import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Loading } from "../../../../../uis/Loading";
import { Error } from "../../../../../uis/Error";
import { useGetLogMarketPriceById } from "../../../../../hooks/useLogMarketPriceHook";
import { useParams } from "react-router-dom";
import { logMarketPriceColumnsForTable } from "../../../../../utils/columns";

export const PastLogMarketPrice = () => {
  const { log_market_price_id } = useParams<{ log_market_price_id: string }>();
  const { data, error, isPending } = useGetLogMarketPriceById(
    Number(log_market_price_id),
  );

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {logMarketPriceColumnsForTable.map((col) => (
              <TableCell key={col.field as string}>{col.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.log_market_price_details &&
            data.log_market_price_details.length > 0 &&
            data.log_market_price_details.map(
              (log_market_price_detail, index) => (
                <TableRow key={index}>
                  {logMarketPriceColumnsForTable.map((col) => (
                    <TableCell key={col.field}>
                      <span style={{ color: "gray" }}>
                        {col.type === "number"
                          ? Number(
                              log_market_price_detail[col.field],
                            ).toLocaleString()
                          : log_market_price_detail[col.field]}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ),
            )}
        </TableBody>
      </Table>
    </Box>
  );
};
