import React, { useEffect, useRef } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";

interface SearchBoxProps {
  placeholder: string;
  isMobile: boolean;
  onClick?: () => void;
  // onChange によって、パラメータを設定できるよう外部に提供
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  // searchClicked によって、入力フィールドのフォーカス制御を外部で管理可能
  searchClicked?: boolean;
  // Menubarの表示を外部のboolean値で判定
  showMenubar?: boolean;
  // Menubarが押されたときの処理を外部に委譲
  onMenuClick?: () => void;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  placeholder,
  isMobile,
  onClick,
  onChange,
  value,
  searchClicked = false,
  showMenubar = false,
  onMenuClick,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // searchClicked の状態に応じて入力フィールドにフォーカス
  useEffect(() => {
    if (searchClicked && inputRef.current) {
      inputRef.current.focus();
    } else if (inputRef.current) {
      inputRef.current.blur();
    }
  }, [searchClicked]);

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      onClick={onClick}
      onChange={onChange}
      value={value}
      inputRef={inputRef}
      InputProps={{
        startAdornment: showMenubar ? (
          <InputAdornment position="start">
            <IconButton
              aria-label="open menu"
              onClick={onMenuClick}
              edge="start"
              sx={{ mr: 1 }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="search" size="large">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          backgroundColor: "#F2F2F2",
          color: "gray",
          borderRadius: "50px",
        },
      }}
      sx={{
        width: "100%",
        marginTop: isMobile ? 1 : 2,
        borderRadius: "50px",
        border: "1px solid #ccc",
      }}
    />
  );
};
