import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { SearchBox } from "../../../uis/SearchBox/SearchBox";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import {
  useCreateMessageRoom,
  useGetMessageRooms,
} from "../../../hooks/Message/useMessageRoomHook";
import Avatar from "../../../uis/Avatar";
import { useNavigate } from "react-router-dom";
import { useSearchMessageRoomUsers } from "../../../hooks/useUserHook";
import { useReadMessage } from "../../../hooks/Message/useMessageHook";
import { UnreadBadge } from "../../../uis/Badge";
import { PaginationComponent } from "../../../uis/Pagination";
import { useSearchAndPaginationHook } from "../../../hooks/useSearchAndPaginationHook";
import { formatJapaneseDate } from "../../../utils/time";

export const MessageRooms = () => {
  const navigate = useNavigate();
  // カスタムフック
  const { page, searchText, handlePageChange, handleSearchChange } =
    useSearchAndPaginationHook();

  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error, isPending } = useGetMessageRooms({
    page,
  });
  const { data: users, error: searchError } = useSearchMessageRoomUsers({
    searchText,
  });
  const { mutateAsync } = useCreateMessageRoom();
  const { mutateAsync: mutateUpdateAsync } = useReadMessage();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };

  const handleCreate = async (userId?: string) => {
    if (!userId) {
      navigate("/signin");
      return;
    }
    try {
      const res = await mutateAsync({ partner_id: userId });
      navigate(`/talk/message_room/${res.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      // setDisabled(false);
    }
  };

  const handleClick = async (roomId: number) => {
    try {
      await mutateUpdateAsync(roomId);
      navigate(`/talk/message_room/${roomId}`);
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  // エラー時の処理
  if (error || searchError) {
    return <Error message={error?.message || searchError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        height: "85vh", // 全体コンテナの高さを指定
      }}
    >
      <SearchBox
        placeholder="会社名を検索"
        isMobile
        onClick={handleSearchBoxClick}
        onChange={handleSearchChange}
        value={searchText}
        searchClicked={searchClicked} // ここで渡す
      />
      {searchClicked && users && users.length > 0 && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            marginTop: 1,
            padding: 2,
          }}
        >
          {users.map((user) => (
            <Typography
              key={user.id}
              sx={{
                padding: "8px 16px",
                borderBottom: "1px solid #e0e0e0",
                "&:hover": { backgroundColor: "#f5f5f5" },
              }}
              onClick={() => handleCreate(user?.user_id)}
            >
              {user.company_name !== "" ? user.company_name : "会社名なし"}
            </Typography>
          ))}
        </Box>
      )}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          overflowY: "auto", // 縦方向にスクロール
          width: "100%",
          mt: 2,
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {data && data.messageRooms && data.messageRooms.length > 0 ? (
            data.messageRooms.map((room) => (
              <ListItem
                key={room.id}
                alignItems="flex-start"
                onClick={() => handleClick(room.id)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  padding: theme.spacing(2),
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <ListItemAvatar>
                  {/* アイコンの表示、ここでは仮にAvatarを使用 */}
                  <Avatar user={room.user} isMobile={isMobile} />
                </ListItemAvatar>
                <ListItemText
                  primary={room.user.company_name} // 名前表示
                  secondary={
                    <Box>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {room.message?.content} {/* メッセージの内容 */}
                      </Typography>
                      {room.message && (
                        <Typography
                          variant="caption"
                          component="div"
                          color="text.secondary"
                          sx={{ mt: 0.5 }}
                        >
                          {formatJapaneseDate(
                            new Date(room.message.created_at),
                          )}
                        </Typography>
                      )}
                    </Box>
                  }
                  secondaryTypographyProps={{ component: "div" }}
                />
                {/* 未読件数の表示 */}
                <UnreadBadge
                  badgeContent={room.unread_count}
                  color="error"
                  overlap="circular"
                />
              </ListItem>
            ))
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mt: 2, textAlign: "center" }}
            >
              メッセージルームが見つかりません。
            </Typography>
          )}
        </List>
      </Box>
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isMobile={isMobile}
        />
      )}
    </Box>
  );
};
