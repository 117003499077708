import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { blueprintOrderKeys, userKeys } from "../../utils/query-key";
import { ReadonlyResponse } from "../../utils/types/general_type";
import { BlueprintDetail } from "./type";
import { BaseOrder, ReadonlyBaseOrder } from "../../utils/types/base_type";

export function useUpdateBlueprintOrderByReceiver() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: BaseOrder) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/blueprint_order/receiver`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id: data.id,
            total_order_amount: data.total_order_amount,
            // created_at,updated_atを送信しないように明示する
            blueprint_details: data.blueprint_details.map((it) => {
              return {
                name: it.name,
                species: it.species,
                material: it.material,
                origin: it.origin,
                grade: it.grade,
                width: it.width,
                height: it.height,
                length: it.length,
                unit_volume: it.unit_volume,
                quantity: it.quantity,
                total_volume: it.total_volume,
                amount:
                  data.total_order_amount && data.total_order_amount > 0
                    ? undefined
                    : it.amount,
              } satisfies BlueprintDetail;
            }),
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      toast.success("設計図面を更新しました");
      await queryClient.invalidateQueries({
        queryKey: blueprintOrderKeys.receiver(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.item({
          filterType: "applying",
        }),
      });
    },
  });
}

export function useGetBlueprintOrderByReceiver(id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: blueprintOrderKeys.receiver(id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/blueprint_order/receiver/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyBaseOrder>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
