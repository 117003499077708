import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginLayout } from "./layout/LoginLayout";
import { SignIn } from "../pages/Auth/SignIn";
import { SignUp } from "../pages/Auth/SignUp";
import { HomeLayout } from "./layout/HomeLayout";
import { CreateNotification } from "../pages/Home/CreateNotification";
import { MessageRooms } from "../pages/Home/MessageRooms/MessageRooms";
import { Order } from "../pages/Home/Order";
import { CreateWoodList } from "../pages/Home/Wood/CreateWoodList";
import { AdminLayout } from "./layout/AdminLayout";
import { UserComponent } from "../pages/Home/User";
import { Profile } from "../pages/Home/Profile/index";
import { ProfileLayout } from "./layout/ProfileLayout";
import { CreateLogList } from "../pages/Home/Log/CreateLogList";
import { Applying } from "../pages/Home/RequestList/Applying";
import { LogMailing } from "../pages/Home/Log/LogMailing";
import { ManagementLayout } from "./layout/ManagementLayout";
import { Stock } from "../pages/Home/Stock";
import { Document } from "../pages/Home/Management/Document";
import { Money } from "../pages/Home/Management/Money";
import { OrderMessageRooms } from "../pages/Home/MessageRooms/OrderMessageRooms";
import { MessageRoom } from "../pages/Home/MessageRoom";
import { OrderMessageRoom } from "../pages/Home/OrderMessageRoom";
import { WoodTemporallyStored } from "../pages/Home/Wood/WoodTemporallyStored";
import { WoodOrderHistory } from "../pages/Home/Wood/WoodOrderHistory";
import { PrecutLayout } from "./layout/PrecutLayout";
import { LogOrderLayout } from "./layout/LogOrderLayout";
import { LogTemporallyStored } from "../pages/Home/Log/LogTemporallyStored";
import { LogOrderHistory } from "../pages/Home/Log/LogOrderHistory";
import { WoodMailing } from "../pages/Home/Wood/WoodMailing";
import { MessageRoomsLayout } from "./layout/MessageRoomsLayout";
import { LogMarketPrice } from "../pages/Home/Data/LogMarketPrice/LogMarketPrice";
import { DataLayout } from "./layout/DataLayout";
import { LatestLogMarketPrice } from "../pages/Home/Data/LogMarketPrice/LatestLogMarketPrice";
import { CreateBlueprintList } from "../pages/Home/Blueprint/CreateBlueprintList";
import { BlueprintLayout } from "./layout/BlueprintLayout";
import { BlueprintMailing } from "../pages/Home/Blueprint/BlueprintMailing";
// import { CreateBlueprintDetailList } from "../pages/Home/Blueprint/CreateBlueprintDetailList";
import { BlueprintTemporallyStored } from "../pages/Home/Blueprint/BlueprintTemporallyStored";
import { BlueprintOrderHistory } from "../pages/Home/Blueprint/BlueprintOrderHistory";
import { WoodQuotation } from "../pages/Home/WoodQuotation";
import { MessageRoomLayout } from "./layout/MessageRoomLayout";
import { ThreeSplitScreenLayout } from "./layout/ThreeSplitScreenLayout";
import { HomeNotificationList } from "../pages/Home/Home/notification_list";
import { QuotationList } from "../pages/Home/Management/Document/QuotationList";
import { OrderFormList } from "../pages/Home/Management/Document/OrderFormList";
import { DeliveryNoteList } from "../pages/Home/Management/Document/DeliveryNoteList";
import { InvoiceList } from "../pages/Home/Management/Document/InvoiceList";
import { WoodOrders } from "../pages/Home/Wood/WoodOrders";
import { LogOrders } from "../pages/Home/Log/LogOrders";
import { BlueprintOrders } from "../pages/Home/Blueprint/BlueprintOrders";
import { OriginCertificate } from "../pages/Home/Management/Document/OriginCertificate";
import TermsOfService from "../pages/TermsOfService";
import { MyNotifications } from "../pages/Home/MyNotifications";
import { PastLogMarketPrices } from "../pages/Home/Data/LogMarketPrice/PastLogMarketPrices";
import { PastLogMarketPrice } from "../pages/Home/Data/LogMarketPrice/PastLogMarketPrice";
import { TransactionAmount } from "../pages/Home/Management/Money/TransactionAmount";
import { PasswordReset } from "../pages/Auth/PasswordReset";
import {
  DATA_LOG_MARKET_PRICE_PATH,
  DATA_ORDER_QUANTITY_PATH,
  MANAGEMENT_DOCUMENT_PATH,
  MANAGEMENT_MONEY_PATH,
  MANAGEMENT_STOCK_PATH,
  ORDER_BASE_PATH,
  ORDER_MAILING_PATH,
} from "../utils/constant";
import { Payment } from "../pages/Home/Management/Money/Payment";
import SpecifiedCommercialTransactionAct from "../pages/SpecifiedCommercialTransactionAct";
import { Inquiry } from "../pages/Inquiry";
import { SawmillPrecutLayout } from "./layout/SawmillPrecutLayout";
import { CreateFreeList } from "../pages/Home/Free/CreateFreeList";
import { FreeMailing } from "../pages/Home/Free/FreeMailing";
import { FreeTemporallyStored } from "../pages/Home/Free/FreeTemporallyStored";
import { FreeOrderHistory } from "../pages/Home/Free/FreeOrderHistory";
import { FreeOrders } from "../pages/Home/Free/FreeOrders";
import { InputStockWood } from "../pages/Home/Management/Stock/Wood/InputStockWood";
import { LogReceiverLayout } from "./layout/LogReceiverLayout";
import { InputStockLog } from "../pages/Home/Management/Stock/Log/InputStockLog";
import { UserStockWoods } from "../pages/Home/Wood/UserStockWoods";
import { UserStockLogs } from "../pages/Home/Log/UserStockLogs";
import { TemporarilyStoredNotifications } from "../pages/Home/TemporarilyStoredNotifications";
import { NotificationDetail } from "../pages/Home/NotificationDetail";
import { OrderQuantity } from "../pages/Home/Data/OrderQuantity";
import { TransactionVolume } from "../pages/Home/Data/OrderQuantity/TransactionVolume";
import { ReceivingList } from "../pages/Home/RequestList/ReceivingList";
import { PendingList } from "../pages/Home/RequestList/PendingList";
import { DenyingList } from "../pages/Home/RequestList/DenyingList";
import { MarketLayout } from "./layout/MarketLayout";
import { LogQuotation } from "../pages/Home/LogQuotation";
import { FreeQuotation } from "../pages/Home/FreeQuotation";
import { CreateBlueprintDetailList } from "../pages/Home/Blueprint/CreateBlueprintDetailList";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/specified_commercial_transaction_act"
          element={<SpecifiedCommercialTransactionAct />}
        />
        <Route path="/terms_of_service" element={<TermsOfService />} />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route element={<LoginLayout />}>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/password_reset" element={<PasswordReset />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route path={ORDER_BASE_PATH} element={<Order />} />
          <Route
            path={`${DATA_LOG_MARKET_PRICE_PATH}/:log_market_price_id`}
            element={<PastLogMarketPrice />}
          />
        </Route>
        <Route element={<ThreeSplitScreenLayout />}>
          <Route path="/home" element={<HomeNotificationList />} />
          <Route path="/home/notifications" element={<MyNotifications />} />
          <Route
            path="/home/temporarily_stored/notifications"
            element={<TemporarilyStoredNotifications />}
          />
          <Route
            path="/home/notification/:notification_id"
            element={<CreateNotification />}
          />
          <Route
            path="/home/notification/detail/:notification_id"
            element={<NotificationDetail />}
          />
          <Route
            path={`${DATA_LOG_MARKET_PRICE_PATH}/latest`}
            element={<LatestLogMarketPrice />}
          />
          <Route
            path={`${DATA_LOG_MARKET_PRICE_PATH}/past`}
            element={<PastLogMarketPrices />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/quotation_list`}
            element={<QuotationList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/order_form_list`}
            element={<OrderFormList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/delivery_note_list`}
            element={<DeliveryNoteList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/invoice_list`}
            element={<InvoiceList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/certificate`}
            element={<OriginCertificate />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/request_list`}
            element={<Applying />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/consent_list`}
            element={<ReceivingList />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/pending_list`}
            element={<PendingList />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/decline_list`}
            element={<DenyingList />}
          />
          <Route
            path={`${MANAGEMENT_MONEY_PATH}/transaction_amount`}
            element={<TransactionAmount />}
          />
          <Route
            path={`${DATA_ORDER_QUANTITY_PATH}/transaction_volume`}
            element={<TransactionVolume />}
          />
          <Route
            path={`${MANAGEMENT_MONEY_PATH}/payment`}
            element={<Payment />}
          />
        </Route>
        <Route element={<ProfileLayout />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route element={<MarketLayout />}>
          <Route
            path={`${ORDER_BASE_PATH}/log_quotation/:log_order_id`}
            element={<LogQuotation />}
          />
        </Route>
        <Route element={<LogOrderLayout />}>
          <Route
            path={`${ORDER_BASE_PATH}/wood_quotation/:wood_order_id`}
            element={<WoodQuotation />}
          />
          <Route
            path={`${ORDER_MAILING_PATH}/log/:log_id`}
            element={<LogMailing />}
          />
          <Route
            path="/user/stock_logs/:stock_log_id/:log_id"
            element={<UserStockLogs />}
          />
          <Route
            path={`${ORDER_MAILING_PATH}/free/:free_id`}
            element={<FreeMailing />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/create_free_list/:id`}
            element={<CreateFreeList />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/create_log_list/:id`}
            element={<CreateLogList />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/log_temporally_stored`}
            element={<LogTemporallyStored />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/log_order_history`}
            element={<LogOrderHistory />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/log_orders`}
            element={<LogOrders />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/free_temporally_stored`}
            element={<FreeTemporallyStored />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/free_order_history`}
            element={<FreeOrderHistory />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/free_orders`}
            element={<FreeOrders />}
          />
        </Route>
        <Route element={<SawmillPrecutLayout />}>
          <Route
            path={`${ORDER_BASE_PATH}/create_blueprint_detail_list/:id`}
            element={<CreateBlueprintDetailList />}
          />
          <Route
            path={`${MANAGEMENT_STOCK_PATH}/input_stock_wood/:id`}
            element={<InputStockWood />}
          />
        </Route>
        <Route element={<LogReceiverLayout />}>
          <Route
            path={`${MANAGEMENT_STOCK_PATH}/input_stock_log/:id`}
            element={<InputStockLog />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/free_quotation/:free_order_id`}
            element={<FreeQuotation />}
          />
        </Route>
        <Route element={<PrecutLayout />}>
          <Route
            path={`${ORDER_BASE_PATH}/create_wood_list/:id`}
            element={<CreateWoodList />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/wood_temporally_stored`}
            element={<WoodTemporallyStored />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/wood_order_history`}
            element={<WoodOrderHistory />}
          />
          <Route
            path={`${ORDER_MAILING_PATH}/wood/:wood_id`}
            element={<WoodMailing />}
          />
          <Route
            path="/user/stock_woods/:stock_wood_id/:wood_id"
            element={<UserStockWoods />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/wood_orders`}
            element={<WoodOrders />}
          />
        </Route>
        <Route element={<BlueprintLayout />}>
          <Route
            path={`${ORDER_BASE_PATH}/create_blueprint_list/:blueprint_id`}
            element={<CreateBlueprintList />}
          />
          <Route
            path={`${ORDER_MAILING_PATH}/blueprint/:blueprint_id`}
            element={<BlueprintMailing />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/blueprint_temporally_stored`}
            element={<BlueprintTemporallyStored />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/blueprint_order_history`}
            element={<BlueprintOrderHistory />}
          />
          <Route
            path={`${ORDER_BASE_PATH}/blueprint_orders`}
            element={<BlueprintOrders />}
          />
        </Route>
        <Route element={<MessageRoomsLayout />}>
          <Route path="/talk/message_rooms" element={<MessageRooms />} />
          <Route
            path="/talk/order_message_rooms"
            element={<OrderMessageRooms />}
          />
        </Route>
        <Route element={<MessageRoomLayout />}>
          <Route
            path="/talk/message_room/:message_room_id"
            element={<MessageRoom />}
          />
          <Route
            path="/talk/wood_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/talk/log_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/talk/free_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/talk/blueprint_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
        </Route>
        <Route element={<ManagementLayout />}>
          <Route path={MANAGEMENT_STOCK_PATH} element={<Stock />} />
          <Route path={MANAGEMENT_DOCUMENT_PATH} element={<Document />} />
          <Route path={MANAGEMENT_MONEY_PATH} element={<Money />} />
        </Route>
        <Route element={<DataLayout />}>
          <Route
            path={DATA_LOG_MARKET_PRICE_PATH}
            element={<LogMarketPrice />}
          />
          <Route path={DATA_ORDER_QUANTITY_PATH} element={<OrderQuantity />} />
        </Route>
        <Route element={<AdminLayout />}>
          <Route path="/user" element={<UserComponent />} />
        </Route>
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </BrowserRouter>
  );
};
