import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { messageRoomKeys, userKeys } from "../../utils/query-key";
import {
  ReadonlyResponse,
  ReadonlyResponseWithURL,
  TimeStamp,
} from "../../utils/types/general_type";
import { UUID } from "crypto";
export interface MessageRequest {
  message_room_id: number;
  content?: string;
}

export interface Message extends TimeStamp {
  id: number;
  user_id: UUID;
  content: string | null;
  file_path?: string;
  presigned_url: string;
  message_room_id: number;
}
export type Messages = ReadonlyArray<Message>;

export interface SendMessage {
  content: string | null;
  file: File | null;
}

export function useCreateMessage(message_room_id: number) {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: SendMessage) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          message_room_id: message_room_id,
          content: data.content,
          filename: data.file?.name,
        }),
      });
      const res = (await handleResponse(response)) as ReadonlyResponseWithURL;
      if (data.file && res.url) {
        const uploadResponse = await fetch(res.url, {
          method: "PUT",
          body: data.file,
        });
        if (!uploadResponse.ok) {
          throw new Error("アップロードに失敗しました");
        }
      }
      return res;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRoom(message_room_id),
      });
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRooms,
      });
    },
  });
}

export function useReadMessage() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (roomId: number) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/message`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          message_room_id: roomId,
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: messageRoomKeys.messageRooms,
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.user,
      });
    },
  });
}
