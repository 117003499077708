import { useNavigate } from "react-router-dom";
import { MenuItemComponent } from "../../../../uis/Item/MenuItem";
import { DATA_ORDER_QUANTITY_PATH } from "../../../../utils/constant";

export const OrderQuantity = () => {
  const navigate = useNavigate();

  const menuItems = [
    {
      title: "取引量",
      items: [
        {
          text: "今月・先月・今年の取引量累計",
          link: `${DATA_ORDER_QUANTITY_PATH}/transaction_volume`,
        },
      ],
    },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    navigate(link);
  };

  return (
    <MenuItemComponent
      menuItems={menuItems}
      handleClick={handleClick}
      disabled={false}
    />
  );
};
