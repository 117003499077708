import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { orderMessageRoomKeys, userKeys } from "../utils/query-key";
import {
  ReadonlyResponse,
  ReadonlyResponseWithURL,
} from "../utils/types/general_type";
import { SendMessage } from "./Message/useMessageHook";
import {
  OrderMessageRoomType,
  OrderMessageType,
} from "../utils/types/item_type";

export function useCreateOrderMessage({
  messageRoomId,
  type,
}: OrderMessageRoomType) {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: SendMessage) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${type}_order_message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_message_room_id: messageRoomId,
            content: data.content,
            filename: data.file?.name,
          }),
        },
      );
      const res = (await handleResponse(response)) as ReadonlyResponseWithURL;
      if (data.file && res.url) {
        const uploadResponse = await fetch(res.url, {
          method: "PUT",
          body: data.file,
        });
        if (!uploadResponse.ok) {
          throw new Error("アップロードに失敗しました");
        }
      }
      return res;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.orderMessageRoom(messageRoomId),
      });
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.search,
      });
    },
  });
}

export function useReadOrderMessage() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ messageRoomId, type }: OrderMessageRoomType) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${type}_order_message`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            order_message_room_id: messageRoomId,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.search,
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.user,
      });
    },
  });
}

export function useDownloadOrderMessageFileFromS3() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async ({ messageId, type }: OrderMessageType) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const apiPath =
        type === "message"
          ? "/api/message/file"
          : `/api/${type}_order_message/file`;

      // タイトル、内容、画像URLをサーバーに送信
      const presignedResponse = await fetch(`${config.backendUrl}${apiPath}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: messageId,
        }),
      });
      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "署名付きURLの取得中にエラーが発生しました",
        );
      }
      const { url } = await presignedResponse.json();
      const response = await fetch(url, {
        method: "GET",
      });
      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "S3からファイルを取得中にエラーが発生しました",
        );
      }

      const blob = await response.blob();

      return blob; // 画像のキーを取得
    },
  });
}
