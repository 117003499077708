import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { User } from "../../hooks/useUserHook";
import Avatar from "../Avatar";

interface UserDetailModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
}

export const UserDetailModal: React.FC<UserDetailModalProps> = ({
  open,
  onClose,
  user,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>ユーザーの詳細</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {user.company_image && (
            <Box sx={{ textAlign: "center" }}>
              <Avatar user={user} isMobile={false} />
            </Box>
          )}
          <Typography variant="h6">{user.company_name}</Typography>
          <Typography>
            <strong>メール:</strong> {user.email}
          </Typography>
          {user.phone_number && (
            <Typography>
              <strong>電話番号:</strong>
              <a href={`tel:${user.phone_number}`}>{user.phone_number}</a>
            </Typography>
          )}
          {user.address && (
            <Typography>
              <strong>住所:</strong> {user.address}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
