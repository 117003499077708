import { Box, Button, Menu, MenuItem } from "@mui/material";
import { OrderStatusEnum, OrderStatusType } from "../utils/types/general_type";
import { UUID } from "crypto";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MouseEvent, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useReceiveOrder } from "../hooks/useOrderHook";
import { ItemType } from "../utils/types/item_type";
import { ORDER_BASE_PATH } from "../utils/constant";

interface MenuProps {
  itemType: ItemType;
  status: OrderStatusEnum;
  userId?: UUID;
  itemId: number;
}

export const MenuComponent = ({
  itemType,
  status,
  userId,
  itemId,
}: MenuProps) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { mutateAsync: mutateReceiveOrderAsync } = useReceiveOrder();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // 注文を承諾/保留/辞退するハンドラ
  const handleClick = async (
    status: OrderStatusType,
    userId?: string,
    id?: number,
  ) => {
    // ボタンを連打できないように disabled 状態に
    setDisabled(true);

    if (!userId) {
      toast.error("ユーザーが存在しません");
      setDisabled(false);
      return;
    }

    if (!id) {
      toast.error("エラーが発生しました。");
      setDisabled(false);
      return;
    }

    try {
      await mutateReceiveOrderAsync({
        id,
        type: itemType,
        status,
        pageStatus: status,
      });
      navigate(
        `${ORDER_BASE_PATH}/${status === "receiving" ? "consent_list" : status === "pending" ? "pending_list" : "decline_list"}`,
      );
    } catch (error) {
      console.error(error);
    } finally {
      // API 終了後にボタンを再び有効に
      setDisabled(false);
    }
  };

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(e) => handleMenuOpen(e)}
          sx={{
            borderColor: "black",
            color: "black",
            "&:hover": {
              borderColor: "black",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          選択
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1,
            minWidth: 150,
          },
        }}
      >
        {status !== OrderStatusEnum.is_received && (
          <MenuItem
            onClick={() => {
              handleClick("receiving", userId, itemId);
              handleMenuClose();
            }}
            sx={{ color: "black" }}
            disabled={disabled}
          >
            注文を承諾する
          </MenuItem>
        )}
        {status !== OrderStatusEnum.is_pending && (
          <MenuItem
            onClick={() => {
              handleClick("pending", userId, itemId);
              handleMenuClose();
            }}
            sx={{ color: "black" }}
            disabled={disabled}
          >
            注文を保留する
          </MenuItem>
        )}
        {status !== OrderStatusEnum.is_denied && (
          <MenuItem
            onClick={() => {
              handleClick("denying", userId, itemId);
              handleMenuClose();
            }}
            sx={{ color: "black" }}
            disabled={disabled}
          >
            注文を辞退する
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
