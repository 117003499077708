import { Box, Modal } from "@mui/material";
import { ChangeButtons } from "../Button/ChangeButtons";

interface ChangeButtonsModalProps {
  open: boolean;
  onClose: () => void;
}

export const ChangeButtonsModal = ({
  open,
  onClose,
}: ChangeButtonsModalProps) => {
  const handleClose = (
    reason: "backdropClick" | "escapeKeyDown" | "closeButton",
  ) => {
    if (reason === "closeButton") {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      hideBackdrop={false}
    >
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 0, // ヘッダーとコンテンツを分けるためにパディングを0に
          width: { xs: "90%", sm: "80%" }, // レスポンシブ対応
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: 2,
        }}
      >
        <ChangeButtons />
      </Box>
    </Modal>
  );
};
