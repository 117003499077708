import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { documentKeys, orderMessageRoomKeys } from "../utils/query-key";
import { ItemType } from "../utils/types/item_type";
import { PAGE_SIZE, THIRTY_SECONDS } from "../utils/constant";
import {
  PaginationParams,
  ReadonlyResponse,
  Status,
} from "../utils/types/general_type";
import { toast } from "react-toastify";
import { BaseDeliveryNote, BaseOrder } from "../utils/types/base_type";

interface DeliveryNoteEmail {
  email: string;
  file_path: string;
  status?: Status;
}
export interface Document extends BaseDeliveryNote {
  type: ItemType;
  order: BaseOrder;
  // 産地証明書の申請ページでのみ使用するプロパティ
  delivery_note_emails: DeliveryNoteEmail[];
}
export type Documents = ReadonlyArray<Document>;

export interface DocumentResponse {
  documents: Documents;
  totalPages: number;
}

export function useGetQuotations({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.quotations({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/quotations?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetOrderForms({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.orderForms({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/order_forms?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetDeliveryNotes({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.deliveryNotesWithPagination({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/delivery_notes?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetInvoices({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.invoices({ page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/invoices?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

// 産地証明書の申請ページで使用する関数
export function useGetDeliveryNotesByReceiver({
  page,
  pageSize = PAGE_SIZE,
}: PaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: documentKeys.deliveryNotesByReceiverWithPagination({
      page,
      pageSize,
    }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/delivery_notes/receiver?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Readonly<DocumentResponse>>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useUpdateDeliveryNoteLegalityVerifiedChecked() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      itemName,
      id,
    }: {
      itemName: "free" | "log";
      id: number;
      messageRoomId: number;
    }) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/${itemName}_order/delivery_note`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res, variables) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: orderMessageRoomKeys.orderMessageRoom(
          variables.messageRoomId,
        ),
      });
      await queryClient.invalidateQueries({
        queryKey: documentKeys.deliveryNotes,
      });
    },
  });
}
