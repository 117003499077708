import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ProfileRoute } from "../ProfileRoute";
import Sidebar from "../../uis/Bars/Sidebar";
import BottomBar from "../../uis/Bars/BottomBar";
import { Header } from "../../uis/Bars/Header";

export const ProfileLayout: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ProfileRoute>
      {/* 
        1. 全体でheightを100vhにしてoverflowをhiddenにすることで
           ブラウザのデフォルトスクロールバーを消す。
        2. Sidebarとメインコンテンツをflexで横並びにする。
      */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          overflow: "hidden", // デフォルトのスクロールバーを表示させない
        }}
      >
        {/* モバイル表示ではサイドバーを非表示 */}
        {!isMobile && <Sidebar isLoggedIn={true} />}

        {/* メインコンテンツ */}
        <Box
          sx={{
            flex: 1,
            // 横幅が常に全体の残りを使うようにしつつ、独自にスクロールさせる
            height: "100%",
            overflowY: "auto", // ここで縦スクロールを有効にする
            // ↓ 以下はカスタムスクロールバーのデザイン例
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c1c1c1",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#a8a8a8",
            },
          }}
        >
          {isMobile && <Header />}
          <Outlet />
        </Box>
        {/* スマホ画面ならBottomBarを表示 */}
        {isMobile && <BottomBar isLoggedIn={true} />}
      </Box>
    </ProfileRoute>
  );
};
