import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ItemSearchBox } from "../../../../uis/SearchBox/ItemSearchBox";
import { Title } from "../../../../uis/Title";
import { useSearchItems } from "../../../../hooks/useItemHook";
import { Form } from "./form";
import { ORDER_BASE_PATH } from "../../../../utils/constant";

export const CreateBlueprintList = () => {
  const navigate = useNavigate();
  const { blueprint_id } = useParams<{ blueprint_id: string }>();

  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Title title="設計図送信" />
      {/* 一時保存したリストを検索するための検索ボックス（必要なら） */}
      <ItemSearchBox
        useSearchItems={useSearchItems}
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) =>
          navigate(`${ORDER_BASE_PATH}/create_blueprint_list/${id}`)
        }
        id={Number(blueprint_id)}
        itemName="blueprint"
      />
      {/* フォーム全体 */}
      <Form blueprint_id={Number(blueprint_id)} navigate={navigate} />
    </Box>
  );
};
