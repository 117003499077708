import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { toast } from "react-toastify";
import { CreateUser, UpdateUserType } from "../utils/schema";
import { userKeys } from "../utils/query-key";
import { Industry } from "../utils/industry";
import {
  FIVE_MINUTES,
  PAGE_SIZE,
  SEARCH_BOX_ITEMS,
  THIRTY_SECONDS,
} from "../utils/constant";
import {
  SearchRequest,
  ReadonlyResponse,
  TimeStamp,
  PaginationParams,
  OrderStatusWithApplying,
} from "../utils/types/general_type";
import { UUID } from "crypto";
import { ItemType, WoodLog } from "../utils/types/item_type";
import { StockWood } from "./Wood/useStockWoodHook";
import { StockLog } from "./Log/useStockLogHook";
import { ReadonlyWood, WoodDetails } from "./Wood/type";
import { LogDetails, ReadonlyLog } from "./Log/type";
import { BlueprintDetails } from "./Blueprint/type";
import { FreeDetails } from "./Free/type";
import { BaseItem, BaseOrder } from "../utils/types/base_type";

enum StripeStatusEnum {
  none = "none",
  created = "created",
  completed = "completed",
}

export interface User extends TimeStamp {
  id: number;
  email: string;
  is_admin: boolean;
  industry?: Industry; // enumを使用
  phone_number?: string;
  company_name?: string;
  admin_password?: string;
  company_image?: string;
  corporate_number?: string;
  postal_code?: string;
  address?: string;
}

interface GetUser extends User {
  user_id?: UUID;
  stripe_account_status: StripeStatusEnum;
  stock_wood?: StockWood;
  stock_log?: StockLog;
  unread_message_count: number;
  unread_order_message_count: number;
  is_received_is_pending_is_denied_all_false: number;
  is_received_true_only: number;
  is_pending_true_only: number;
  is_denied_true_only: number;
  log_order_applying_count: number;
  wood_order_applying_count: number;
  blueprint_order_applying_count: number;
  free_order_applying_count: number;
}

export type ReadonlyGetUser = Readonly<GetUser>;

export type Users = ReadonlyArray<ReadonlyGetUser>;

export function useCreateUser() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (data: CreateUser) => {
      const { token } = await getAccessToken();

      const response = await fetch(`${config.backendUrl}/api/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
    },
  });
}

export function useUpdateUser() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<UpdateUserType>) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          industry: data.industry,
          phone_number: data.phoneNumber,
          company_name: data.companyName,
          company_image: data.companyImage,
          corporate_number: data.corporateNumber,
          postal_code: data.postalCode,
          address: data.address,
          is_stripe_account_created: data.isStripeAccountCreated,
        }),
        credentials: "include",
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: userKeys.user,
      });
    },
  });
}

export function useGetUser() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.user,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/user`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyGetUser>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useSearchMessageRoomUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchMessageRoom(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/message_room/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useSearchWoodUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchWood(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/wood/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useSearchLogUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchLog(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/log/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useSearchBlueprintUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchBlueprint(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/blueprint/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export function useSearchFreeUsers({
  searchText,
  limit = SEARCH_BOX_ITEMS,
}: SearchRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.searchFree(searchText),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      if (searchText) {
        params.append("search_text", searchText);
      }
      params.append("limit", `${limit}`);
      const response = await fetch(
        `${config.backendUrl}/api/users/free/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Users>;
    },
    staleTime: FIVE_MINUTES,
  });
}

export interface GetItemUsersRequest extends PaginationParams {
  filterType: OrderStatusWithApplying;
}

export interface UnifiedItem {
  type: ItemType;
  item: BaseItem;
  my_order: BaseOrder;
  isButtonShown: boolean;
  wood_details?: WoodDetails;
  log_details?: LogDetails;
  blueprint_details?: BlueprintDetails;
  free_details?: FreeDetails;
}
interface GetItemUsersResponse {
  unifiedItems: ReadonlyArray<UnifiedItem>;
  totalPages: number;
}
export type ReadonlyGetItemUsersResponse = Readonly<GetItemUsersResponse>;
export function useGetItemUsers({
  filterType,
  page,
  pageSize = PAGE_SIZE,
}: GetItemUsersRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.itemWithPagination({ filterType, page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        page: page.toString(),
        pageSize: pageSize.toString(),
        filterType: filterType,
      } as const;

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/users/request_item?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyGetItemUsersResponse>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export interface GetUserStocks {
  woodLog: WoodLog;
  itemId: number;
  stockId: number;
  enabled?: boolean;
}
interface GetUserStocksResponse<T extends ReadonlyLog | ReadonlyWood> {
  user: ReadonlyGetUser;
  item: T;
}
export function useGetUserStocks<T extends ReadonlyLog | ReadonlyWood>({
  woodLog,
  itemId,
  stockId,
  enabled,
}: GetUserStocks) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: userKeys.stocks({ woodLog, itemId, stockId }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/user/stock_${woodLog}s/${stockId}/${itemId}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        Readonly<GetUserStocksResponse<T>>
      >;
    },
    enabled: enabled,
    staleTime: FIVE_MINUTES,
    gcTime: FIVE_MINUTES,
  });
}
