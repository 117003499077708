import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import {
  LoggingNoticeOrForestManagementPlanNumber,
  LoggingNoticeOrForestManagementPlanNumberSchema,
} from "../../utils/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import FormField from "../Forms/FormField"; // FormFieldにdisabledプロパティを渡せるように修正されている前提
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { useUpdateOrderLoggingNoticeOrForestManagementPlanNumber } from "../../hooks/useOrderHook";
import { useLocation, useParams } from "react-router-dom";
import { BaseOrder } from "../../utils/types/base_type";
import { useEffect, useState } from "react";
import {
  useGetFileFromS3ById,
  useUploadFileById,
} from "../../hooks/S3/useSignedURLHook";
import { Loading } from "../Loading";
import { Error } from "../Error";
import { FileUpload } from "../File/FileUpload";
import { toast } from "react-toastify";

interface CleanWoodLawModalProps {
  open: boolean;
  onClose: () => void;
  order?: BaseOrder;
}

export const CleanWoodLawModal = ({
  open,
  onClose,
  order,
}: CleanWoodLawModalProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isImageFromServer, setIsImageFromServer] = useState(false); // サーバーからの画像かどうかのフラグ
  const location = useLocation();
  const isLogOrder = location.pathname.includes("log_order_message_room");
  const isFreeOrder = location.pathname.includes("free_order_message_room");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch, // watch をインポート
    setValue, // setValue をインポート
  } = useForm<LoggingNoticeOrForestManagementPlanNumber>({
    resolver: zodResolver(LoggingNoticeOrForestManagementPlanNumberSchema),
  });
  const watchedNumber = watch("loggingNoticeOrForestManagementPlanNumber"); // 番号フィールドの値を監視
  const { order_message_room_id } = useParams<{
    order_message_room_id: string;
  }>();
  const { mutateAsync: mutateUploadFileToS3, isPending: isMutatePending } =
    useUploadFileById();
  const {
    data: imageData,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3ById({
    id: order?.id,
    filetype: "logging_notice_or_forest_management_plan_number",
    category: `${isLogOrder ? "log" : isFreeOrder ? "free" : undefined}_clean_wood_law`,
    // orderにファイルパスがあり、かつ番号が入力されていない場合に有効化
    enabled:
      !!order?.id &&
      !!order?.logging_notice_or_forest_management_plan_number_file_path &&
      !order?.logging_notice_or_forest_management_plan_number,
  });
  const { mutateAsync } =
    useUpdateOrderLoggingNoticeOrForestManagementPlanNumber();

  // モーダルが開かれたとき、またはorderが変わったときに初期値を設定
  useEffect(() => {
    if (order && open) {
      const initialNumber =
        order.logging_notice_or_forest_management_plan_number || "";
      const hasServerImage =
        !!order.logging_notice_or_forest_management_plan_number_file_path;

      // 初期値設定ロジック
      if (initialNumber) {
        // 番号があれば、番号をセットし、画像は選択不可＆クリア
        reset({ loggingNoticeOrForestManagementPlanNumber: initialNumber });
        setSelectedFile(null);
        setIsImageFromServer(false);
      } else if (hasServerImage) {
        // 画像があれば、番号は空にし、画像情報をセット（FileUpload内で処理）
        // reset({ loggingNoticeOrForestManagementPlanNumber: "" });
        setSelectedFile(null); // ローカル選択はクリア
        setIsImageFromServer(true); // サーバー画像フラグを立てる
      } else {
        // 両方なければリセット
        reset({ loggingNoticeOrForestManagementPlanNumber: "" });
        setSelectedFile(null);
        setIsImageFromServer(false);
      }
    } else if (!open) {
      // モーダルが閉じたら状態をリセット
      reset({ loggingNoticeOrForestManagementPlanNumber: "" });
      setSelectedFile(null);
      setIsImageFromServer(false);
    }
  }, [order, open, reset]);

  // 番号フィールドの値が変更されたときの副作用
  useEffect(() => {
    // watchedNumber が存在する (空文字列でない) 場合
    if (watchedNumber) {
      // 画像選択をクリアし、画像サーバーフラグも解除
      setSelectedFile(null);
      setIsImageFromServer(false);
      // 必要であれば、FileUploadコンポーネント側で表示されているサーバー画像プレビューもクリアする処理を追加
    }
  }, [watchedNumber]);

  // 画像が選択されたときの副作用
  useEffect(() => {
    if (selectedFile) {
      // 番号フィールドをクリア
      setValue("loggingNoticeOrForestManagementPlanNumber", "", {
        shouldValidate: true, // 必要であればバリデーションを実行
      });
      setIsImageFromServer(false); // サーバー画像フラグは解除
    }
  }, [selectedFile, setValue]);

  if (getLoading && !imageData) {
    // imageDataがまだない場合のローディング表示
    return <Loading />;
  }

  // エラー時の処理
  if (getError) {
    return <Error message={getError.message} />; // または適切なエラー表示
  }

  // 無効化状態の決定
  // - 番号フィールド: 画像が選択されているか、サーバーからの画像が表示されている場合
  const isNumberFieldDisabled = !!selectedFile;
  // - 画像アップロード: 番号が入力されている場合
  const isFileUploadDisabled = !!watchedNumber;

  const onSubmit = async (data: LoggingNoticeOrForestManagementPlanNumber) => {
    try {
      const itemName = isLogOrder ? "log" : isFreeOrder ? "free" : undefined;
      if (!order) {
        toast.error("orderが存在しません");
        return;
      }
      const filetype = selectedFile?.type === "application/pdf" ? "pdf" : "img";
      // 画像が選択されている場合 (ローカル選択)
      if (selectedFile) {
        const uploadData = {
          id: order.id,
          file: selectedFile,
          path: `${filetype}/${itemName}_clean_wood_law`,
        } as const;
        const res = await mutateUploadFileToS3(uploadData);
        await mutateAsync({
          id: order.id,
          imagePath: res, // アップロードした画像のパス
          itemName,
          messageRoomId: Number(order_message_room_id),
        });
        // 番号が入力されている場合 (画像は選択されていないはず)
      } else if (data.loggingNoticeOrForestManagementPlanNumber) {
        await mutateAsync({
          id: order.id,
          loggingNoticeOrForestManagementPlanNumber:
            data.loggingNoticeOrForestManagementPlanNumber,
          itemName,
          messageRoomId: Number(order_message_room_id),
        });
        // サーバーからの画像が表示されていて、変更がない場合（かつ番号も入力されていない）
      } else if (isImageFromServer && imageData?.objectURL) {
        // この場合は特に更新は不要かもしれないが、もし既存のパスを再送信する必要があれば記述
        // 必要であれば既存のパスを送信するロジックを追加
        // await mutateAsync({ ... });
      } else {
        // どちらも入力されていない場合（バリデーションで弾くべきだが念のため）
        toast.warn("番号または画像を入力してください。");
        return; // 送信しない
      }

      onClose(); // 成功したら閉じる
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("送信中にエラーが発生しました。");
    }
  };

  const handleClose = (
    _event: object, // イベント引数を追加（MUI ModalのonClose型に合わせる）
    reason: "backdropClick" | "escapeKeyDown" | "closeButton",
  ) => {
    // closeButton 以外の理由では閉じないようにする（バツボタン以外でのクローズを制御する場合）
    // if (reason && reason === "backdropClick") return;
    // if (reason && reason === "escapeKeyDown") return;
    // バツボタンクリック時は常に閉じる
    if (reason === "closeButton" || !reason) {
      // reasonがない場合も（直接onClose呼び出しなど）許容
      onClose();
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      // 画像が選択されたら、番号フィールドを無効化するためにisImageFromServerフラグは不要になる
      // (selectedFileが存在すること自体で制御されるため)
      // isImageFromServer は初期表示用と考える
    } else {
      // ファイル選択がキャンセルされた場合など
      setSelectedFile(null);
      // もしorderに元々画像があったなら、isImageFromServerを再度trueにするか検討
      // (ただし、ユーザーが意図的にクリアした可能性もある)
      // 現状ではnullにするだけで、番号入力が可能になる
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose} // 更新した onClose ハンドラを使用
      aria-labelledby="clean-wood-law-modal-title"
      aria-describedby="clean-wood-law-modal-description"
      disableEscapeKeyDown // Escapeキーでのクローズを無効化
    >
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 400 },
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          outline: "none",
        }}
      >
        {/* バツボタンの追加 */}
        <IconButton
          onClick={() => handleClose({}, "closeButton")} // closeButton理由で呼ぶ
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            id="clean-wood-law-modal-title"
            sx={{ marginBottom: "1rem" }}
          >
            伐採届No.もしくは森林経営計画No.を入力、または画像のどちらかのみをアップロードしてください
          </Typography>

          {/* 番号入力フィールド */}
          <FormField
            placeholder="伐採届No. or 森林経営計画No."
            register={register}
            name="loggingNoticeOrForestManagementPlanNumber"
            isError={!!errors.loggingNoticeOrForestManagementPlanNumber}
            errorMessage={
              errors.loggingNoticeOrForestManagementPlanNumber?.message
            }
            disabled={isNumberFieldDisabled} // disabled 状態を渡す
          />
          <Typography variant="body2" sx={{ textAlign: "center", my: 1 }}>
            または
          </Typography>
          {/* 画像選択部分 */}
          <FileUpload
            selectedFile={selectedFile}
            // サーバーからの画像URLは、ローカル選択がなく、番号入力もなく、imageDataが存在する場合に渡す
            filePath={
              !selectedFile && !watchedNumber && isImageFromServer
                ? imageData?.objectURL
                : undefined
            }
            // サーバーからのHEIC Blobも同様の条件で渡す
            heicBlob={
              !selectedFile && !watchedNumber && isImageFromServer
                ? imageData?.blob
                : undefined
            }
            handleFileChange={handleImageChange}
            accept={["image", "heic", "pdf"]} // 画像とHEICを受け入れる
            message="画像を選択またはドラッグ＆ドロップ"
            disabled={isFileUploadDisabled} // disabled 状態を渡す
          />
          {selectedFile && (
            <Button onClick={() => setSelectedFile(null)}>
              画像を取り除く
            </Button>
          )}
          <Box sx={{ mt: 3 }}>
            <Button
              color="primary"
              type="submit"
              // 送信ボタンは、番号が入力されているか、画像が選択されているか、サーバー画像が表示されている場合に有効
              // かつ、ローディング中でない場合
              disabled={
                isSubmitting ||
                isMutatePending ||
                (!watchedNumber && !selectedFile && !isImageFromServer) // 何も入力/選択されていない場合は非活性
              }
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                height: "3rem",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                "&:disabled": {
                  // 非活性時のスタイルを追加
                  backgroundColor: "grey.400",
                },
              }}
            >
              送信
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
