import { useGetNotification } from "../../hooks/useNotificationHook";
import { NotificationComponent } from "../common/NotificationComponent";

export const NotificationDetail = () => {
  return (
    <NotificationComponent
      isEdited={false}
      useGetNotification={useGetNotification}
    />
  );
};
