import { useEffect } from "react";
import {
  TableComponent,
  TableComponentExtendedProps,
} from "../../../../uis/Table";
import { woodColumnsForTable } from "../../../../utils/columns";
import { defaultWoodRow } from "../../../../utils/rows";
import { Wood, WoodDetail } from "../../../../hooks/Wood/type";

export const WoodTable = ({
  register,
  errors,
  setValue,
  watchedDetails,
  control,
}: TableComponentExtendedProps<Wood, WoodDetail>) => {
  useEffect(() => {
    watchedDetails?.forEach((detail, index) => {
      if (!detail) return;
      const width = detail.width ?? 0; // mm
      const height = detail.height ?? 0; // mm
      const length = detail.length ?? 0; // mm
      const quantity = detail.quantity ?? 0;

      // 単材積(㎥) = (mm^3) / (1,000,000,000)
      const newUnitVolume = (width * height * length) / 1_000_000_000;
      if (detail.unit_volume !== newUnitVolume) {
        setValue(`wood_details.${index}.unit_volume`, newUnitVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      // 材積(㎥) = 単材積(㎥) × 本数
      const newTotalVolume = newUnitVolume * quantity;
      if (detail.total_volume !== newTotalVolume) {
        setValue(`wood_details.${index}.total_volume`, newTotalVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDetails]);

  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="wood_details"
      columns={woodColumnsForTable}
      defaultRow={defaultWoodRow}
      control={control}
    />
  );
};
