import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import { StockWoodTable } from "../Management/Stock/Wood/stock_wood_table";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useGetUserStocks } from "../../../hooks/useUserHook";
import { StockWood } from "../../../hooks/Wood/useStockWoodHook";
import { useForm } from "react-hook-form";
import { UserProfile } from "../../../uis/UserProfile";
import { useEffect } from "react";
import { ReadonlyWood } from "../../../hooks/Wood/type";
import { Talk } from "../../../uis/Talk";
import { useCreateOrder } from "../../../hooks/useOrderHook";
import { ORDER_MAILING_PATH } from "../../../utils/constant";
import { formatJapaneseDate } from "../../../utils/time";

export const UserStockWoods = () => {
  const { stock_wood_id: stockWoodIdParam, wood_id: woodIdParam } = useParams<{
    stock_wood_id: string;
    wood_id: string;
  }>();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<StockWood>(); // フォームの値を管理する

  const numericWoodId = woodIdParam ? parseInt(woodIdParam, 10) : NaN;
  const numericStockWoodId = stockWoodIdParam
    ? parseInt(stockWoodIdParam, 10)
    : NaN;

  const { data, error, isPending } = useGetUserStocks<ReadonlyWood>({
    woodLog: "wood",
    itemId: numericWoodId,
    stockId: numericStockWoodId,
    enabled: !isNaN(numericWoodId) && !isNaN(numericStockWoodId),
  });
  const { mutateAsync, isPending: isMutatePending } = useCreateOrder();

  useEffect(() => {
    if (
      data &&
      data.user.stock_wood &&
      data.user.stock_wood.stock_wood_details.length > 0
    ) {
      reset(data.user.stock_wood);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  // データが存在しない、または必要な情報が欠けている場合の表示
  if (!data || !data.user || !data.item || !data.user.stock_wood) {
    return <Error message="表示に必要なデータが見つかりませんでした。" />;
  }

  const handleOrder = async () => {
    if (data && data.user.user_id) {
      try {
        await mutateAsync({
          itemName: "wood",
          itemId: numericWoodId,
          isAll: false,
          userIds: [data.user.user_id],
        });
        navigate("/order");
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Box sx={{ width: "90%", margin: "0 auto" }}>
      <UserProfile user={data.user} />
      <Talk userId={data.user.user_id} />
      <Box sx={{ marginY: 2 }}>
        {/* Linkコンポーネントで画面遷移 */}
        <Link to={`${ORDER_MAILING_PATH}/wood/${numericWoodId}`}>
          送信先選択に戻る
        </Link>
      </Box>{" "}
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{
          marginTop: 2,
          marginBottom: 1,
          color: "text.secondary",
          textAlign: "right",
        }} // 右寄せにする
      >
        在庫最終更新: {formatJapaneseDate(data.user.stock_wood.updated_at)}
      </Typography>
      <Button
        variant="contained"
        onClick={handleOrder}
        // 注文処理中、またはデータ取得中(再検証中含む)はボタンを無効化
        disabled={isMutatePending}
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          borderRadius: "8px",
          padding: "10px 24px", // パディング調整
          fontWeight: "bold",
          minWidth: "120px", // 最小幅設定
          "&:hover": {
            backgroundColor: "primary.dark",
          },
          "&:disabled": {
            opacity: 0.7, // 無効時の見た目を調整
          },
        }}
        // 処理中の視覚的フィードバック
        startIcon={
          isMutatePending ? (
            <CircularProgress size={20} color="inherit" />
          ) : null
        }
      >
        {isMutatePending ? "注文処理中..." : "注文する"}
      </Button>
      <StockWoodTable
        register={register}
        errors={errors}
        disabled={true}
        control={control}
        setValue={setValue}
      />
    </Box>
  );
};
