import { ItemType } from "./types/item_type";

export function conversionItemName(itemName?: ItemType) {
  return itemName === "wood"
    ? "木材"
    : itemName === "log"
      ? "丸太"
      : itemName === "blueprint"
        ? "設計図"
        : itemName === "free"
          ? "自由記述"
          : "";
}
