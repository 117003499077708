import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ItemSearchBox } from "../../../../uis/SearchBox/ItemSearchBox";
import { ORDER_BASE_PATH } from "../../../../utils/constant";
import { Title } from "../../../../uis/Title";
import { useSearchItems } from "../../../../hooks/useItemHook";
import { Form } from "./form";

/**
 * 木材・丸太など、すべて同じ画面フローを扱う共通コンポーネント
 */
export const CreateLogList = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  // UI（フォーム、検索、テーブルなど）もすべて共通化
  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* タイトル */}
      <Title title="丸太リスト作成" />
      {/* 3. 「丸太モード」の場合は <ItemSearchBox<Log>> を使い、検索関数は Logs を返す */}
      <ItemSearchBox
        id={Number(id)}
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) =>
          navigate(`${ORDER_BASE_PATH}/create_log_list/${id}`)
        }
        useSearchItems={useSearchItems}
        itemName="log"
      />
      {/* フォーム */}
      <Form />
    </Box>
  );
};
