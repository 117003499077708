import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { logMarketPriceKeys } from "../utils/query-key";
import { THIRTY_SECONDS } from "../utils/constant";
import {
  ReadonlyResponse,
  Status,
  TimeStamp,
} from "../utils/types/general_type";
import { toast } from "react-toastify";

export interface LogMarketPrice extends TimeStamp {
  id: number;
  file_path?: string;
  log_market_price_details: LogMarketPriceDetails;
  log_market_price_status?: Status;
}

export interface LogMarketPriceDetail {
  species: string;
  origin: string;
  grade: string;
  top_diameter: number;
  length: number;
  unit_volume: number;
  total_volume: number;
  market_price: number;
}
export type LogMarketPriceDetails = ReadonlyArray<
  LogMarketPriceDetail & Partial<TimeStamp>
>;

export type ReadonlyLogMarketPrice = Readonly<LogMarketPrice>;
type LogMarketPrices = ReadonlyArray<LogMarketPrice>;

export function useCreateLogMarketPrice() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/log_market_price`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
  });
}

export function useUpdateLogMarketPrice() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: LogMarketPrice) => {
      const { token } = await getAccessToken();
      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/log_market_price`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id: data.id,
            file_path: data.file_path,
            log_market_price_details: data.log_market_price_details.map(
              (detail) => ({
                species: detail.species,
                origin: detail.origin,
                grade: detail.grade,
                top_diameter: detail.top_diameter,
                length: detail.length,
                unit_volume: detail.unit_volume,
                total_volume: detail.total_volume,
                market_price: detail.market_price,
              }),
            ),
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: logMarketPriceKeys.log_market_price_by_id(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: logMarketPriceKeys.logMarketPrice,
      });
      toast.success("更新しました");
    },
  });
}

export function useGetLatestLogMarketPrice() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logMarketPriceKeys.logMarketPrice,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/latest_log_market_price`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyLogMarketPrice>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetLogMarketPrices() {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logMarketPriceKeys.logMarketPrices,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/log_market_prices`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<LogMarketPrices>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetLogMarketPriceById(id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logMarketPriceKeys.log_market_price_by_id(id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/log_market_price/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyLogMarketPrice>;
    },
    staleTime: THIRTY_SECONDS,
  });
}
