import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import config from "../config";
import { ItemType } from "../utils/types/item_type";
import { toast } from "react-toastify";
import { documentKeys } from "../utils/query-key";
import { InquiryType } from "../utils/schema";
import {
  ReadonlyResponse,
  ReadonlyResponseWithURL,
} from "../utils/types/general_type";
import { handleResponse } from "../utils/response";

interface Email {
  email: string;
  deliveryNoteId: number;
  type: ItemType;
  selectedFile: File;
}
export function useSendEmail() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      deliveryNoteId,
      email,
      type,
      selectedFile,
    }: Email) => {
      const { token } = await getAccessToken();

      // FormDataオブジェクトを作成
      const formData = new FormData();
      formData.append("deliveryNoteId", deliveryNoteId.toString());
      formData.append("email", email);
      formData.append("type", type);
      // 'file' というキーでファイルを追加 (バックエンドと合わせる)
      formData.append("file", selectedFile, selectedFile.name);

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/email`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData, // FormDataオブジェクトをbodyに設定
      });
      const res = (await handleResponse(response)) as ReadonlyResponseWithURL;
      if (res.url) {
        const uploadResponse = await fetch(res.url, {
          method: "PUT",
          body: selectedFile,
        });
        if (!uploadResponse.ok) {
          throw new Error("アップロードに失敗しました");
        }
      }
      return res;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: documentKeys.deliveryNotesByReceiver,
      });
    },
  });
}

export function useSendInquiry() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async ({ email, companyName, content }: InquiryType) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendInquiryUrl}/api/inquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          companyName,
          content,
          email,
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      toast.success("メールを送信しました");
    },
  });
}
