import { useMutation, useQuery } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { stockLogKeys } from "../../utils/query-key";
import { handleResponse } from "../../utils/response";
import {
  ReadonlyResponse,
  Status,
  TimeStamp,
} from "../../utils/types/general_type";
import { toast } from "react-toastify";

export interface StockLog extends TimeStamp {
  id: number;
  user_id?: string;
  file_path?: string;
  stock_log_details: StockLogDetail[];
  stock_log_status?: Status;
}

export interface StockLogDetail {
  species: string;
  origin: string;
  grade: string;
  top_diameter: number;
  length: number;
  unit_volume: number;
  quantity: number;
}

export type StockLogs = ReadonlyArray<StockLog>;
export type ReadonlyStockLog = Readonly<StockLog>;

interface UpdateStockLog {
  id: number;
  stock_log_details: StockLogDetail[];
}

export function useUpdateStockLog() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (data: StockLog) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/stock_log`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          stock_log_details: data.stock_log_details.map((detail) => ({
            species: detail.species,
            origin: detail.origin,
            grade: detail.grade,
            top_diameter: detail.top_diameter,
            length: detail.length,
            unit_volume: detail.unit_volume,
            quantity: detail.quantity,
          })),
        } satisfies UpdateStockLog),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      toast.success("丸太の在庫を更新しました");
    },
  });
}

export function useGetStockLog(id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: stockLogKeys.stockLog,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/stock_log/${id}`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyStockLog>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
