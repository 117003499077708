import { useParams, useLocation } from "react-router-dom";
import { useGetOrderMessageRoom } from "../../hooks/useOrderMessageRoomHook";
import { useCreateOrderMessage } from "../../hooks/useOrderMessageHook";
import React, { useContext, useState } from "react";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { TabBar } from "../../uis/Bars/Tab";
import { Box } from "@mui/material";
import { MessageTab } from "../common/MessageTab";
import { DetailsTab } from "../common/DetailsTab";
import { UserContext } from "../../contexts/UserContext";

export const OrderMessageRoom: React.FC = () => {
  const user = useContext(UserContext);
  const { order_message_room_id } = useParams<{
    order_message_room_id: string;
  }>();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const isWoodOrder = location.pathname.includes("wood_order_message_room");
  const isLogOrder = location.pathname.includes("log_order_message_room");
  const isBlueprintOrder = location.pathname.includes(
    "blueprint_order_message_room",
  );
  const isFreeOrder = location.pathname.includes("free_order_message_room");

  // typeを判別
  const type = isWoodOrder
    ? "wood"
    : isLogOrder
      ? "log"
      : isBlueprintOrder
        ? "blueprint"
        : isFreeOrder
          ? "free"
          : undefined;

  // メッセージルーム取得
  const { data, error, isPending } = useGetOrderMessageRoom({
    type,
    messageRoomId: Number(order_message_room_id),
  });

  const { mutateAsync, isPending: isMutatePending } = useCreateOrderMessage({
    type,
    messageRoomId: Number(order_message_room_id),
  });

  const tabs = [{ label: "メッセージ" }, { label: "詳細" }] as const;

  // タブ切り替え
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  // ローディング・エラー
  if (isPending) {
    return <Loading />;
  }
  if (error) {
    return <Error message={error.message} />;
  }

  const partner =
    data.order.receiver_id === user?.user_id
      ? data.order.item.orderer
      : data.order.user;

  return (
    <Box sx={{ width: "100%", borderLeft: "1px solid #e0e0e0" }}>
      <TabBar
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      {selectedTab === 0 && (
        <MessageTab
          mutateAsync={mutateAsync}
          isMutatePending={isMutatePending}
          type={type}
          partner={partner}
          isDeliveryNoteButtonShown={data.isDeliveryNoteButtonShown}
          isInvoiceButtonShown={data.isInvoiceButtonShown}
          isPaymentShown={data.isPaymentShown}
          isStripeAccountCompletedShown={data.isStripeAccountCompletedShown}
          isPaymentActionRequired={data.isPaymentActionRequired}
          order={data.order}
          messages={data.messages}
        />
      )}
      {selectedTab === 1 && <DetailsTab data={data} type={type} />}
    </Box>
  );
};
