import { useEffect } from "react";
import {
  StockWood,
  StockWoodDetail,
} from "../../../../../hooks/Wood/useStockWoodHook";
import {
  TableComponent,
  TableComponentExtendedProps,
} from "../../../../../uis/Table";
import { stockWoodColumnsForTable } from "../../../../../utils/columns";
import { defaultStockWoodRow } from "../../../../../utils/rows";

export const StockWoodTable = ({
  register,
  errors,
  disabled,
  control,
  watchedDetails,
  setValue,
}: TableComponentExtendedProps<StockWood, StockWoodDetail>) => {
  useEffect(() => {
    watchedDetails?.forEach((detail, index) => {
      if (!detail) return;
      const width = detail.width ?? 0; // mm
      const height = detail.height ?? 0; // mm
      const length = detail.length ?? 0; // mm

      // 単材積(㎥) = (mm^3) / (1,000,000,000)
      const newUnitVolume = (width * height * length) / 1_000_000_000;
      if (detail.unit_volume !== newUnitVolume) {
        setValue(`stock_wood_details.${index}.unit_volume`, newUnitVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDetails]);

  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="stock_wood_details"
      columns={stockWoodColumnsForTable}
      defaultRow={defaultStockWoodRow}
      disabled={disabled}
      control={control}
    />
  );
};
