import {
  useGetStockWood,
  useUpdateStockWood,
} from "../../../../../hooks/Wood/useStockWoodHook";
import { InputStock } from "../../../../common/InputStock";

export const InputStockWood = () => {
  return (
    <InputStock
      formTitle="木材の在庫管理"
      useGetStock={useGetStockWood}
      useUpdateStock={useUpdateStockWood}
      detailsKey="stock_wood_details"
      itemName="stock_wood"
    />
  );
};
