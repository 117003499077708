import {
  Alert,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { SignInSchema, SignInType } from "../../utils/schema";
import { useForm } from "react-hook-form";
import { validateData } from "../../utils/validation";
import { TextFieldForm } from "../../uis/TextField/TextFieldForm";
import { Color } from "../../utils/color";
import { MobileTopLogo } from "../../uis/svg";

interface AuthProps {
  isSignin?: boolean;
}

export const Auth = ({ isSignin = true }: AuthProps) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInType>();

  const onSubmit = async (data: SignInType) => {
    const validationResponse = validateData(data, SignInSchema);
    if (validationResponse.success) {
      try {
        const result = isSignin
          ? await auth.signIn(data)
          : await auth.signUp(data);
        if (result.success) {
          navigate(isSignin ? "/home" : "/signin");
        } else {
          console.error(result.message);
          setErrorMessage(`${result.message}`);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("ログインに失敗しました");
      }
    } else {
      setErrorMessage(validationResponse.errors[0]);
    }
  };

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "80%", // 残りの80%をメインコンテンツに割り当て
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: 'url("/background.png")', // バックグラウンド画像の設定
        flexDirection: isMobile ? "column" : "row",
        minHeight: "100vh", // 画面全体を覆う
      }}
    >
      {isMobile && (
        <Box sx={{ textAlign: "center", marginBottom: "40px", height: "50%" }}>
          <MobileTopLogo />
          <Typography
            variant="h6"
            sx={{ color: "white", marginTop: "10px", fontSize: "1.5rem" }}
          >
            EFF木材流通システム
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: isMobile ? "100%" : "50%", // 残りの50%をメインコンテンツに割り当て
          backgroundColor: "#F2F2F2",
          padding: isMobile ? 0 : "40px",
          borderRadius: isMobile ? 0 : "8px", // 角を少し丸める
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // 影を追加して浮き上がり感を出す
          // スマホサイズの場合、画面下部に固定
          ...(isMobile && {
            position: "fixed",
            bottom: 0,
            left: 0,
            height: "50%",
          }),
        }}
      >
        {isMobile && (
          <Box
            sx={{
              width: "100%",
              backgroundColor: Color.Main,
              height: "50px",
              borderTopRightRadius: "8px",
              borderTopLeftRadius: "8px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              sx={{
                textAlign: "center",
                color: "white",
                fontSize: "1rem",
              }}
            >
              {isSignin ? "ログイン" : "アカウント登録"}
            </Typography>
          </Box>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: isMobile ? "85%" : "auto",
            margin: "0 auto",
            ...(isMobile && {
              height: "calc(100% - 50px)",
            }),
          }}
        >
          <Stack
            spacing={3}
            sx={{
              width: "100%",
              height: isMobile ? "90%" : "100%",
              ...(isMobile && {
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "column",
              }),
            }}
          >
            {errorMessage && (
              <Alert severity="error" sx={{ color: "red" }}>
                {errorMessage}
              </Alert>
            )}
            {/* メールアドレス */}
            <TextFieldForm
              name="email"
              control={control}
              errors={errors}
              labelName="担当者メールアドレス"
              type="email"
              rules={{
                required: "メールアドレスは必須です",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "正しいメールアドレスの形式で入力してください",
                },
              }}
            />
            {/* パスワード */}
            <TextFieldForm
              name="password"
              control={control}
              errors={errors}
              labelName="パスワード"
              type="password"
              rules={{
                required: "パスワードは必須です",
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上でなければなりません",
                },
              }}
            />
            {/* ログインボタン */}
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{
                backgroundColor: Color.Main,
                color: "#FFFFFF",
                fontSize: "1.2rem",
                height: "60px",
                borderRadius: "30px", // 角を少し丸める
                width: "100%",
                "&:hover": {
                  backgroundColor: "#006f3d", // ホバー時に少し明るく
                },
              }}
            >
              {isSubmitting
                ? isSignin
                  ? "ログイン中..."
                  : "登録中..."
                : isSignin
                  ? "ログイン"
                  : "登録"}
            </Button>
            {/* パスワードリセットリンク */}
            {isSignin && (
              <Box textAlign="center" sx={{ margin: "0" }}>
                <Link
                  to="/password_reset"
                  style={{
                    color: Color.Main, // テキスト色を#004831に設定
                    fontSize: "14px",
                    textDecoration: "none",
                  }}
                >
                  パスワードを忘れた方はこちら
                </Link>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
