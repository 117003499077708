import {
  Box,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
} from "@mui/material";
import { useGetProgressOrderPayments } from "../../../../hooks/useOrderPaymentHook";
import { useSearchAndPaginationHook } from "../../../../hooks/useSearchAndPaginationHook";
import { PaginationComponent } from "../../../../uis/Pagination";
import { Error } from "../../../../uis/Error";
import { SearchBox } from "../../../../uis/SearchBox/SearchBox";
import { useContext, useState } from "react";
import { UserContext } from "../../../../contexts/UserContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Payment = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const user = useContext(UserContext);

  const { page, searchText, handlePageChange, handleSearchChange } =
    useSearchAndPaginationHook();

  const { data, error } = useGetProgressOrderPayments({
    searchText,
    page,
  });

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };

  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box sx={{ width: "95%", margin: "0 auto" }}>
      {/* 検索ボックスはスクロール外に固定 */}
      <SearchBox
        placeholder="会社・プロジェクトを検索"
        isMobile
        onClick={handleSearchBoxClick}
        onChange={handleSearchChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          overflowY: "auto", // 縦方向にスクロール
          width: "100%",
          mt: 2,
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {data && data?.orderPayments.length > 0 ? (
            data?.orderPayments.map((it) => (
              <Accordion key={`${it.orderPayment.id}-${it.type}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    {it.orderPayment.order.item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper variant="outlined" sx={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>金額 (円)</TableCell>
                          <TableCell>アイテムタイプ</TableCell>
                          <TableCell>タイトル</TableCell>
                          <TableCell>会社名</TableCell>
                          <TableCell>支払い側かどうか</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {it.orderPayment.amount.toLocaleString()}
                          </TableCell>
                          <TableCell>{it.type}</TableCell>
                          <TableCell>
                            {it.orderPayment.order.item.title}
                          </TableCell>
                          <TableCell>
                            {it.orderPayment.order.user.user_id ===
                            user?.user_id
                              ? it.orderPayment.order.user.company_name
                              : it.orderPayment.order.item.orderer.company_name}
                          </TableCell>
                          <TableCell>
                            {it.isPaymentSide ? "支払い側" : "請求側"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mt: 2, textAlign: "center" }}
            >
              支払い/請求中のデータが存在しません
            </Typography>
          )}
        </List>
      </Box>
      {/* ページネーションもリストの外に配置して常に表示可能にする */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isMobile={isMobile}
        />
      )}
    </Box>
  );
};
