import {
  useGetStockLog,
  useUpdateStockLog,
} from "../../../../../hooks/Log/useStockLogHook";
import { InputStock } from "../../../../common/InputStock";

export const InputStockLog = () => {
  return (
    <InputStock
      formTitle="丸太の在庫管理"
      useGetStock={useGetStockLog}
      useUpdateStock={useUpdateStockLog}
      detailsKey="stock_log_details"
      itemName="stock_log"
    />
  );
};
