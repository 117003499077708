import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { Loading } from "../uis/Loading";
import { useEffect } from "react";
import { useGetUser, User } from "../hooks/useUserHook";
import { Industries } from "../utils/industry";
import { UserContext } from "../contexts/UserContext";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredIndustries?: Industries;
  requiresAdmin?: boolean;
  redirectTo?: string;
  industryErrorMessage?: string;
  isProfile?: boolean;
}

const isProfileIncomplete = (user: User): boolean => {
  return (
    user.industry === null ||
    (typeof user.industry !== "number" && !user.industry) ||
    !user.phone_number ||
    !user.postal_code ||
    !user.address ||
    !user.company_name
  );
};

export const ProtectedRoute = ({
  children,
  requiredIndustries,
  requiresAdmin = false,
  redirectTo = "/home",
  industryErrorMessage,
  isProfile = false,
}: ProtectedRouteProps) => {
  const { error: authError, isLoading } = useAuth();
  const { data: user, error: userError, isPending } = useGetUser();

  useEffect(() => {
    if (authError || userError) {
      toast.error(authError?.message || userError?.message);
    }
    if (!isProfile && user && isProfileIncomplete(user)) {
      toast.error("プロフィールを登録してください。");
    }
    if (user && requiresAdmin && !user.is_admin) {
      toast.error("管理者権限が存在しません");
    }
    if (
      user &&
      requiredIndustries &&
      user.industry &&
      !requiredIndustries.includes(user.industry)
    ) {
      toast.error(industryErrorMessage || "権限がありません");
    }
  }, [
    authError,
    userError,
    user,
    industryErrorMessage,
    requiresAdmin,
    requiredIndustries,
    isProfile,
  ]);

  if (isLoading || isPending) {
    return <Loading />;
  }

  if (authError || !user || userError) {
    return <Navigate to="/signin" />;
  }

  if (!isProfile && isProfileIncomplete(user)) {
    return <Navigate to="/profile" />;
  }

  if (requiresAdmin && !user.is_admin) {
    return <Navigate to={redirectTo} />;
  }

  if (
    requiredIndustries &&
    user.industry &&
    !requiredIndustries.includes(user.industry)
  ) {
    return <Navigate to={redirectTo} />;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
