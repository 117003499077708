import { Avatar as MuiAvatar } from "@mui/material";
import { useGetImageFromS3 } from "../hooks/S3/useS3ImageHook";
import { Loading } from "./Loading";
import { User } from "../hooks/useUserHook";
import { Error } from "./Error";

const Avatar = ({ user, isMobile }: { user?: User; isMobile: boolean }) => {
  const { data, error, isLoading } = useGetImageFromS3(user?.company_image);
  if (isLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <MuiAvatar
      alt={user?.company_name}
      src={data || ""}
      sx={{
        width: isMobile ? 40 : 50,
        height: isMobile ? 40 : 50,
        marginRight: isMobile ? 1 : 2,
      }}
    />
  );
};

export default Avatar;
