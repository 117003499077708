import {
  TableComponent,
  TableComponentExtendedProps,
} from "../../../../uis/Table";
import { Free, FreeDetail } from "../../../../hooks/Free/type";
import { defaultFreeRow } from "../../../../utils/rows";
import { freeColumnsForTable } from "../../../../utils/columns";
import { OmitStrict } from "../../../../utils/types/general_type";
import { UserContext } from "../../../../contexts/UserContext";
import { useContext } from "react";
import { Industry } from "../../../../utils/industry";
import { useWatch } from "react-hook-form";

export const FreeTable = ({
  register,
  errors,
  control,
  watchedTotalAmount,
}: OmitStrict<TableComponentExtendedProps<Free, FreeDetail>, "setValue">) => {
  const user = useContext(UserContext);
  const watchedDetailsQuantity = useWatch({
    control,
    name: `free_details.0.quantity`,
  });
  const watchedDetailsCubicMeter = useWatch({
    control,
    name: `free_details.0.cubic_meter`,
  });
  const filteredFreeColumnsForTable = freeColumnsForTable.filter((col) => {
    if (Boolean(watchedTotalAmount) || user?.industry !== Industry.Market) {
      if (watchedDetailsQuantity > 0) {
        return col.field !== "amount" && col.field !== "cubic_meter";
      }
      if (watchedDetailsCubicMeter > 0) {
        return col.field !== "amount" && col.field !== "quantity";
      }
      // 合計値を入力すると、異常発生
      return col.field !== "amount";
    }
    if (watchedDetailsQuantity > 0) {
      return col.field !== "cubic_meter";
    }
    if (watchedDetailsCubicMeter > 0) {
      return col.field !== "quantity";
    }
    return true;
  });
  const filteredDefaultFreeRow = {
    ...defaultFreeRow,
    amount:
      Boolean(watchedTotalAmount) || user?.industry !== Industry.Market
        ? 0
        : defaultFreeRow.amount,
  };
  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="free_details"
      columns={filteredFreeColumnsForTable}
      defaultRow={filteredDefaultFreeRow}
      control={control}
    />
  );
};
