import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetOrderPaymentsByRange } from "../../../../hooks/useOrderPaymentHook";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { SearchTransaction } from "../../../../utils/schema";
import { useLocation } from "react-router-dom";
import { SearchTransactionBox } from "../../../../uis/SearchBox/SearchTransactionBox";

export const TransactionAmount: React.FC = () => {
  const location = useLocation();

  // 現在の年月を取得
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // 1-12 を 01-12 に変換

  // デフォルトの開始月と終了月を設定（今年の1月から現在の月まで）
  const defaultStartDate = `${currentYear}-01`;
  const defaultEndDate = `${currentYear}-${currentMonth}`;

  // URLのクエリパラメータを解析
  const getQueryParams = (): SearchTransaction => {
    const params = new URLSearchParams(location.search);
    const startDate = params.get("startDate") || defaultStartDate;
    const endDate = params.get("endDate") || defaultEndDate;
    const type = (params.get("type") as "order" | "receive" | "both") || "both";

    return {
      startDate,
      endDate,
      type,
    };
  };

  // 検索パラメータのステート（初期値を設定）
  const [searchParams, setSearchParams] =
    useState<SearchTransaction>(getQueryParams());

  const { data, error, isPending } = useGetOrderPaymentsByRange(searchParams);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }
  return (
    <Box p={4} sx={{ width: "95%" }}>
      <Typography variant="h4" gutterBottom>
        取引金額
      </Typography>

      {/* フィルタリングフォーム */}
      <SearchTransactionBox
        setSearchParams={setSearchParams}
        getQueryParams={getQueryParams}
      />
      {data && (
        <>
          {data.length === 0 ? (
            <Typography variant="body1">
              該当するデータがありません。
            </Typography>
          ) : (
            data.map((monthlyPayment) => (
              <Accordion key={`${monthlyPayment.year}-${monthlyPayment.month}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    {monthlyPayment.year}年 {monthlyPayment.month}月
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper variant="outlined" sx={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>金額 (円)</TableCell>
                          <TableCell>アイテムタイプ</TableCell>
                          <TableCell>タイトル</TableCell>
                          <TableCell>会社名</TableCell>
                          <TableCell>作成日</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {monthlyPayment.payments.map((payment) => (
                          <TableRow key={payment.orderId}>
                            <TableCell>
                              {payment.amount.toLocaleString()}
                            </TableCell>
                            <TableCell>{payment.itemType}</TableCell>
                            <TableCell>{payment.title}</TableCell>
                            <TableCell>
                              {payment.partner.company_name}
                            </TableCell>
                            <TableCell>
                              {new Date(payment.created_at).toLocaleDateString(
                                "ja-JP",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                },
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </>
      )}
    </Box>
  );
};
