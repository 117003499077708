import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { PrivateRoute } from "../PrivateRoute";
import Sidebar from "../../uis/Bars/Sidebar";
import { Header } from "../../uis/Bars/Header";
import BottomBar from "../../uis/Bars/BottomBar";

export const HomeLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  // 画面幅が sm 以下かどうかを判定
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PrivateRoute>
      {/* スマホの場合は縦スクロールにし、BottomBarを下に配置できるよう flexDirectionをcolumnに */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        {/* PC画面ならSidebar、スマホ画面なら表示しない */}
        {!isMobile && <Sidebar isLoggedIn={true} />}
        {/* コンテンツ部分 */}
        <Box
          sx={{
            flexGrow: 1,
            width: isMobile ? "100%" : "80%",
            height: "100%",
            overflowY: "auto", // ここで縦スクロールを有効にする
            // PC版でSidebarを固定幅にしている場合、幅を合わせるなら下記のように調整してもよい
            // minHeightを取ることで、フッターのようにBottomBarを画面下に固定
          }}
        >
          <Header
            title={location.pathname.includes("order") ? "注文" : "ホーム"}
          />
          <Outlet />
        </Box>
        {/* スマホ画面ならBottomBarを表示 */}
        {isMobile && <BottomBar isLoggedIn={true} />}
      </Box>
    </PrivateRoute>
  );
};
