import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from "@mui/icons-material/Chat";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  DataLogo,
  LoginLogo,
  LogoutLogo,
  ManagementLogo,
  OrderLogo,
  SignupLogo,
} from "../svg";
import {
  DATA_LOG_MARKET_PRICE_PATH,
  MANAGEMENT_STOCK_PATH,
} from "../../utils/constant";

/** Sidebar/BottomBar で利用するメニューアイテムの型 */
export interface BarItem {
  /** アイコンなどReact要素 */
  children: React.ReactNode;
  /** ボタンラベル */
  label: string;
  /** 遷移先パス */
  link: string;
}

type BarItems = ReadonlyArray<BarItem>;

/** ログイン前用メニュー */
export const menubarLoginItems = [
  {
    children: <LoginLogo />,
    label: "ログイン",
    link: "/signin",
  },
  {
    children: <SignupLogo />,
    label: "はじめての方",
    link: "/signup",
  },
] as const satisfies BarItems;

/** ログイン後用メニュー */
export const menubarAuthenticatedItems = [
  {
    children: <HomeIcon />,
    label: "ホーム",
    link: "/home",
  },
  {
    children: <OrderLogo />,
    label: "注文",
    link: "/order",
  },
  {
    children: <ChatIcon />,
    label: "トーク",
    link: "/talk/message_rooms",
  },
  {
    children: <ManagementLogo />,
    label: "管理",
    link: MANAGEMENT_STOCK_PATH,
  },
  {
    children: <DataLogo />,
    label: "データ",
    link: DATA_LOG_MARKET_PRICE_PATH,
  },
  {
    children: <AccountCircleIcon sx={{ color: "white" }} />,
    label: "マイページ",
    link: "/profile",
  },
  {
    children: <LogoutLogo />,
    label: "ログアウト",
    link: "/signout",
  },
] as const satisfies BarItems;
