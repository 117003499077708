import { useNavigate } from "react-router-dom";
import { useContext, useRef } from "react";
import { MenuItemComponent } from "../../uis/Item/MenuItem";
import { useCreateStock } from "../../hooks/useStockHook";
import { MANAGEMENT_STOCK_PATH } from "../../utils/constant";
import { UserContext } from "../../contexts/UserContext";
import { Industry } from "../../utils/industry";

export const Stock = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const submitProcessing = useRef(false);
  const { mutateAsync } = useCreateStock();

  const menuItems = [
    {
      title: "在庫",
      items: [
        { text: "在庫管理", link: `${MANAGEMENT_STOCK_PATH}/input_stock_log` },
        { text: "在庫管理", link: `${MANAGEMENT_STOCK_PATH}/input_stock_wood` },
      ],
    },
  ] as const;

  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    if (submitProcessing.current) return;

    switch (link) {
      case `${MANAGEMENT_STOCK_PATH}/input_stock_wood`:
      case `${MANAGEMENT_STOCK_PATH}/input_stock_log`:
        try {
          const res = await mutateAsync();
          navigate(`${link}/${res.id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false;
        }
        break;
      default:
        navigate(link);
    }
  };

  // --------------------------------------------------
  // 業種に応じた「除外するリンク」を定義するマッピング
  // --------------------------------------------------
  const excludedLinkMapping: Record<Industry, readonly MenuLink[]> = {
    [Industry.Forestry]: [`${MANAGEMENT_STOCK_PATH}/input_stock_wood`],
    [Industry.Market]: [`${MANAGEMENT_STOCK_PATH}/input_stock_wood`],
    [Industry.Sawmill]: [`${MANAGEMENT_STOCK_PATH}/input_stock_log`],
    [Industry.Precut]: [`${MANAGEMENT_STOCK_PATH}/input_stock_log`],
    [Industry.SawmillPrecut]: [`${MANAGEMENT_STOCK_PATH}/input_stock_log`],
    [Industry.Builder]: [
      `${MANAGEMENT_STOCK_PATH}/input_stock_wood`,
      `${MANAGEMENT_STOCK_PATH}/input_stock_log`,
    ],
    [Industry.Manufacturer]: [
      `${MANAGEMENT_STOCK_PATH}/input_stock_wood`,
      `${MANAGEMENT_STOCK_PATH}/input_stock_log`,
    ],
    [Industry.Viewer]: [
      `${MANAGEMENT_STOCK_PATH}/input_stock_wood`,
      `${MANAGEMENT_STOCK_PATH}/input_stock_log`,
    ],
    [Industry.Administrator]: [
      `${MANAGEMENT_STOCK_PATH}/input_stock_wood`,
      `${MANAGEMENT_STOCK_PATH}/input_stock_log`,
    ],
  };

  // --------------------------------------------------
  // ユーザーの業種に応じたフィルタリング処理
  // --------------------------------------------------
  const filteredMenuItems =
    user && user.industry !== undefined
      ? menuItems.map((group) => {
          // ユーザー業種に対応する除外リンク一覧を型注釈付きで取得
          const excludes = excludedLinkMapping[user.industry!] ?? [];
          return {
            ...group,
            // 除外リンクに含まれないアイテムのみ抽出
            items: group.items.filter((item) => !excludes.includes(item.link)),
          };
        })
      : [];

  return (
    <MenuItemComponent
      menuItems={filteredMenuItems}
      handleClick={handleClick}
      disabled={submitProcessing.current}
    />
  );
};
