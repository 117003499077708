import { ProtectedRoute } from "./ProtectedRoute";
import { Industry } from "../utils/industry";

export const MarketRoute = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProtectedRoute
      requiredIndustries={[Industry.Market]}
      industryErrorMessage="権限が存在しません。市場のユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
