import { useGetMyNotifications } from "../../hooks/useNotificationHook";
import { NotificationList } from "../common/NotificationList";

export const TemporarilyStoredNotifications = () => {
  return (
    <NotificationList
      useGetNotifications={useGetMyNotifications}
      isHome={false}
      isTemporarilyStored={true}
      isPublished={false}
    />
  );
};
