import React from "react";
import { CommonSplitLayout } from "./common/CommonSplitLayout";
import { LogReceiverRoute } from "../LogReceiverRoute";
import { Stock } from "../../pages/Home/Stock";
import { MANAGEMENT_STOCK_PATH, ORDER_BASE_PATH } from "../../utils/constant";
import { Order } from "../../pages/Home/Order";

const leftPanelByPath = (path: string) => {
  if (path.includes(MANAGEMENT_STOCK_PATH)) {
    return <Stock />;
  } else if (path.includes(ORDER_BASE_PATH)) {
    return <Order />;
  }
  return null;
};

export const LogReceiverLayout: React.FC = () => {
  return (
    <CommonSplitLayout
      routeWrapper={LogReceiverRoute}
      headerTitle="丸太"
      leftPanel={leftPanelByPath}
    />
  );
};
