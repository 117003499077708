import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useDownloadFromS3 } from "../../hooks/S3/useSignedURLHook";
import { toast } from "react-toastify";
import { ItemType } from "../../utils/types/item_type";
import { getCookie } from "../../utils/cookie";
import { useState } from "react";
import { AdminPasswordModal } from "../Modals/AdminPasswordModal";
import { useParams } from "react-router-dom";
import { useUpdateDeliveryNoteLegalityVerifiedChecked } from "../../hooks/useDocumentHook";

interface FileReference {
  id?: number;
  itemType?: ItemType;
  isLegalityVerifiedChecked?: boolean;
}
export type AcceptedCategories =
  | "quotation"
  | "order_form"
  | "delivery_note"
  | "invoice";

interface FileDownloadProps {
  file: FileReference;
  category: AcceptedCategories;
  isLegalityVerifiedShown?: boolean;
}

const categoryLabels: Record<AcceptedCategories, string> = {
  quotation: "見積書",
  order_form: "注文書",
  delivery_note: "納品書",
  invoice: "請求書",
};

type FileType = "xlsx" | "pdf";

export const FileDownload: React.FC<FileDownloadProps> = ({
  file,
  category,
  isLegalityVerifiedShown = false,
}) => {
  const { mutateAsync, isPending } = useDownloadFromS3();
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [fileType, setFileType] = useState<FileType | null>(null);
  const { order_message_room_id } = useParams<{
    order_message_room_id: string;
  }>();
  const { mutateAsync: mutateUpdateAsync } =
    useUpdateDeliveryNoteLegalityVerifiedChecked();

  const downloadFile = async (filetype: FileType | null) => {
    try {
      if (!file.id || !file.itemType || !filetype) {
        toast.error("ファイルが存在しません");
        return;
      }
      const res = await mutateAsync({
        filetype,
        id: file.id,
        category: `${file.itemType}_${category}`,
      });
      const link = document.createElement("a");
      link.href = res;
      link.download = `${file.itemType}_${category}.${filetype}`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadClick = async (filetype: FileType) => {
    const adminSessionId = getCookie("admin_session_id");
    if (!adminSessionId) {
      setOpenAdminModal(true);
      setFileType(filetype);
      return;
    }
    await downloadFile(filetype);
  };

  const handleAdminAuthSuccess = async () => {
    setOpenAdminModal(false);
    await downloadFile(fileType);
  };

  const handleAdminAuthClose = () => {
    setOpenAdminModal(false);
  };

  const handleCheckboxChange = async () => {
    if (!file.id || (file.itemType !== "log" && file.itemType !== "free")) {
      toast.error("ファイルが間違っています");
      return;
    }
    try {
      await mutateUpdateAsync({
        id: file.id,
        itemName: file.itemType,
        messageRoomId: Number(order_message_room_id),
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 2, // 下方向のマージン
        p: 2, // 内側のパディング
        border: "1px solid #e0e0e0", // 境界線
        background: "#f7f7f7", // 背景色を少し変更
        borderRadius: 2, // 角丸
        boxShadow: "0 1px 3px rgba(0,0,0,0.1)", // 軽い影を追加して立体感を出す
        minWidth: 280, // 最小幅を設定して狭い画面でもある程度表示を保つ
      }}
    >
      {/* カテゴリーラベル */}
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontSize: "1rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {categoryLabels[category]} をダウンロード
      </Typography>
      {/* ダウンロードボタンエリア */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 1.5 }}>
        {/* Excelボタン */}
        <Button
          variant="outlined"
          onClick={() => handleDownloadClick("xlsx")}
          disabled={isPending} // 処理中は無効化
          sx={{
            flexGrow: 1, // ボタンがスペースを均等に分け合うように
            backgroundColor: "#ffffff",
            color: "#333", // 文字色を少し濃く
            borderColor: "#bdbdbd",
            textTransform: "none", // ボタンテキストが大文字にならないように
            "&:hover": {
              backgroundColor: "#f5f5f5",
              borderColor: "#757575",
            },
            // 無効化時のスタイルを明示的に定義
            "&.Mui-disabled": {
              backgroundColor: "#eeeeee",
              borderColor: "#e0e0e0",
              color: "#bdbdbd",
            },
          }}
        >
          Excel
        </Button>
        {/* PDFボタン */}
        <Button
          variant="outlined"
          onClick={() => handleDownloadClick("pdf")}
          disabled={isPending} // 処理中は無効化
          sx={{
            flexGrow: 1,
            backgroundColor: "#ffffff",
            color: "#333",
            borderColor: "#bdbdbd",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              borderColor: "#757575",
            },
            "&.Mui-disabled": {
              backgroundColor: "#eeeeee",
              borderColor: "#e0e0e0",
              color: "#bdbdbd",
            },
          }}
        >
          PDF
        </Button>
      </Box>
      {/* 合法性確認のチェックボックスエリア */}
      {isLegalityVerifiedShown && (
        <FormControlLabel
          control={
            <Checkbox
              checked={file.isLegalityVerifiedChecked} // 親から渡された状態で表示
              onChange={handleCheckboxChange} // 変更時にコールバックを実行
              size="small" // チェックボックスのサイズ
              color="primary" // チェック時の色 (デフォルト or success なども可)
              sx={{ py: 0, pr: 0.5 }} // チェックボックス自体のパディング調整
            />
          }
          label={
            // ラベルテキスト部分
            <Typography
              variant="body2"
              sx={{
                color: file.isLegalityVerifiedChecked
                  ? "text.primary"
                  : "text.secondary",
                fontSize: "0.8rem", // ラベルのフォントサイズ
              }}
            >
              {file.isLegalityVerifiedChecked
                ? "合法性の確認にチェックしました。"
                : "合法性の確認にチェックする"}
            </Typography>
          }
          sx={{
            mt: 1, // 上マージン
            mx: "auto", // 全体を中央寄せ
            display: "flex", // 中央寄せを有効化
            width: "fit-content", // コンテンツ幅に合わせる
            // 必要に応じて微調整
            // mr: 0, // 右マージンをリセットする場合
          }}
        />
      )}
      {/* 管理者パスワード認証モーダル */}
      {openAdminModal && (
        <AdminPasswordModal
          open={openAdminModal}
          onSuccessClose={handleAdminAuthSuccess} // 成功時のコールバック
          onErrorClose={handleAdminAuthClose} // 失敗・キャンセル時のコールバック
        />
      )}
    </Box>
  );
};
