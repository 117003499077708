import { useState } from "react";
import { toast } from "react-toastify";
import { useUploadFileById } from "../S3/useSignedURLHook";

interface UseFileUploadOptions {
  onUploadSuccess?: () => void;
  acceptedTypes?: string[]; // 例: ['csv', 'xlsx']
  maxFileSize?: number; // バイト単位
  id: number;
  filePath?: string;
}

export const useFileUpload = ({
  onUploadSuccess,
  acceptedTypes = ["csv", "xlsx"], // デフォルト
  maxFileSize = 100 * 1024 * 1024, // デフォルト 100MB
  filePath,
  id,
}: UseFileUploadOptions) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // ファイルアップロード処理
  const { mutateAsync, isPending } = useUploadFileById();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      setSelectedFile(null);
      return;
    }

    // ファイルタイプの検証
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!fileExtension || !acceptedTypes.includes(fileExtension)) {
      toast.error(
        `許可されていないファイル形式です。(${acceptedTypes.join(", ")})`,
      );
      setSelectedFile(null);
      event.target.value = ""; // input の値をリセット
      return;
    }

    // ファイルサイズの検証
    if (file.size > maxFileSize) {
      toast.error(
        `ファイルサイズが大きすぎます。(最大: ${maxFileSize / 1024 / 1024}MB)`,
      );
      setSelectedFile(null);
      event.target.value = ""; // input の値をリセット
      return;
    }

    setSelectedFile(file);
  };

  const handleFileUploadClick = async () => {
    if (!selectedFile || !filePath) {
      toast.error("アップロード対象のファイルが選択されていません。");
      return;
    }
    try {
      await mutateAsync({
        id,
        file: selectedFile,
        path: filePath,
      });
      setSelectedFile(null); // アップロード成功時に選択をクリア
      clearSelectedFile();
      onUploadSuccess?.();
    } catch (error) {
      console.error(error);
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  return {
    selectedFile,
    handleFileChange,
    isPending,
    clearSelectedFile,
    handleFileUploadClick,
  };
};
