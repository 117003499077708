import { OmitStrict } from "../../utils/types/general_type";
import { FileUpload, FileUploadProps } from "./FileUpload";
import { Typography } from "@mui/material";

type OmitFileUploadProps = OmitStrict<
  FileUploadProps,
  "accept" | "heicBlob" | "message"
>;
interface ExcelFileUploadProps extends OmitFileUploadProps {
  isPolling: boolean;
}

const ExcelFileUpload = ({
  selectedFile,
  filePath,
  handleFileChange,
  isPolling,
  disabled = false,
}: ExcelFileUploadProps) => {
  return (
    <>
      {isPolling ? (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ my: 1, textAlign: "center" }}
        >
          ファイル処理中...完了までお待ちください。
        </Typography>
      ) : (
        <FileUpload
          selectedFile={selectedFile}
          filePath={filePath}
          handleFileChange={handleFileChange}
          accept={["csv", "xlsx"]}
          message="CSV/Excelファイルを取りこむ"
          disabled={disabled}
        />
      )}
    </>
  );
};

export default ExcelFileUpload;
