import { Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { Buttons } from "../../../../uis/Button/Button";
import { useForm, useWatch } from "react-hook-form";
import {
  useGetBlueprintOrderByReceiver,
  useUpdateBlueprintOrderByReceiver,
} from "../../../../hooks/Blueprint/useBlueprintOrderHook";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { BlueprintTable } from "./blueprint_table";
import { ItemSearchBox } from "../../../../uis/SearchBox/ItemSearchBox";
import { useSearchItems } from "../../../../hooks/useItemHook";
import { TemplateExcelDownload } from "../../../../uis/File/TemplateExcelDownload";
import { ORDER_BASE_PATH } from "../../../../utils/constant";
import { BaseOrder } from "../../../../utils/types/base_type";
import { MenuComponent } from "../../../../uis/Menu";
import { TotalAmountField } from "../../../../uis/TextField/TotalAmountField";
import { TotalAmount } from "../../../../uis/TotalAmount";
import { CommonBlueprintImageForm } from "../../../common/Blueprint/BlueprintImageForm";
import { useFilePolling } from "../../../../hooks/File/useFilePolling"; // カスタムフック
import { useFileUpload } from "../../../../hooks/File/useFileUpload"; // カスタムフック
import ExcelFileUpload from "../../../../uis/File/ExcelFileUpload";

export const CreateBlueprintDetailList = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, error, isPending, refetch } = useGetBlueprintOrderByReceiver(
    Number(id),
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<BaseOrder>();

  const {
    selectedFile,
    handleFileChange,
    clearSelectedFile,
    handleFileUploadClick,
    isPending: isUploadMutatePending,
  } = useFileUpload({
    id: Number(id),
    filePath: `excel_csv/blueprint_order`,
    onUploadSuccess: () => {
      startPolling(); // アップロード成功でポーリング開始
    },
  });

  // ファイルステータスのポーリング処理
  const { isPolling, startPolling } = useFilePolling({
    refetch,
  });

  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (data) {
      reset(data);
      const status = data.file_status;
      if (status?.status === "pending" && !isPolling) {
        startPolling();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // --- フォーム値の監視 ---
  const watchedBlueprintDetails = useWatch({
    control,
    name: "blueprint_details",
  });
  const watchedTotalOrderAmount = useWatch({
    control,
    name: "total_order_amount",
  });

  // --- API Mutation ---
  const { mutateAsync: updateBlueprintOrderMutate } =
    useUpdateBlueprintOrderByReceiver();

  // --- レンダリング ---
  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }
  if (!data || !id) return <Error message="データが見つかりません。" />; // IDやデータがない場合

  const onSubmit = async (formData: BaseOrder) => {
    try {
      await updateBlueprintOrderMutate({ ...formData, id: Number(id) });
      clearSelectedFile();
    } catch (error) {
      console.error(error);
    }
  };

  // 全詳細が空でないかどうかのフラグ
  const areAllDetailsComplete =
    Array.isArray(watchedBlueprintDetails) && // 配列であるか
    watchedBlueprintDetails.length > 0 && // 配列が空でないか
    watchedBlueprintDetails.every(
      (
        detail, // 配列の全ての要素が条件を満たすか
      ) =>
        // detailがnull/undefinedの場合も考慮し、オブジェクトの値を取得
        Object.values(detail ?? {}).some(
          // オブジェクトの値のいずれかが条件を満たすか
          (value) =>
            // 値が「未入力」ではないか (空文字、0、null、undefined以外)
            value !== "" &&
            value !== 0 &&
            value !== null &&
            value !== undefined,
        ),
    );

  // 合計金額が入力されているかどうかのフラグ
  // watchedTotalOrderAmount が 0 や '' でも入力されたとみなすか要件による
  const hasTotalAmount =
    watchedTotalOrderAmount !== null && // nullでないか
    watchedTotalOrderAmount !== undefined && // undefinedでないか
    String(watchedTotalOrderAmount) !== ""; // 文字列にして空文字でないか (これにより 0 は入力済みとみなされる)

  // --- ボタンの有効/無効判定 ---
  // 全明細が空でないか、または合計金額が入力されているか
  const isUpdateEnabled = areAllDetailsComplete || hasTotalAmount;

  const isProcessing = isSubmitting || isPolling;

  return (
    <Box
      sx={{
        width: "95%", // sx プロパティは必要に応じて残す or styled-components などに移行
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* 一時保存リスト検索 */}
      <ItemSearchBox
        placeholder="一時保存したリスト一覧"
        navigateTo={(itemId) =>
          navigate(`${ORDER_BASE_PATH}/create_blueprint_list/${itemId}`)
        }
        useSearchItems={useSearchItems}
        id={Number(id)}
        itemName="blueprint"
      />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <Box sx={{ margin: "1rem auto" }}>
          <TemplateExcelDownload category="blueprint" />
        </Box>

        {/* ポーリング中の表示 */}
        <ExcelFileUpload
          selectedFile={selectedFile}
          filePath={data?.file_path}
          handleFileChange={handleFileChange}
          isPolling={isPolling}
        />
        {/* タイトル (表示のみ) */}
        <TextField
          label="タイトル"
          variant="outlined"
          fullWidth
          value={data?.item?.title || ""} // オプショナルチェーンとデフォルト値
          sx={{ marginBottom: 3, borderRadius: "10px" }}
          disabled={true}
        />
        {/* コメント (表示のみ) */}
        <TextField
          label="コメント"
          variant="outlined"
          fullWidth
          multiline
          value={data?.item?.comment || ""}
          rows={3}
          sx={{ marginBottom: 3 }}
          disabled={true}
        />

        {/* 青図明細テーブル */}
        <BlueprintTable
          register={register}
          errors={errors}
          watchedDetails={watchedBlueprintDetails}
          setValue={setValue} // Context API 検討
          control={control}
          watchedTotalAmount={watchedTotalOrderAmount} // 必要なら渡す
          disabled={isProcessing}
        />

        {/* 合計金額表示 */}
        {data && (
          <TotalAmount
            item={data.item} // data.item が null でないことを確認
            order={data}
            isTotalItemAmountShown={false}
          />
        )}
        {/* 合計金額入力 */}
        <TotalAmountField
          register={register}
          errors={errors}
          totalAmountField="total_order_amount"
        />
        {/* 青図画像 (表示のみ) */}
        <CommonBlueprintImageForm
          control={control}
          errors={errors}
          fieldNamePrefix="item.blueprint_images" // ネストしたフィールド名
          blueprintId={Number(id)}
          disabled={true} // 常時無効
        />
        {/* メニューコンポーネント */}
        {data && (data.total_order_and_detail_amount ?? 0) >= 50 && (
          <MenuComponent
            itemType="blueprint"
            status={data.status}
            userId={data.item.orderer.user_id}
            itemId={data.item.id}
          />
        )}
        {/* 操作ボタン */}
        <Buttons
          isMobile
          whiteButtonText="CSV/Excelファイルをアップロードする"
          whiteButtonClick={handleFileUploadClick}
          whiteButtonDisabled={!selectedFile || isUploadMutatePending} // ボタンの有効/無効を制御
          blackButtonText="更新する"
          type="submit"
          blackButtonDisabled={!isUpdateEnabled || isProcessing} // ボタンの有効/無効を制御
        />
      </Box>
    </Box>
  );
};
