import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../../../uis/Bars/Sidebar";
import { Header } from "../../../uis/Bars/Header";
import BottomBar from "../../../uis/Bars/BottomBar";

/**
 * RouteWrapper は SawmillPrecutRoute や PrivateRoute など、
 * 「認可（Guard）やロジックを実装したRoute」コンポーネントを
 * ラップとして受け取るための型です。
 *
 * 下記のようなイメージ:
 *
 * const SawmillPrecutRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
 *   // ロジック等
 *   return <>{children}</>;
 * };
 */
type RouteWrapperComponent = React.FC<{ children: React.ReactNode }>;

/**
 * leftPanel として受け取る型。
 *  - 固定要素なら React.ReactNode
 *  - パスで切り替えたい場合は location.pathname を受け取って JSX を返す関数
 */
type LeftPanelType = React.ReactNode | ((path: string) => React.ReactNode);

/**
 * headerTitle も同様に、固定文字列 or location.pathname を使った動的文字列に対応。
 */
type HeaderTitleType = string | ((path: string) => string);

interface CommonSplitLayoutProps {
  /** SawmillPrecutRoute, PrivateRoute 等を渡す */
  routeWrapper: RouteWrapperComponent;

  /**
   * ヘッダーに表示するタイトル。
   * - 固定文字列
   * - または (path: string) => string の関数
   */
  headerTitle?: HeaderTitleType;

  /**
   * 左側 30% に表示するコンポーネント。
   * - 固定のコンポーネント
   * - または (path: string) => JSX の関数
   */
  leftPanel?: LeftPanelType;

  /**
   * サイドバーを表示するかどうか。省略時 true とする
   *  例: isShowSidebar={false} にするとサイドバー非表示
   */
  isShowSidebar?: boolean;
}

/**
 * **共通レイアウトコンポーネント**。
 *
 * - 左にはサイドバー（モバイル時は非表示）
 * - 右はヘッダー＋(左: 30%/右: 70%)
 * - Outlet には各ルーティング先のコンテンツが入る
 * - height: 100vh を基本とし、スクロールを親要素で管理
 */
export const CommonSplitLayout: React.FC<CommonSplitLayoutProps> = (props) => {
  const {
    routeWrapper: RouteWrapper,
    headerTitle,
    leftPanel,
    isShowSidebar = true,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const path = location.pathname;

  // タイトルが string か関数かで分岐
  const resolvedHeaderTitle =
    typeof headerTitle === "function" ? headerTitle(path) : headerTitle;

  // 左カラムが static か関数かで分岐
  const resolvedLeftPanel =
    typeof leftPanel === "function" ? leftPanel(path) : leftPanel;

  return (
    <RouteWrapper>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体高さ
        }}
      >
        {/* isShowSidebar=true && モバイルでない場合にサイドバー表示 */}
        {isShowSidebar && !isMobile && <Sidebar isLoggedIn={true} />}

        {/* 右側: ヘッダー + 2カラム */}
        <Box
          sx={{
            width: isMobile ? "100%" : "80%",
          }}
        >
          {/* ヘッダー */}
          <Header title={resolvedHeaderTitle || ""} />

          {/* 2カラム */}
          <Box sx={{ display: "flex", width: "100%", height: "90vh" }}>
            {!isMobile && (
              <Box
                sx={{
                  // 横幅が常に全体の残りを使うようにしつつ、独自にスクロールさせる
                  height: "100%",
                  overflowY: "auto", // ここで縦スクロールを有効にする
                  width: "30%",
                }}
              >
                {resolvedLeftPanel}
              </Box>
            )}
            <Box
              sx={{
                width: isMobile ? "100%" : "70%",
                flex: 1,
                // 横幅が常に全体の残りを使うようにしつつ、独自にスクロールさせる
                height: "100%",
                overflowY: "auto", // ここで縦スクロールを有効にする
                // ↓ 以下はカスタムスクロールバーのデザイン例
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f0f0f0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#c1c1c1",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#a8a8a8",
                },
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
        {/* スマホ画面ならBottomBarを表示 */}
        {isMobile && <BottomBar isLoggedIn={true} />}
      </Box>
    </RouteWrapper>
  );
};
