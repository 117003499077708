import { useContext, useEffect } from "react";
import {
  TableComponent,
  TableComponentExtendedProps,
} from "../../../../uis/Table";
import { logColumnsForTable } from "../../../../utils/columns";
import { defaultLogRow } from "../../../../utils/rows";
import { Log, LogDetail } from "../../../../hooks/Log/type";
import { useWatch } from "react-hook-form";
import { UserContext } from "../../../../contexts/UserContext";
import { Industry } from "../../../../utils/industry";

export const LogTable = ({
  register,
  errors,
  setValue,
  watchedDetails,
  control,
  logMarketPriceDetails,
}: TableComponentExtendedProps<Log, LogDetail>) => {
  const user = useContext(UserContext);
  const watchedTotalAmount = useWatch({
    control,
    name: "total_item_amount",
  });
  const filteredLogColumnsForTable = logColumnsForTable.filter((col) =>
    Boolean(watchedTotalAmount) || user?.industry !== Industry.Market
      ? col.field !== "amount"
      : true,
  );
  const filteredDefaultLogRow = {
    ...defaultLogRow,
    amount:
      Boolean(watchedTotalAmount) || user?.industry !== Industry.Market
        ? 0
        : defaultLogRow.amount,
  };

  useEffect(() => {
    watchedDetails?.forEach((detail, index) => {
      if (!detail) return;
      const top_diameter = detail.top_diameter ?? 0; // mm
      const length = detail.length ?? 0; // mm
      const quantity = detail.quantity ?? 0;

      // 単材積(㎥) = (mm^3) / (1,000,000,000)
      const newUnitVolume = (top_diameter * top_diameter * length) / 100;
      if (detail.unit_volume !== newUnitVolume) {
        setValue(`log_details.${index}.unit_volume`, newUnitVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      // 材積(㎥) = 単材積(㎥) × 本数
      const newTotalVolume = newUnitVolume * quantity;
      if (detail.total_volume !== newTotalVolume) {
        setValue(`log_details.${index}.total_volume`, newTotalVolume, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDetails]);

  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="log_details"
      columns={filteredLogColumnsForTable}
      defaultRow={filteredDefaultLogRow}
      control={control}
      logMarketPriceDetails={logMarketPriceDetails}
    />
  );
};
