import { BlueprintDetail } from "../hooks/Blueprint/type";
import { LogDetail } from "../hooks/Log/type";
import { FreeDetail } from "../hooks/Free/type";
import { WoodDetail } from "../hooks/Wood/type";
import { OmitStrict } from "./types/general_type";

export type FieldsType<T> = {
  [P in keyof T]: string;
};
export const WoodFields = {
  name: "名称",
  species: "樹種",
  material: "素材",
  origin: "産地",
  grade: "等級",
  width: "幅",
  height: "高さ",
  length: "長さ",
  unit_volume: "単材積",
  quantity: "本数",
  total_volume: "材積",
  amount: "金額",
} as const satisfies FieldsType<OmitStrict<WoodDetail, "id">>;
export const LogFields = {
  species: "樹種",
  origin: "産地",
  grade: "等級",
  top_diameter: "末口径",
  length: "長さ",
  unit_volume: "単材積",
  quantity: "本数",
  total_volume: "材積",
  amount: "金額",
} as const satisfies FieldsType<LogDetail>;
export const BlueprintFields = {
  name: "名称",
  species: "樹種",
  material: "素材",
  origin: "産地",
  grade: "等級",
  width: "幅",
  height: "高さ",
  length: "長さ",
  unit_volume: "単材積",
  quantity: "本数",
  total_volume: "材積",
  amount: "金額",
} as const satisfies FieldsType<BlueprintDetail>;
export const FreeFields = {
  name: "名称",
  quantity: "本数",
  amount: "金額",
  cubic_meter: "立米",
} as const satisfies FieldsType<FreeDetail>;
