import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { itemKeys, userKeysBase } from "../../utils/query-key";
import {
  OrderStatusWithId,
  ReadonlyResponse,
  ReadonlyResponseWithId,
} from "../../utils/types/general_type";
import { useAuth } from "../useAuth";
import { Log, ReadonlyLog } from "./type";

export function useCreateLog() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: itemKeys.itemsByFilter({
          itemName: "log",
          isTemporarilyStored: true,
          isOrdered: false,
        }),
      });
    },
  });
}

export function useUpdateLog() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Log) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/log`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          title: data.title,
          comment: data.comment,
          is_temporarily_stored: data.is_temporarily_stored,
          is_ordered: data.is_ordered,
          total_item_amount: data.total_item_amount,
          log_details: data.log_details.map((detail) => ({
            id: detail.id,
            species: detail.species,
            origin: detail.origin,
            grade: detail.grade,
            top_diameter: detail.top_diameter,
            length: detail.length,
            unit_volume: detail.unit_volume,
            quantity: detail.quantity,
            total_volume: detail.total_volume,
            amount: !Boolean(data.total_item_amount)
              ? detail.amount
              : undefined,
          })),
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_temporarily_stored) {
        toast.success("丸太リストを一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: itemKeys.item("log", variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: itemKeys.itemsByFilter({
          itemName: "log",
          isTemporarilyStored: true,
          isOrdered: false,
        }),
      });
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const key = query.queryKey;
          // userKeysBase.user の長さを取得（例: ["user"] なら 1）
          const baseLength = userKeysBase.user.length;
          // クエリキーの次の要素に、GetUserStocks オブジェクトが入っている前提
          const params = key[baseLength] as
            | { woodLog: string; itemId: number; stockId?: number }
            | undefined;
          // キーの最後の要素が "stocks" であることも確認
          const isStocks = key[key.length - 1] === "stocks";
          return (
            isStocks &&
            params?.woodLog === "log" &&
            params?.itemId === variables.id
          );
        },
      });
    },
  });
}

export function useGetLog({
  id,
  isTemporarilyStored,
  isOrdered,
}: OrderStatusWithId) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.item("log", id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/log/${id}?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyLog>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
