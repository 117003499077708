import { Box } from "@mui/material";
import { useEffect } from "react";
import { Buttons } from "../../../../uis/Button/Button";
import { useForm, useWatch } from "react-hook-form";
import {
  useGetBlueprint,
  useUpdateBlueprint,
} from "../../../../hooks/Blueprint/useBlueprintHook";
import { BaseBlueprint } from "../../../../hooks/Blueprint/type";
import { TitleAndCommentField } from "../../../../uis/Forms/TitleAndCommentField";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { NavigateFunction } from "react-router-dom";
import { ORDER_MAILING_PATH } from "../../../../utils/constant";
import { CommonBlueprintImageForm } from "../../../common/Blueprint/BlueprintImageForm";

export const Form = ({
  blueprint_id,
  navigate,
}: {
  blueprint_id: number;
  navigate: NavigateFunction;
}) => {
  const { data, error, isPending } = useGetBlueprint({
    id: blueprint_id,
    isOrdered: false,
    isTemporarilyStored: true,
  });
  const { mutateAsync: mutateUpdateBlueprint } = useUpdateBlueprint();

  // react-hook-form の設定
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<BaseBlueprint>({
    defaultValues: {
      // title や comment は空文字で初期化
      title: "",
      comment: "",
      blueprint_images: [],
    },
  });

  // watch でフォームの値を監視
  const watchedTitle = useWatch({ control, name: "title" });
  const watchedComment = useWatch({ control, name: "comment" });
  const watchedBlueprintImages = useWatch({
    control,
    name: "blueprint_images",
  });

  // data が変わったタイミングでフォーム全体を更新
  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSaveTemporarily = async (formData: BaseBlueprint) => {
    formData.id = blueprint_id;
    formData.is_temporarily_stored = true;
    formData.is_ordered = false;
    try {
      await mutateUpdateBlueprint(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData: BaseBlueprint) => {
    formData.id = blueprint_id;
    try {
      await mutateUpdateBlueprint(formData);
      // 送付先選択に遷移
      navigate(`${ORDER_MAILING_PATH}/blueprint/${blueprint_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }
  // 画像が1つでもあれば true
  const hasAtLeastOneImage =
    Array.isArray(watchedBlueprintImages) &&
    watchedBlueprintImages.some((img) => img.file || img.file_path);

  // 一時保存ボタンの disabled 条件
  const isWhiteButtonDisabled =
    !watchedTitle && !watchedComment && !hasAtLeastOneImage;

  // 「送付先選択へ」ボタンの disabled 条件
  const isBlackButtonDisabled = !watchedTitle || !hasAtLeastOneImage;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%", marginTop: "10%" }}
    >
      {/* タイトル & コメント入力フィールド */}
      <TitleAndCommentField register={register} errors={errors} />
      {/* 画像アップロード用フォーム */}
      <CommonBlueprintImageForm // 型パラメータを指定
        control={control}
        errors={errors}
        fieldNamePrefix="blueprint_images" // ここでフィールド名を指定
        blueprintId={blueprint_id}
      />
      <Buttons
        isMobile
        whiteButtonText="作成内容を一時保存"
        whiteButtonClick={handleSubmit(handleSaveTemporarily)} // handleSaveTemporarily関数を使用
        whiteButtonDisabled={isWhiteButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
        blackButtonText="送付先選択へ"
        type="submit"
        blackButtonDisabled={isBlackButtonDisabled || isSubmitting} // ボタンの有効/無効を制御
      />
    </Box>
  );
};
