import { useContext, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OrderDetailModal } from "../Modals/OrderDetailModal";
import { ItemType, OrderDetailModalType } from "../../utils/types/item_type";
import { getUserLists } from "../../utils/user";
import { UserInfo } from "../UserInfo";
import { useNavigate } from "react-router-dom";
import { Title } from "../Title";
import { conversionItemName } from "../../utils/conversion";
import { useGetItems } from "../../hooks/useItemHook";
import { Loading } from "../Loading";
import { Error } from "../Error";
import { PaginationComponent } from "../Pagination";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";
import { FileAndDetailsComponent } from "../FileAndDetailsComponent";
import { ORDER_BASE_PATH } from "../../utils/constant";
import { OrderStatusEnum } from "../../utils/types/general_type";
import { Industry } from "../../utils/industry";
import { UserContext } from "../../contexts/UserContext";

interface ItemListProps {
  isOrderHistory?: boolean;
  itemName: ItemType;
  handleCancelOrder?: (itemId: number) => Promise<void>;
}

export function ItemList({
  isOrderHistory = true,
  itemName,
  handleCancelOrder,
}: ItemListProps) {
  const user = useContext(UserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState<OrderDetailModalType | null>(null);
  const navigate = useNavigate();

  // カスタムフック
  const { page, handlePageChange } = useSearchAndPaginationHook();

  // API からリスト情報を取得
  const { data, error, isPending } = useGetItems({
    itemName,
    isTemporarilyStored: !isOrderHistory,
    isOrdered: isOrderHistory,
    page,
  });

  if (isPending) return <Loading />;
  if (error) return <Error message={error.message} />;

  return (
    <Box
      sx={{
        // 全体コンテナの高さを固定し、縦長リストをスクロール可能にする
        display: "flex",
        flexDirection: "column",
        width: "95%",
        margin: "0 auto",
        height: "90vh", // 必要に応じて調整
      }}
    >
      {/* タイトル */}
      <Title
        title={`${conversionItemName(itemName)}リスト　${
          isOrderHistory ? "発注" : "一時保存"
        }履歴`}
      />

      {/* リストをスクロールさせるためのコンテナ */}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          overflowY: "auto", // ここでスクロールバーを表示
          mt: 2, // タイトルとの余白
        }}
      >
        <List
          sx={{ width: "95%", bgcolor: "background.paper", margin: "0 auto" }}
        >
          {data && data.items.length > 0 ? (
            data.items.map((item, index) => {
              const {
                isContractingUser,
                isReceivingUsers,
                isPendingUsers,
                isDenyingUsers,
                isApplyingUsers,
              } = getUserLists({
                orders: item.orders,
              });

              return (
                <ListItem
                  key={index}
                  alignItems="flex-start"
                  sx={{
                    borderBottom: "1px solid black",
                    minHeight: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemText
                    primary={
                      itemName === "log" ? (
                        <Box>
                          <Typography variant="h5" sx={{ fontSize: "1.5rem" }}>
                            {item.title ?? "タイトルなし"}
                          </Typography>
                          {/* {log.total_amount !== undefined && (
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              見積もり金額（合計）: ¥
                              {log.total_amount?.toLocaleString()}
                            </Typography>
                          )} */}
                        </Box>
                      ) : (
                        // カスタムタイトル
                        (item.title ?? "タイトルが存在しません")
                      )
                    }
                    secondary={
                      isOrderHistory &&
                      (handleCancelOrder &&
                      item.orders &&
                      item.orders.length > 0 ? (
                        item.orders.every((it) => it.is_active) ? (
                          <Button
                            variant="outlined"
                            onClick={() => handleCancelOrder(item.id)}
                            sx={{
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                              color: "#000000",
                              border: "2px solid #000000",
                              padding: "8px 16px",
                              fontSize: "1rem",
                              transition: "all 0.3s",
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                                borderColor: "#333333",
                              },
                            }}
                          >
                            注文を取り消す
                          </Button>
                        ) : (
                          <Typography>契約済み</Typography>
                        )
                      ) : (
                        <Typography>注文情報なし</Typography>
                      ))
                    }
                    sx={{ width: "30%" }}
                  />

                  {/* ユーザーのセクション表示 */}
                  {isOrderHistory && (
                    <Box sx={{ mb: 4, width: "32%" }}>
                      {isContractingUser && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            契約しているユーザー
                          </Typography>
                          <UserInfo
                            key={isContractingUser.user_id}
                            user={isContractingUser}
                            status="契約中"
                          />
                        </Box>
                      )}
                      {isReceivingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            受注したユーザー
                          </Typography>
                          {isReceivingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="受注中"
                            />
                          ))}
                        </Box>
                      )}
                      {isPendingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            保留中のユーザー
                          </Typography>
                          {isPendingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="保留中"
                            />
                          ))}
                        </Box>
                      )}
                      {isDenyingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            拒否したユーザー
                          </Typography>
                          {isDenyingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="拒否済み"
                            />
                          ))}
                        </Box>
                      )}
                      {isApplyingUsers.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h6" gutterBottom>
                            申請中のユーザー
                          </Typography>
                          {isApplyingUsers.map((user) => (
                            <UserInfo
                              key={user.user_id}
                              user={user}
                              status="申請中"
                            />
                          ))}
                        </Box>
                      )}
                      {/* 表示するユーザーが全くいない場合 */}
                      {!isContractingUser &&
                        [
                          isReceivingUsers,
                          isPendingUsers,
                          isDenyingUsers,
                          isApplyingUsers,
                        ].every((list) => list.length === 0) && (
                          <Typography variant="body1">
                            ユーザーが存在しません。
                          </Typography>
                        )}
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: isMobile ? "70%" : "38%",
                    }}
                  >
                    {/* 詳細・ファイルダウンロードセクション */}
                    {isOrderHistory ? (
                      item.orders.map((order) => (
                        <FileAndDetailsComponent
                          order={order}
                          item={item}
                          detail={{
                            log_details: item?.log_details,
                            wood_details: item?.wood_details,
                            blueprint_details: item?.orders.flatMap(
                              (it) => it.blueprint_details,
                            ),
                            free_details: item?.free_details,
                          }}
                          setOrder={setOrder}
                          isShown={Boolean(
                            order.status === OrderStatusEnum.is_received,
                          )}
                          isMobile={isMobile}
                          itemType={itemName}
                        />
                      ))
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            mr: 1,
                            color: "black",
                            borderColor: "black",
                            backgroundColor: "white",
                            width: "35%",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.1)",
                              borderColor: "black",
                            },
                          }}
                          onClick={() => {
                            setOrder({
                              itemType: itemName,
                              item: item,
                              detail: {
                                log_details: item?.log_details,
                                wood_details: item?.wood_details,
                                free_details: item?.free_details,
                                blueprint_details: item?.orders.flatMap(
                                  (it) => it.blueprint_details,
                                ),
                              },
                            });
                          }}
                        >
                          詳細確認
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            mr: 1,
                            color: "red",
                            borderColor: "red",
                            backgroundColor: "white",
                            width: "35%",
                            "&:hover": {
                              backgroundColor: "rgba(255, 0, 0, 0.1)",
                              borderColor: "red",
                            },
                          }}
                          onClick={() =>
                            navigate(
                              `${ORDER_BASE_PATH}/create_${itemName}_list/${item.id}`,
                            )
                          }
                        >
                          作成を再開
                        </Button>
                      </Box>
                    )}
                  </Box>
                </ListItem>
              );
            })
          ) : (
            // データが無い場合など
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Typography variant="body1" color="textSecondary">
                表示するデータがありません。
              </Typography>
            </Box>
          )}
        </List>
      </Box>

      {/* ページネーションはスクロール領域の外に配置 */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isMobile={isMobile}
        />
      )}
      {/* 詳細モーダル */}
      {order !== null && order.item && (
        <OrderDetailModal
          open={order !== null}
          onClose={() => setOrder(null)}
          itemType={order.itemType}
          item={order.item}
          order={order.order}
          detail={order.detail}
          isTotalItemAmountShown={
            itemName !== "log" ||
            (itemName === "log" && user?.industry === Industry.Market)
          }
        />
      )}
    </Box>
  );
}
