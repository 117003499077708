import { useMutation, useQuery } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { stockWoodKeys } from "../../utils/query-key";
import { handleResponse } from "../../utils/response";
import {
  ReadonlyResponse,
  Status,
  TimeStamp,
} from "../../utils/types/general_type";
import { toast } from "react-toastify";

export interface StockWood extends TimeStamp {
  id: number;
  user_id?: string;
  file_path?: string;
  stock_wood_details: StockWoodDetail[];
  stock_wood_status?: Status;
}

export interface StockWoodDetail {
  name: string;
  species: string;
  material: string;
  origin: string;
  grade: string;
  width: number;
  height: number;
  length: number;
  unit_volume: number;
  quantity: number;
}

export type StockWoods = ReadonlyArray<StockWood>;
export type ReadonlyStockWood = Readonly<StockWood>;

interface UpdateStockWood {
  id: number;
  stock_wood_details: StockWoodDetail[];
}

export function useUpdateStockWood() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (data: StockWood) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/stock_wood`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          stock_wood_details: data.stock_wood_details.map((detail) => ({
            name: detail.name,
            species: detail.species,
            material: detail.material,
            origin: detail.origin,
            grade: detail.grade,
            width: detail.width,
            height: detail.height,
            length: detail.length,
            unit_volume: detail.unit_volume,
            quantity: detail.quantity,
          })),
        } satisfies UpdateStockWood),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async () => {
      toast.success("木材の在庫を更新しました");
    },
  });
}

export function useGetStockWood(id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: stockWoodKeys.stockWood,
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/stock_wood/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyStockWood>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
