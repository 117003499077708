import {
  MANAGEMENT_DOCUMENT_PATH,
  MANAGEMENT_MONEY_PATH,
  MANAGEMENT_STOCK_PATH,
} from "../../utils/constant";
import { CommonTabbedLayout } from "./common/CommonTabbedLayout";

export const ManagementLayout = () => {
  const tabs = [
    { label: "在庫", path: MANAGEMENT_STOCK_PATH },
    { label: "書類", path: MANAGEMENT_DOCUMENT_PATH },
    { label: "お金", path: MANAGEMENT_MONEY_PATH },
  ] as const;

  return <CommonTabbedLayout tabs={tabs} />;
};
