import { BlueprintDetail } from "../hooks/Blueprint/type";
import { LogDetail } from "../hooks/Log/type";
import { StockLogDetail } from "../hooks/Log/useStockLogHook";
import { StockWoodDetail } from "../hooks/Wood/useStockWoodHook";
import { WoodDetail } from "../hooks/Wood/type";
import { ColumnDefinitionForTable } from "../uis/Table";
import { PickSameDetailProperties } from "./types/item_type";
import { FreeDetail } from "../hooks/Free/type";
import { LogMarketPriceDetail } from "../hooks/useLogMarketPriceHook";

// export interface ColumnDefinitionForItemList<T> {
//   label: string;
//   field: keyof T | ((item: T) => React.ReactNode);
//   align?: "left" | "right" | "center" | "inherit" | "justify";
// }

interface ColumnType<T> extends ColumnDefinitionForTable<T> {
  align?: "left" | "right" | "center" | "inherit" | "justify";
}

// 基本となるカラム情報を一箇所にまとめる
export const baseColumns = [
  {
    label: "樹種",
    field: "species",
    type: "text",
    align: "left",
    colSize: "medium",
  },
  {
    label: "産地",
    field: "origin",
    type: "text",
    align: "left",
    colSize: "medium",
  },
  {
    label: "等級",
    field: "grade",
    type: "text",
    align: "left",
    colSize: "medium",
  },
  {
    label: "単材積(m³)",
    field: "unit_volume",
    type: "number",
    step: "any",
    align: "right",
    displayOnly: true,
    unitLabel: "m³",
    colSize: "large",
  },
] as const satisfies ReadonlyArray<ColumnType<PickSameDetailProperties>>;

/** 名前だけ別途必要なケースでよく使う列 */
const nameColumn = {
  label: "名称",
  field: "name",
  type: "text",
  align: "left",
  colSize: "medium",
} as const;

const materialColumn = {
  label: "素材",
  field: "material",
  type: "text",
  align: "left",
  colSize: "medium",
} as const;

/** 丸太特有の "末口径(mm)" 列 */
const topDiameterColumn = {
  label: "末口径(cm)",
  field: "top_diameter",
  type: "number",
  unitLabel: "cm",
  align: "right",
  colSize: "medium",
} as const;

/** 幅・高さの列 */
const widthColumn = {
  label: "幅 (mm)",
  field: "width",
  type: "number",
  unitLabel: "mm",
  colSize: "medium",
} as const;

const heightColumn = {
  label: "高さ(mm)",
  field: "height",
  type: "number",
  unitLabel: "mm",
  colSize: "medium",
} as const;

/** 材積 (m³) の列（合計など計算結果を表示する用途） */
const totalVolumeColumn = {
  label: "材積(m³)",
  field: "total_volume",
  type: "number",
  step: "any",
  align: "right",
  unitLabel: "m³",
  displayOnly: true,
  colSize: "large",
} as const;

/** 相場(円) の列 */
const marketPriceColumn = {
  label: "相場(円)",
  field: "market_price",
  type: "number",
  colSize: "medium",
  unitLabel: "円",
} as const;

export const amountColumn = {
  label: "金額(円)",
  field: "amount",
  type: "number",
  unitLabel: "円",
  colSize: "large",
} as const;

const woodLengthColumn = {
  label: "長さ(mm)",
  field: "length",
  type: "number",
  align: "right",
  unitLabel: "mm",
  colSize: "medium",
} as const;

const logLengthColumn = {
  label: "長さ(m)",
  field: "length",
  type: "number",
  align: "right",
  unitLabel: "m",
  colSize: "medium",
} as const;

const quantityColumn = {
  label: "本数(本)",
  field: "quantity",
  type: "number",
  align: "right",
  unitLabel: "本",
  colSize: "small",
} as const;

const cubicMeterColumn = {
  label: "立米",
  field: "cubic_meter",
  type: "number",
  align: "right",
  unitLabel: "m³",
  colSize: "small",
} as const;

const baseLogColumns: ReadonlyArray<ColumnType<LogDetail>> = [
  ...baseColumns.slice(0, 3),
  topDiameterColumn,
  logLengthColumn,
  ...baseColumns.slice(3), // 残りのカラムを追加
  quantityColumn,
  totalVolumeColumn,
  amountColumn,
] as const;

const logWithQuotationAmountColumns: ReadonlyArray<ColumnType<LogDetail>> = [
  ...baseLogColumns,
  {
    label: "金額(円)",
    field: "quotation_amount",
    type: "number",
    unitLabel: "円",
    colSize: "large",
    canBeEdited: true,
  },
] as const;

export const logColumnsForTable = createTableColumns(baseLogColumns);
export const logWithQuotationAmountColumnsForTable = createTableColumns(
  logWithQuotationAmountColumns,
);

const baseWoodColumns: ReadonlyArray<ColumnType<WoodDetail>> = [
  nameColumn,
  ...baseColumns.slice(0, 1),
  materialColumn,
  ...baseColumns.slice(1, 3),
  widthColumn,
  heightColumn,
  woodLengthColumn,
  ...baseColumns.slice(3), // 残りのカラムを追加
  quantityColumn,
  totalVolumeColumn,
] as const;

// woodColumns を生成
// export const woodColumnsForItem: ReadonlyArray<
//   ColumnDefinitionForItemList<WoodDetail>
// > = baseWoodColumns.map(({ label, field, align }) => ({
//   label,
//   field,
//   align,
// }));

// columns を生成
export const woodColumnsForTable = createTableColumns(baseWoodColumns);

const baseBlueprintColumns: ReadonlyArray<ColumnType<BlueprintDetail>> = [
  nameColumn,
  ...baseColumns.slice(0, 1),
  materialColumn,
  ...baseColumns.slice(1, 3),
  widthColumn,
  heightColumn,
  woodLengthColumn,
  ...baseColumns.slice(3), // 残りのカラムを追加
  quantityColumn,
  totalVolumeColumn,
  amountColumn,
] as const;

// blueprintColumns を生成
// export const blueprintColumnsForItem: ReadonlyArray<
//   ColumnDefinitionForItemList<BlueprintDetail>
// > = baseBlueprintColumns.map(({ label, field, align }) => ({
//   label,
//   field,
//   align,
// }));
// columns を生成
export const blueprintColumnsForTable =
  createTableColumns(baseBlueprintColumns);

const baseStockWoodColumns: ReadonlyArray<ColumnType<StockWoodDetail>> = [
  nameColumn,
  ...baseColumns.slice(0, 1),
  materialColumn,
  ...baseColumns.slice(1, 3),
  widthColumn,
  heightColumn,
  woodLengthColumn,
  ...baseColumns.slice(3), // 残りのカラムを追加
  quantityColumn,
] as const;
export const stockWoodColumnsForTable =
  createTableColumns(baseStockWoodColumns);

const baseStockLogColumns: ReadonlyArray<ColumnType<StockLogDetail>> = [
  ...baseColumns.slice(0, 3),
  topDiameterColumn,
  logLengthColumn,
  ...baseColumns.slice(3), // 残りのカラムを追加
  quantityColumn,
] as const;
// columns を生成
export const stockLogColumnsForTable = createTableColumns(baseStockLogColumns);

const logMarketPriceColumns: ReadonlyArray<ColumnType<LogMarketPriceDetail>> = [
  ...baseColumns.slice(0, 3),
  topDiameterColumn,
  logLengthColumn,
  ...baseColumns.slice(3), // 残りのカラムを追加
  totalVolumeColumn,
  marketPriceColumn,
] as const;
export const logMarketPriceColumnsForTable = createTableColumns(
  logMarketPriceColumns,
);

export const freeColumnsForTable: ReadonlyArray<
  ColumnDefinitionForTable<FreeDetail>
> = [nameColumn, amountColumn, quantityColumn, cubicMeterColumn] as const;

// ColumnType[] を ColumnDefinitionForTable[] に変換するための共通関数
export function createTableColumns<T>(
  columns: ReadonlyArray<ColumnType<T>>,
): ReadonlyArray<ColumnDefinitionForTable<T>> {
  return columns.map(
    ({
      label,
      field,
      type,
      align,
      step,
      unitLabel,
      displayOnly,
      colSize,
      canBeEdited,
    }) => ({
      label,
      field,
      type,
      align,
      step,
      unitLabel,
      displayOnly,
      colSize,
      canBeEdited,
    }),
  ) as ReadonlyArray<ColumnDefinitionForTable<T>>;
}
