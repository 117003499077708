import { Outlet } from "react-router-dom";
import { PublicRoute } from "../PublicRoute";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "../../uis/Bars/Sidebar";

export const LoginLayout = () => {
  const theme = useTheme();
  // 画面幅が sm 以下かどうかを判定
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PublicRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        {/* スマホ画面ではサイドバーを非表示にする */}
        {!isMobile && <Sidebar isLoggedIn={false} />}
        <Outlet />
      </Box>
    </PublicRoute>
  );
};
