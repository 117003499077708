import React from "react";
import { CommonSplitLayout } from "./common/CommonSplitLayout";
import { SawmillPrecutRoute } from "../SawmillPrecutRoute";
import { Order } from "../../pages/Home/Order";
import { Stock } from "../../pages/Home/Stock";
import { ORDER_BASE_PATH } from "../../utils/constant";

/**
 * SawmillPrecutLayout で行っていた「左カラムの切り替え」を関数化。
 */
const leftPanel = (path: string) => {
  if (path.includes("stock")) {
    return <Stock />;
  } else if (path.includes(ORDER_BASE_PATH)) {
    return <Order />;
  }
  return null;
};

export const SawmillPrecutLayout: React.FC = () => {
  return (
    <CommonSplitLayout
      routeWrapper={SawmillPrecutRoute}
      headerTitle="木材"
      leftPanel={leftPanel}
      // デフォルトでサイドバー表示
      isShowSidebar
      // 右カラムの追加スタイルがあれば rightContainerSx に書く
    />
  );
};
