import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { logOrderKeys, userKeys } from "../../utils/query-key";
import { ReadonlyResponse } from "../../utils/types/general_type";
import { useAuth } from "../useAuth";
import { LogDetail, ReadonlyLogOrder } from "./type";

export function useUpdateLogOrderTotalAmount() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      total_order_amount,
      log_details,
    }: {
      id: number;
      total_order_amount?: number;
      log_details: LogDetail[];
    }) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/log_order/total_amount`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id,
            total_order_amount,
            log_details: log_details,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      toast.success("金額を入力しました");
      await queryClient.invalidateQueries({
        queryKey: logOrderKeys.log_receiver(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.item({
          filterType: "applying",
        }),
      });
    },
  });
}

export function useGetLogOrderByReceiver(log_order_id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logOrderKeys.log_receiver(log_order_id),
    queryFn: async () => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/log_order/receiver/${log_order_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyLogOrder>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
