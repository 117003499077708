import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import {
  menubarAuthenticatedItems,
  menubarLoginItems,
} from "../uis/Bars/barItems";

type menubarLoginLink = (typeof menubarLoginItems)[number]["link"];
type menubarAuthenticatedLink =
  (typeof menubarAuthenticatedItems)[number]["link"];

/**
 * Sidebar/BottomBar 両方で使うナビゲーション用の共通フック
 */
export const useBarNavigation = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  /**
   * メニューアイテムをクリックした際の共通処理
   * @param link 遷移先リンク
   */
  const handleNavigation = async (
    link: menubarLoginLink | menubarAuthenticatedLink,
  ) => {
    if (link === "/signout") {
      try {
        const res = await signOut();
        if (res.success) {
          navigate("/signin");
        } else {
          console.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      navigate(link);
    }
  };

  return { handleNavigation };
};
