import { FormHelperText, TextField } from "@mui/material";
import {
  UseFormRegister,
  FieldErrors,
  Path,
  FieldValues,
} from "react-hook-form";

interface TotalAmountFieldProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  totalAmountField?: "total_item_amount" | "total_order_amount";
}

export const TotalAmountField = <T extends FieldValues>({
  register,
  errors,
  totalAmountField = "total_item_amount",
}: TotalAmountFieldProps<T>) => {
  return (
    <>
      <TextField
        label="合計金額"
        type="number"
        fullWidth
        margin="normal"
        {...register(totalAmountField as Path<T>, {
          valueAsNumber: true,
          validate: (value) =>
            value !== undefined && !Number.isNaN(value)
              ? value >= 0
              : true || "正の数を入力してください",
        })}
        inputProps={{ min: 0 }}
        error={!!errors[totalAmountField]}
      />
      {errors[totalAmountField]?.message && (
        <FormHelperText sx={{ color: "red" }}>
          合計金額を入力してください
        </FormHelperText>
      )}
    </>
  );
};
