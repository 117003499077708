import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Buttons } from "../../../../../uis/Button/Button";
import { useForm, useWatch } from "react-hook-form";
import { useUploadFileById } from "../../../../../hooks/S3/useSignedURLHook";
import { Loading } from "../../../../../uis/Loading";
import { Error } from "../../../../../uis/Error";
import { LogMarketPriceTable } from "./log_market_price_table";
import { FileUpload } from "../../../../../uis/File/FileUpload";
import { toast } from "react-toastify";
import {
  ReadonlyLogMarketPrice,
  useGetLatestLogMarketPrice,
  LogMarketPrice,
  useUpdateLogMarketPrice,
} from "../../../../../hooks/useLogMarketPriceHook";
import { UserContext } from "../../../../../contexts/UserContext";
import { TemplateExcelDownload } from "../../../../../uis/File/TemplateExcelDownload";
import { formatJapaneseDate } from "../../../../../utils/time";

export const LatestLogMarketPrice = () => {
  const user = useContext(UserContext);
  const [isPolling, setIsPolling] = useState(false); // ポーリングの状態管理を追加
  const [data, setData] = useState<ReadonlyLogMarketPrice | null>(null); // dataを状態管理
  const { mutateAsync } = useUpdateLogMarketPrice();
  const { mutateAsync: mutateUploadFileToS3, isPending: isMutatePending } =
    useUploadFileById();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetLatestLogMarketPrice();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
  } = useForm<LogMarketPrice>();
  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
      if (fetchedData.log_market_price_status?.status === "pending") {
        setIsPolling(true);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    let intervalId: number; // NodeJS.Timeout から number に変更

    if (isPolling) {
      intervalId = window.setInterval(async () => {
        // window.setInterval を使用
        try {
          const newData = await refetch(); // データを再取得
          if (newData && newData.data) {
            setData(newData.data); // dataを更新
            if (newData.data.log_market_price_status?.status === "success") {
              setIsPolling(false); // ポーリングを停止
            } else if (
              newData.data.log_market_price_status?.status === "failure"
            ) {
              toast.error(newData.data.log_market_price_status.failure_reason);
              if (!newData.data.file_path) {
                setIsPolling(false); // ポーリングを停止
              }
            }
          }
        } catch (error) {
          console.error(error);
          setIsPolling(false); // エラー時もポーリングを停止
        }
      }, 1000); // 1秒ごとに実行
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleFileUpload = async () => {
    if (!selectedFile || !data) {
      toast.error("アップロード対象のファイルがありません");
      return;
    }
    try {
      await mutateUploadFileToS3({
        id: data.id,
        file: selectedFile,
        path: "excel_csv/log_market_price",
      });
      setSelectedFile(null);
      setIsPolling(true); // CSV/Excel の取りこみを監視するためポーリング開始
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: LogMarketPrice) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const watchedLogMarketPriceDetails = useWatch({
    control,
    name: "log_market_price_details",
  });

  const areAllLogDetailsComplete =
    Array.isArray(watchedLogMarketPriceDetails) &&
    watchedLogMarketPriceDetails.every((logDetail) =>
      Object.values(logDetail).every((value) => value !== "" && value !== 0),
    );

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }
  const latestDate = data?.log_market_price_details.reduce(
    (maxDate, detail) => {
      // detail.updated_at が存在し、有効な Date オブジェクトかを確認
      if (detail.updated_at) {
        const currentUpdate = detail.updated_at;
        // isNaN で無効な日付 (例: new Date('invalid date string')) をチェック
        if (currentUpdate && !isNaN(currentUpdate.getTime())) {
          // maxDate が null か、currentUpdate の方が新しい場合に更新
          if (maxDate === null || currentUpdate > maxDate) {
            return currentUpdate;
          }
        }
      }
      // 条件に合わない場合は現在の maxDate を維持
      return maxDate;
    },
    null as Date | null,
  ); // 初期値を null として明示的に型付け

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      {/* ★ 要件1: 最新更新日時の表示 */}
      {latestDate && (
        <Box sx={{ width: "100%", textAlign: "right", mb: 1 }}>
          <Typography variant="caption" color="text.secondary">
            最新更新日時: {formatJapaneseDate(latestDate)}
          </Typography>
        </Box>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        {user?.is_admin && (
          <Box sx={{ margin: "1rem auto" }}>
            <TemplateExcelDownload category="log_market_price" />
          </Box>
        )}
        {/* ファイルアップロード関連のUI (管理者のみ) */}
        {user?.is_admin && (
          <Box>
            {isPolling ? (
              <Typography sx={{ color: "primary.main", fontWeight: "bold" }}>
                アップロードされたファイルを処理中...
              </Typography>
            ) : (
              <FileUpload
                selectedFile={selectedFile}
                filePath={data?.file_path} // 最新の data state を参照
                handleFileChange={handleFileChange}
                accept={["csv", "xlsx"]}
                message="CSV/Excelファイルを取りこむ"
              />
            )}
          </Box>
        )}

        <LogMarketPriceTable
          register={register}
          errors={errors}
          control={control}
          watchedDetails={watchedLogMarketPriceDetails}
          setValue={setValue}
        />
        <Buttons
          isMobile
          whiteButtonText="CSV/Excelファイルをアップロードする"
          whiteButtonClick={handleFileUpload}
          whiteButtonDisabled={!selectedFile || isMutatePending} // ボタンの有効/無効を制御
          blackButtonText="更新する"
          type="submit"
          blackButtonDisabled={
            !areAllLogDetailsComplete || isSubmitting || !user?.is_admin
          } // ボタンの有効/無効を制御
        />
      </Box>
    </Box>
  );
};
