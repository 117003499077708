import { Box } from "@mui/material";
import { useEffect } from "react";
import { BlackButton } from "../../../uis/Button/Button";
import { useForm } from "react-hook-form";
import {
  useGetWoodOrderByReceiver,
  useUpdateWoodQuotationAmount,
} from "../../../hooks/Wood/useWoodHook";
import { useParams } from "react-router-dom";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import { WoodQuotationTable } from "./wood_quotation_table";
import { WoodOrder } from "../../../hooks/Wood/type";
import { MenuComponent } from "../../../uis/Menu";
import { TitleAndCommentField } from "../../../uis/Forms/TitleAndCommentField";
import { TotalAmountField } from "../../../uis/TextField/TotalAmountField";
import { TotalAmount } from "../../../uis/TotalAmount";

export const WoodQuotation = () => {
  // メニューの開閉状態
  const { mutateAsync } = useUpdateWoodQuotationAmount();
  const { wood_order_id } = useParams<{ wood_order_id: string }>();
  const { data, error, isPending } = useGetWoodOrderByReceiver(
    Number(wood_order_id),
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<WoodOrder>();

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  const onSubmit = async (data: WoodOrder) => {
    try {
      await mutateAsync({
        id: Number(wood_order_id),
        wood_details: data.item.wood_details,
        total_order_amount: data.total_order_amount,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%", paddingTop: "10%" }}
      >
        <TitleAndCommentField
          register={register}
          errors={errors}
          disabled={true}
          titleField="item.title"
          commentField="item.comment"
        />
        <WoodQuotationTable
          register={register}
          errors={errors}
          control={control}
        />
        <TotalAmount item={data.item} order={data} />
        <TotalAmountField
          register={register}
          errors={errors}
          totalAmountField="total_order_amount"
        />
        {(data.total_order_and_detail_amount ?? 0) >= 50 && (
          <MenuComponent
            itemType="wood"
            status={data.status}
            userId={data.item.orderer.user_id}
            itemId={data.item.id}
          />
        )}
        <BlackButton
          isMobile
          text="金額を入力"
          type="submit"
          disabled={isSubmitting}
        />
      </Box>
    </Box>
  );
};
