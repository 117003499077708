import {
  Box,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { PhotoCamera, PictureAsPdf } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import heic2any from "heic2any"; // heic2any をインポート

type SimplifiedFileType = "csv" | "xlsx" | "image" | "heic" | "pdf";

type AcceptedFileTypesMapping = {
  csv: ".csv";
  xlsx: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  image: "image/*";
  heic: "image/heic,image/heif"; // HEIC の MIME タイプ
  pdf: "application/pdf";
};

export interface FileUploadProps {
  selectedFile: File | null;
  filePath: string | undefined;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept: SimplifiedFileType[]; // 簡略化された形式を指定
  message?: string;
  disabled?: boolean;
  heicBlob?: Blob;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  selectedFile,
  filePath,
  handleFileChange,
  accept,
  message = "ファイルを選択",
  disabled = false,
  heicBlob,
}) => {
  const [csvData, setCsvData] = useState<string[][] | null>(null);
  const [excelData, setExcelData] = useState<any[][] | null>(null);
  const [localImagePreviewUrl, setLocalImagePreviewUrl] = useState<
    string | null
  >(null); // ローカル選択画像のプレビューURL
  const [serverImagePreviewUrl, setServerImagePreviewUrl] = useState<
    string | null
  >(null); // S3取得画像の最終プレビューURL
  const [isConvertingLocalHeic, setIsConvertingLocalHeic] = useState(false); // ローカルHEIC変換中
  const [isConvertingServerHeic, setIsConvertingServerHeic] = useState(false); // S3取得HEIC変換中
  const [conversionError, setConversionError] = useState<string | null>(null); // 変換エラーメッセージ
  /**
   * accept で指定された簡略化形式を、実際の input[type=file] 用に変換
   */
  const resolveAcceptedFileTypes = (types: SimplifiedFileType[]): string => {
    const mapping: AcceptedFileTypesMapping = {
      csv: ".csv",
      xlsx: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      image: "image/*",
      heic: "image/heic,image/heif",
      pdf: "application/pdf",
    };

    const uniqueTypes = types.includes("image")
      ? types.filter((t) => t !== "heic")
      : types;
    return uniqueTypes
      .flatMap((type) => {
        const value = mapping[type];
        return Array.isArray(value) ? value : [value];
      })
      .join(",");
  };

  /**
   * ファイルの種類に応じてプレビューやデータの抽出などを行う
   */
  useEffect(() => {
    // Cleanup previous local preview
    if (localImagePreviewUrl) {
      URL.revokeObjectURL(localImagePreviewUrl);
      setLocalImagePreviewUrl(null);
    }
    setCsvData(null);
    setExcelData(null);
    setIsConvertingLocalHeic(false);
    setConversionError(null); // ローカルファイル選択時にエラーをクリア

    if (selectedFile) {
      const fileType = selectedFile.type;

      // HEIC 形式のチェック
      if (
        fileType === "image/heic" ||
        fileType === "image/heif" ||
        // ファイル名で判定する場合（typeが空の場合など）
        /\.heic$/i.test(selectedFile.name) ||
        /\.heif$/i.test(selectedFile.name)
      ) {
        setIsConvertingLocalHeic(true);
        heic2any({ blob: selectedFile, toType: "image/jpeg", quality: 0.8 })
          .then((conversionResult) => {
            const resultBlob = Array.isArray(conversionResult)
              ? conversionResult[0]
              : conversionResult;
            const url = URL.createObjectURL(resultBlob);
            setLocalImagePreviewUrl(url);
          })
          .catch((error) => {
            console.error("Local HEIC conversion error:", error);
            setConversionError("HEIC画像の変換に失敗しました。");
          })
          .finally(() => setIsConvertingLocalHeic(false));
      } else if (fileType.startsWith("image/")) {
        // 画像の場合
        setCsvData(null);
        setExcelData(null);
        const url = URL.createObjectURL(selectedFile);
        setLocalImagePreviewUrl(url);
      } else if (fileType === "text/csv") {
        // CSVファイルの処理
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result;
          if (typeof text === "string") {
            const rows = text.split("\n").map((row) => row.split(","));
            setCsvData(rows);
            setExcelData(null);
          }
        };
        reader.readAsText(selectedFile);
      } else if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel" ||
        /\.xlsx$/i.test(selectedFile.name) || // 拡張子での判定も追加
        /\.xls$/i.test(selectedFile.name)
      ) {
        // Excelファイルの処理
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target?.result;
          if (data) {
            const workbook = XLSX.read(data, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const json: any[][] = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            setExcelData(json);
            setCsvData(null);
          }
        };
        reader.readAsBinaryString(selectedFile);
      } else if (
        fileType === "application/pdf" ||
        /\.pdf$/i.test(selectedFile.name) // 拡張子での判定も追加
      ) {
      } else {
        // その他のファイルタイプ
        setCsvData(null);
        setExcelData(null);
      }
    } else {
      // ファイルが選択されていない場合はリセット
      setCsvData(null);
      setExcelData(null);
    }
    // Cleanup Object URL on unmount or when selectedFile changes
    return () => {
      if (localImagePreviewUrl) {
        URL.revokeObjectURL(localImagePreviewUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  // --- Effect for fileData from S3 ---
  useEffect(() => {
    // Cleanup previous server preview URL if it exists
    if (serverImagePreviewUrl) {
      URL.revokeObjectURL(serverImagePreviewUrl);
      setServerImagePreviewUrl(null);
    }
    setIsConvertingServerHeic(false);
    // fileData が変わったら関連するエラーをクリア
    // setConversionError(null); // ここでクリアするかどうかはUXによる

    // Process fileData only if no local file is selected
    if (heicBlob && !selectedFile) {
      const fileType = heicBlob.type;

      if (fileType === "image/heic" || fileType === "image/heif") {
        setIsConvertingServerHeic(true);
        setConversionError(null); // 変換開始時にエラーをクリア
        heic2any({ blob: heicBlob, toType: "image/jpeg", quality: 0.8 })
          .then((conversionResult) => {
            const resultBlob = Array.isArray(conversionResult)
              ? conversionResult[0]
              : conversionResult;
            const url = URL.createObjectURL(resultBlob);
            setServerImagePreviewUrl(url);
          })
          .catch((error) => {
            console.error("Server HEIC conversion error:", error);
            setConversionError("サーバー上のHEIC画像の変換に失敗しました。");
            setServerImagePreviewUrl(null); // 変換失敗時はクリア
          })
          .finally(() => setIsConvertingServerHeic(false));
      } else {
        // 画像以外のファイルタイプの場合
        setServerImagePreviewUrl(null);
      }
    } else if (!selectedFile) {
      // fileData が null になり、かつ selectedFile もない場合
      setServerImagePreviewUrl(null); // サーバープレビューもクリア
      setConversionError(null); // エラーもクリア
    }

    // Cleanup serverImagePreviewUrl on unmount or when fileData/selectedFile changes
    return () => {
      if (serverImagePreviewUrl) {
        URL.revokeObjectURL(serverImagePreviewUrl);
      }
    };
    // selectedFileも依存配列に含める（selectedFileが選択されたらサーバープレビューは不要になるため）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  /**
   * プレビュー部分の描画を行う関数
   */
  const renderPreview = () => {
    const isLoading = isConvertingLocalHeic || isConvertingServerHeic;
    // 表示するURLを決定: ローカル選択 > サーバー変換後 > サーバー元URL
    const displayUrl =
      localImagePreviewUrl ||
      serverImagePreviewUrl ||
      (heicBlob && !selectedFile && heicBlob.type.startsWith("image/")
        ? filePath
        : null);
    // ローカルで選択されたファイルがPDFか
    const isLocalPdfSelected =
      selectedFile?.type === "application/pdf" ||
      (selectedFile && /\.pdf$/i.test(selectedFile.name));

    // S3からのファイルが画像以外か、または変換エラーがあるか
    const isServerFileNonImage =
      heicBlob && !selectedFile && !heicBlob.type.startsWith("image/");
    const hasError = !!conversionError;

    // ▼ 0. ローディング表示
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            p: 2,
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 1 }}>画像を変換中...</Typography>
        </Box>
      );
    }

    // ▼ 1. 画像プレビュー表示 (ローカル or サーバー)
    if (displayUrl) {
      return (
        <Box
          component="img"
          src={displayUrl}
          alt={selectedFile ? "Selected file" : "Uploaded file"}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            console.error("Image load error:", displayUrl);
            setConversionError("画像の読み込みに失敗しました。");
            (e.target as HTMLImageElement).style.display = "none"; // エラー画像は非表示
          }}
        />
      );
    }

    // ▼ 2. ファイル選択済み & プレビュー対象外 (CSV/Excel/その他ローカルファイル)
    if (selectedFile) {
      if (csvData) {
        // CSVテーブル表示 (変更なし)
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  {csvData[0]?.map((h, i) => (
                    <TableCell key={i}>{h}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.slice(1).map((r, ri) => (
                  <TableRow key={ri}>
                    {r.map((c, ci) => (
                      <TableCell key={ci}>{c}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        );
      }
      if (excelData) {
        // Excelテーブル表示 (変更なし)
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  {excelData[0]?.map((h, i) => (
                    <TableCell key={i}>{h}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {excelData.slice(1).map((r, ri) => (
                  <TableRow key={ri}>
                    {r.map((c, ci) => (
                      <TableCell key={ci}>{c}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        );
      }
      if (isLocalPdfSelected) {
        // --- PDFファイルの場合 ---
        return (
          <Box sx={{ p: 2, textAlign: "center", width: "100%" }}>
            <PictureAsPdf
              sx={{ fontSize: 60, color: "action.active", mb: 1 }}
            />
            <Typography
              variant="body1"
              color="textPrimary"
              sx={{
                wordBreak: "break-all", // ファイル名が長い場合に折り返す
                maxWidth: "calc(100% - 32px)", // Paddingを考慮
                mx: "auto", // 中央揃え
              }}
            >
              {selectedFile.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              PDFファイル
            </Typography>
          </Box>
        );
      }
      // ローカルファイルでプレビュー不可 or エラー
      return (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography
            variant="body1"
            color={hasError ? "error" : "textSecondary"}
          >
            {selectedFile.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {hasError
              ? conversionError
              : "プレビューできないファイルタイプです。"}
          </Typography>
        </Box>
      );
    }

    // ▼ 3. S3からのファイルが存在 & 画像以外 or エラー
    if (heicBlob && (isServerFileNonImage || hasError)) {
      // ファイル名があれば表示したいが、fileData には現状ない
      return (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#f7f7f7",
            borderRadius: "10px",
            padding: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            アップロード済みファイル
          </Typography>
          <Typography
            variant="body1"
            color={hasError ? "error" : "textSecondary"}
          >
            {hasError ? conversionError : `プレビュー不可 (${heicBlob.type})`}
          </Typography>
        </Box>
      );
    }

    // ▼ 4. 初期状態 (ファイル未選択、fileDataなし) または予期せぬ状態
    return (
      <Box sx={{ textAlign: "center", p: 2 }}>
        {/* エラーがあれば初期状態でも表示 */}
        {hasError && (
          <Typography variant="body1" color="error" sx={{ mb: 1 }}>
            {conversionError}
          </Typography>
        )}
        <IconButton color="primary" component="label" disabled={disabled}>
          <PhotoCamera sx={{ fontSize: 50 }} />
          <input
            hidden
            accept={resolveAcceptedFileTypes(accept)}
            type="file"
            onChange={handleFileChange}
            disabled={disabled}
            onClick={(e) => (e.currentTarget.value = "")} // Reset value
          />
        </IconButton>
        <Typography>{message}</Typography>
      </Box>
    );
  };
  // --- Component Return ---
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 3,
        marginBottom: 3,
        position: "relative",
        backgroundColor: disabled ? "#e0e0e0" : "#f0f0f0", // 少し明るい背景色に変更
        border: `2px dashed ${disabled ? "#bdbdbd" : "#bdbdbd"}`, // 破線に変更
        borderRadius: "10px",
        overflow: "hidden",
        boxSizing: "border-box", // padding, border を width/height に含める
        "&:hover": {
          // ドラッグ＆ドロップを示唆するホバー効果（任意）
          borderColor: disabled ? "#bdbdbd" : "primary.main",
          backgroundColor: disabled ? "#e0e0e0" : "#e8e8e8",
        },
      }}
      component="label" // Box自体をlabelにしてクリック範囲を広げる
      htmlFor="file-upload-input" // 下のinputと紐付け
    >
      {/* Hidden input trigger - selectedFileやfileDataがある場合は非表示 */}
      <IconButton
        color="primary"
        component="label"
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          // ファイル選択済み、データあり、ロード中、disabled の場合はトリガーを隠す
          zIndex:
            selectedFile ||
            isConvertingLocalHeic ||
            isConvertingServerHeic ||
            disabled
              ? -1
              : 1,
          cursor: disabled ? "default" : "pointer",
        }}
        disabled={disabled}
      >
        <input
          hidden
          accept={resolveAcceptedFileTypes(accept)}
          type="file"
          onChange={handleFileChange}
          disabled={disabled}
          onClick={(e) => (e.currentTarget.value = "")}
        />
      </IconButton>
      {/* Preview Area */}
      <Box
        sx={{
          position: "relative",
          zIndex: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {renderPreview()}
      </Box>
    </Box>
  );
};
