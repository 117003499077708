export enum Industry {
  Forestry = 0,
  Market = 1,
  Sawmill = 2,
  Precut = 3,
  SawmillPrecut = 4,
  Builder = 5,
  Manufacturer = 6,
  Viewer = 7,
  Administrator = 8,
}

export const industries = [
  "林業",
  "市場",
  "製材所",
  "プレカット",
  "製材所&プレカット",
  "工務店",
  "メーカー",
  "閲覧者",
  "管理者",
] as const satisfies readonly string[];

export const industryLabels: Record<Industry, (typeof industries)[number]> = {
  [Industry.Forestry]: "林業",
  [Industry.Market]: "市場",
  [Industry.Sawmill]: "製材所",
  [Industry.Precut]: "プレカット",
  [Industry.SawmillPrecut]: "製材所&プレカット",
  [Industry.Builder]: "工務店",
  [Industry.Manufacturer]: "メーカー",
  [Industry.Viewer]: "閲覧者",
  [Industry.Administrator]: "管理者",
};

export function getIndustryName(industry?: Industry): string {
  if (industry === undefined) {
    return "未指定";
  }
  return industries[industry];
}

export type Industries = ReadonlyArray<Industry>;

export const PrecutUsers = [
  Industry.Precut,
  Industry.SawmillPrecut,
  Industry.Administrator,
] as const satisfies Industries;

export const SawmillPrecutUsers = [
  Industry.Precut,
  Industry.Sawmill,
  Industry.SawmillPrecut,
  Industry.Administrator,
] as const satisfies Industries;

export const BlueprintUsers = [
  Industry.Builder,
  Industry.Manufacturer,
  Industry.Administrator,
] as const satisfies Industries;

export const LogOrders = [
  Industry.Market,
  Industry.Sawmill,
  Industry.SawmillPrecut,
  Industry.Administrator,
] as const satisfies Industries;

export const LogReceivers = [
  Industry.Forestry,
  Industry.Market,
  Industry.Administrator,
] as const satisfies Industries;
