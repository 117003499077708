import { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { LogTable } from "./log_table";
import { TitleAndCommentField } from "../../../../uis/Forms/TitleAndCommentField";
import { TemplateExcelDownload } from "../../../../uis/File/TemplateExcelDownload";
import { Buttons, WhiteButton } from "../../../../uis/Button/Button";
import { Log } from "../../../../hooks/Log/type";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { useGetLog, useUpdateLog } from "../../../../hooks/Log/useLogHook";
import { useNavigate, useParams } from "react-router-dom";
import { ORDER_MAILING_PATH } from "../../../../utils/constant";
import { TotalAmountField } from "../../../../uis/TextField/TotalAmountField";
import { UserContext } from "../../../../contexts/UserContext";
import { Industry } from "../../../../utils/industry";
import ExcelFileUpload from "../../../../uis/File/ExcelFileUpload";
import { useFilePolling } from "../../../../hooks/File/useFilePolling";
import { useFileUpload } from "../../../../hooks/File/useFileUpload";

export const Form = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    control,
  } = useForm<Log>(); // フォームの値を管理する

  const watchedDetails = useWatch({
    control,
    name: "log_details",
  });
  const watchedTotalItemAmount = useWatch({
    control,
    name: "total_item_amount",
  });
  // タイトルをウォッチして一時保存ボタンの disabled 制御に活用
  const watchedTitle = useWatch({
    control,
    name: "title",
  });
  const watchedComment = useWatch({
    control,
    name: "comment",
  });

  const { mutateAsync: updateLogMutate } = useUpdateLog();
  const { data, error, isPending, refetch } = useGetLog({
    id: Number(id),
    isOrdered: false,
    isTemporarilyStored: true,
  });

  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (data) {
      reset(data);
      const status = data.file_status;
      if (status?.status === "pending" && !isPolling) {
        startPolling();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // ファイルアップロード処理
  const {
    selectedFile,
    handleFileChange,
    clearSelectedFile,
    handleFileUploadClick,
    isPending: isUploadMutatePending,
  } = useFileUpload({
    id: Number(id),
    filePath: "excel_csv/log",
    onUploadSuccess: () => {
      startPolling(); // アップロード成功でポーリング開始
    },
  });

  // ファイルステータスのポーリング処理
  const { isPolling, startPolling } = useFilePolling({
    refetch,
  });

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  const onSubmit = async (formData: Log) => {
    try {
      await updateLogMutate({
        ...formData,
        id: Number(id),
      }); // 送付先へ行くので確定状態
      clearSelectedFile(); // 念のためクリア
      navigate(`${ORDER_MAILING_PATH}/log/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveTemporarily = async (formData: Log) => {
    try {
      await updateLogMutate({
        ...formData,
        id: Number(id),
        is_temporarily_stored: true,
        is_ordered: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isWhiteButtonDisabled =
    isSubmitting ||
    (!watchedTotalItemAmount &&
      !watchedComment &&
      !watchedTitle &&
      !(
        watchedDetails &&
        watchedDetails[0] &&
        Object.values(watchedDetails[0]).some(
          (value) => value !== "" && value !== 0,
        )
      ));

  // 全詳細が空でないかどうかのフラグ
  const areAllDetailsComplete =
    Array.isArray(watchedDetails) &&
    watchedDetails.length > 0 &&
    watchedDetails.every((detail) =>
      Object.entries(detail).every(
        ([key, value]) => key === "amount" || (value !== "" && value !== 0),
      ),
    );
  const isBlackButtonDisabled =
    !watchedTitle || !areAllDetailsComplete || isSubmitting;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%" }}
    >
      <Box sx={{ margin: "1rem auto" }}>
        <TemplateExcelDownload category="log" />
      </Box>
      <ExcelFileUpload
        selectedFile={selectedFile}
        filePath={data?.file_path}
        handleFileChange={handleFileChange}
        isPolling={isPolling}
      />
      {/* タイトル入力 */}
      <TitleAndCommentField register={register} errors={errors} />
      {/* 明細テーブル: 型に応じて LogTable または WoodTable を表示 */}
      {data && (
        <LogTable
          register={register}
          errors={errors}
          watchedDetails={watchedDetails}
          setValue={setValue}
          control={control}
          logMarketPriceDetails={data.log_market_price.log_market_price_details}
        />
      )}
      {user?.industry === Industry.Market && (
        <TotalAmountField register={register} errors={errors} />
      )}
      <WhiteButton
        isMobile
        text="CSV/Excelファイルをアップロードする"
        onClick={handleFileUploadClick}
        disabled={!selectedFile || isUploadMutatePending}
      />
      <Buttons
        isMobile
        whiteButtonText="作成内容を一時保存"
        whiteButtonClick={handleSubmit(onSaveTemporarily)} // handleSaveTemporarily関数を使用
        whiteButtonDisabled={isWhiteButtonDisabled} // 一時保存無効条件 + 処理中
        blackButtonText="送付先選択へ"
        type="submit"
        blackButtonDisabled={isBlackButtonDisabled} // 送信無効条件 + 処理中
      />
    </Box>
  );
};
