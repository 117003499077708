import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import FormField from "../../../uis/Forms/FormField";
import { useForm } from "react-hook-form";
import { useUpdatePassword } from "../../../hooks/usePasswordHook";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangePasswordSchema, ChangePassword } from "../../../utils/schema";

export const PasswordForm: React.FC = () => {
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ChangePassword>({
    resolver: zodResolver(ChangePasswordSchema),
  });
  const { mutateAsync } = useUpdatePassword();

  const onSubmit = async (data: ChangePassword) => {
    try {
      await mutateAsync(data);
      setShowPasswordForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const togglePasswordForm = () => {
    setShowPasswordForm((prev) => !prev);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "2rem 0",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={togglePasswordForm}
        >
          管理者用パスワードを編集
        </Button>
      </Box>
      {showPasswordForm && (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <FormField
              labelName="現在の管理者用パスワードを入力してください"
              placeholder="現在の管理者用パスワードを入力してください"
              register={register}
              name="oldPassword"
              isError={!!errors.oldPassword}
              errorMessage={errors.oldPassword?.message}
              sx={{ marginBottom: "1rem" }}
            />
            <FormField
              labelName="新たな管理者用パスワードを入力してください"
              placeholder="新たな管理者用パスワードを入力してください"
              register={register}
              name="newPassword"
              isError={!!errors.newPassword}
              errorMessage={errors.newPassword?.message}
              sx={{ marginBottom: "1rem" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
              sx={{
                background: "black",
                color: "white",
                border: "none",
                width: "100%",
                height: "3.5rem",
              }}
            >
              確認
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
