import { Box, Typography } from "@mui/material";
import { useUpdateUser } from "../../../hooks/useUserHook";
import { Error } from "../../../uis/Error";
import { Loading } from "../../../uis/Loading";
import { Avatar as MuiAvatar } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useContext, useRef } from "react";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../hooks/S3/useSignedURLHook";
import { UserContext } from "../../../contexts/UserContext";
import { ChangeButtons } from "../../../uis/Button/ChangeButtons";

export const Home = () => {
  const user = useContext(UserContext);
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const { mutateAsync: mutateUpdateUser } = useUpdateUser();
  const {
    data: imageData,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: user?.company_image,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const inputFileRef = useRef<HTMLInputElement | null>(null); // input elementの参照

  const handleAvatarClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click(); // inputをクリック
    }
  };
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0] && user) {
      const file = event.target.files[0];
      const uploadData = {
        file,
        path: "img/user",
      } as const satisfies SignedURLRequestToPut;
      try {
        const res = await mutateUploadFileToS3(uploadData); // S3にアップロード
        const updateData = {
          companyImage: res,
        } as const;
        await mutateUpdateUser(updateData);
      } catch (error) {
        console.error("Image upload failed", error);
      }
    }
  };

  if (getLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (getError) {
    return <Error message={getError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "100%", // 残りの80%をメインコンテンツに割り当て
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        background: "#F2F6F5",
        paddingTop: "5%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        {/* 1: 会社名の上に画像（円形）を追加 */}
        <input
          type="file"
          ref={inputFileRef}
          style={{ display: "none" }} // inputを非表示にする
          onChange={handleImageChange}
          accept="image/*"
          title="ファイルを選択" // ここでtitle属性を追加
        />
        {/* 会社アイコン（クリック可能） */}
        <MuiAvatar
          alt="Company Icon"
          src={imageData?.objectURL} // ここに会社のアイコン画像のURLが入る
          sx={{
            width: isMobile ? 90 : 100,
            height: isMobile ? 90 : 100,
            marginBottom: isMobile ? 1 : 2,
            cursor: "pointer", // クリックできるようにする
          }}
          onClick={handleAvatarClick} // クリックでファイル選択を開く
        />
        {/* 会社名 */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobile ? "h4" : "h1"}
            component="h2"
            sx={{ fontSize: isMobile ? "1rem" : "1.5rem" }}
          >
            {user?.company_name}
          </Typography>
        </Box>
      </Box>
      <ChangeButtons />
    </Box>
  );
};
