import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Status } from "../../utils/types/general_type";

// ポーリング対象のデータ型（file_statusとfile_pathを持つことを期待）
interface FileData {
  file_path?: string;
  file_status?: Status;
}

interface UseFilePollingOptions<T extends FileData> {
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<T, Error>>;
}

export const useFilePolling = <T extends FileData>({
  refetch,
}: UseFilePollingOptions<T>) => {
  const [isPolling, setIsPolling] = useState(false);
  const intervalIdRef = useRef<number | null>(null);

  const stopPolling = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    setIsPolling(false);
  };

  const startPolling = () => {
    // 既にポーリング中の場合は何もしない
    if (intervalIdRef.current) return;
    setIsPolling(true);
  };

  // ----- ポーリング関連 -----
  // アイテムの詳細に関するファイルをアップロードした際に、実装する
  useEffect(() => {
    if (isPolling) {
      intervalIdRef.current = window.setInterval(async () => {
        try {
          const { data: newData } = await refetch();
          if (newData) {
            const status = newData.file_status;
            if (status?.status === "success") {
              toast.success("ファイルを正しく処理できました。");
              stopPolling();
            } else if (status?.status === "failure") {
              const reason =
                status.failure_reason || "ファイルの処理に失敗しました。";
              toast.error(reason);
              // file_path がない場合、または失敗理由がある場合はポーリング停止
              if (!newData.file_path || status.failure_reason) {
                stopPolling();
              }
            }
          }
        } catch (error) {
          console.error(error);
          toast.error("ファイルの処理状況の確認中にエラーが発生しました。");
          stopPolling(); // エラー時もポーリングを停止
        }
      }, 1000); // 1秒ごとに実行
    }

    // クリーンアップ関数
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  return { isPolling, startPolling };
};
