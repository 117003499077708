import { ChangeEvent } from "react";
import {
  IconButton,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress, // ローディング表示用
} from "@mui/material";
import {
  Controller,
  useFieldArray,
  Control,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  get,
  FieldError,
  ArrayPath, // ネストされたエラーを取得するために使用
} from "react-hook-form";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useDownloadImages } from "../../../hooks/S3/useS3ImageHook";
import { CommonImageForm } from "./ImageForm";

// TFieldValues は react-hook-form のフォーム全体の型
// TFieldName はフィールド配列のパスの型 (例: "blueprint_images" or "item.blueprint_images")
interface CommonBlueprintImageFormProps<
  TFieldValues extends FieldValues,
  TFieldName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>, // ArrayPathに変更
> {
  control: Control<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  /** フィールド配列の name (パス) を指定します (例: "blueprint_images", "item.blueprint_images") */
  fieldNamePrefix: TFieldName;
  /** 新規追加時に blueprint_images 配列要素に設定する blueprint_id */
  blueprintId: number;
  /** フォーム全体が無効化されているか */
  disabled?: boolean;
}

// ネストされたフィールドのエラーを取得するヘルパー関数
const getFieldError = <TFieldValues extends FieldValues>(
  errors: FieldErrors<TFieldValues>,
  name: string, // Path<TFieldValues> を使いたいが、動的生成のため string
): FieldError | undefined => {
  // get関数の第二引数に型パラメータを指定するとより安全になる場合がある
  return get(errors, name) as FieldError | undefined;
};

export const CommonBlueprintImageForm = <
  TFieldValues extends FieldValues,
  TFieldName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>, // ArrayPathに変更
>({
  control,
  errors,
  fieldNamePrefix,
  blueprintId,
  disabled = false,
}: CommonBlueprintImageFormProps<TFieldValues, TFieldName>) => {
  // useSnackbarなどの通知フックを初期化 (必要に応じて)
  // const { enqueueSnackbar } = useSnackbar();

  // useFieldArray に name を Props から渡す
  // ここで name の型が Path<TFieldValues> であることを期待
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldNamePrefix,
  });

  // ダウンロード用のカスタムフック
  const { mutateAsync: mutateDownloadAsync, isPending: isDownloading } =
    useDownloadImages();

  // ダウンロード処理
  const handleDownload = async (id: number) => {
    try {
      await mutateDownloadAsync({ id, category: "blueprint" });
    } catch (error) {
      console.error(error);
    }
  };

  // ファイル追加処理
  const handleAppend = () => {
    // appendするデータの型を PathValue<TFieldValues, TFieldName>[number] に合わせる
    // BlueprintImageValue を基に、フォームスキーマに合わせた形にする
    const newValue = {
      blueprint_id: blueprintId,
      file_path: "",
      description: "",
      file: undefined, // 新規追加時は file は undefined
    };
    append(newValue as PathValue<TFieldValues, TFieldName>[number]);
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="h6" gutterBottom>
        設計図面のファイル（画像／PDF）
      </Typography>
      <Grid container spacing={2} sx={{ mb: disabled ? 1 : 0 }}>
        {fields.map((field, index) => {
          const fileFieldName = `${fieldNamePrefix}.${index}.file`;
          const descriptionFieldName = `${fieldNamePrefix}.${index}.description`;
          const fileError = getFieldError(errors, fileFieldName);

          return (
            <Grid
              container
              spacing={2}
              key={field.id} // ★ useFieldArray が提供する id を使う
              alignItems="center"
              sx={{ mb: 2 }}
            >
              {/* ----- File Input ----- */}
              <Grid item xs={12} sm={disabled ? 6 : 5}>
                <Controller
                  name={fileFieldName as Path<TFieldValues>} // Path型へのキャストは許容
                  control={control}
                  disabled={disabled}
                  // rules={{ required: "ファイルを選択してください" }} // 必要ならルール追加
                  render={({ field: { onChange, value } }) => (
                    <CommonImageForm
                      selectedFile={value as File | undefined}
                      // field は TFieldValues[TFieldName][number] 型であるべき
                      // anyキャストは極力避ける。fieldの型を適切に推論させるか、明示的に指定する
                      filePath={(field as any)?.file_path as string | undefined}
                      handleFileChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // イベント型を明示
                        if (e.target.files?.[0]) {
                          onChange(e.target.files[0]);
                        } else {
                          onChange(undefined);
                        }
                      }}
                      disabled={disabled}
                    />
                  )}
                />
                {fileError && (
                  <Typography
                    color="error"
                    variant="caption"
                    component="p"
                    sx={{ mt: 0.5 }}
                  >
                    {fileError.message}
                  </Typography>
                )}
              </Grid>

              {/* ----- Description Input ----- */}
              <Grid item xs={10} sm={disabled ? 6 : 5}>
                <Controller
                  name={descriptionFieldName as Path<TFieldValues>}
                  control={control}
                  defaultValue={
                    (field as any)?.description || "" // field の型をanyにキャストする必要がある場合
                  }
                  render={({ field: controllerField }) => (
                    <TextField
                      {...controllerField}
                      disabled={disabled}
                      label="ファイルの説明 (任意)"
                      variant="outlined"
                      fullWidth
                      size="small" // 他の要素との高さを合わせるため
                    />
                  )}
                />
              </Grid>

              {/* ----- Remove Button ----- */}
              {!disabled && (
                <Grid item xs={2} sm={2} container justifyContent="center">
                  <IconButton
                    color="secondary"
                    onClick={() => remove(index)}
                    aria-label={`remove file ${index + 1}`} // より具体的に
                    size="small" // 他の要素とのバランス
                  >
                    <RemoveCircle />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>

      {/* ----- Action Buttons ----- */}
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          alignItems: "center",
          gap: 2, // ボタン間のスペース
        }}
      >
        {/* 一括ダウンロードボタン (disabled時のみ表示) */}
        {disabled && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={
              isDownloading ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            } // ローディング表示
            onClick={() => handleDownload(blueprintId)}
            disabled={isDownloading || fields.length === 0} // ダウンロード中orファイル無は非活性
          >
            画像/PDFファイルを一括ダウンロード
          </Button>
        )}

        {/* ファイル追加ボタン (disabledでない時のみ表示) */}
        {!disabled && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddCircle />}
            onClick={handleAppend}
            aria-label="add file"
          >
            ファイルを追加
          </Button>
        )}
      </Box>
    </Box>
  );
};
