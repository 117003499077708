import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { itemKeys, userKeys, woodOrderKeys } from "../../utils/query-key";
import {
  OrderStatusWithId,
  ReadonlyResponse,
  ReadonlyResponseWithId,
} from "../../utils/types/general_type";
import { ReadonlyWood, ReadonlyWoodOrder, Wood, WoodDetail } from "./type";

export function useCreateWood() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: itemKeys.itemsByFilter({
          itemName: "wood",
          isTemporarilyStored: true,
          isOrdered: false,
        }),
      });
    },
  });
}

export function useUpdateWood() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Wood) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          title: data.title,
          comment: data.comment,
          is_temporarily_stored: data.is_temporarily_stored,
          is_ordered: data.is_ordered,
          wood_details: data.wood_details.map((detail) => ({
            name: detail.name,
            species: detail.species,
            material: detail.material,
            origin: detail.origin,
            grade: detail.grade,
            width: detail.width,
            height: detail.height,
            length: detail.length,
            unit_volume: detail.unit_volume,
            quantity: detail.quantity,
            total_volume: detail.total_volume,
          })),
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_temporarily_stored) {
        toast.success("木材リストを一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: itemKeys.item("wood", variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: itemKeys.itemsByFilter({
          itemName: "wood",
          isTemporarilyStored: true,
          isOrdered: false,
        }),
      });
    },
  });
}

export function useGetWood({
  id,
  isTemporarilyStored,
  isOrdered,
}: OrderStatusWithId) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.item("wood", id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/wood/${id}?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyWood>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useUpdateWoodQuotationAmount() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      total_order_amount,
      wood_details,
    }: {
      id: number;
      total_order_amount?: number;
      wood_details: WoodDetail[];
    }) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(
        `${config.backendUrl}/api/wood_quotation_amount`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id,
            wood_details: wood_details.filter((it) => !!it.amount),
            total_order_amount,
          }),
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      toast.success("金額を入力しました");
      await queryClient.invalidateQueries({
        queryKey: woodOrderKeys.wood_receiver(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.item({
          filterType: "applying",
        }),
      });
    },
  });
}

export function useGetWoodOrderByReceiver(woodOrderid: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodOrderKeys.wood_receiver(woodOrderid),
    queryFn: async () => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/wood_order/receiver/${woodOrderid}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyWoodOrder>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
