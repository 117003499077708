import React from "react";
import { Box, Typography } from "@mui/material";
import { UserForm } from "./user_form";
import { PasswordForm } from "./password_form";

export const Profile: React.FC = () => {
  return (
    <Box
      sx={{
        // Sidebarが20%固定幅のため、残り80%をメインコンテンツとして使用
        width: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "2rem", // 画面下部の余白
      }}
    >
      <Typography variant="h5" sx={{ paddingTop: "2rem" }}>
        プロフィール編集
      </Typography>
      <Box sx={{ width: "95%" }}>
        <UserForm />
        <PasswordForm />
      </Box>
    </Box>
  );
};
