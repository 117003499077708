import { FreeOrder } from "../../../hooks/Free/type";
import {
  ColumnDefinitionForTable,
  TableComponent,
  TableComponentProps,
} from "../../../uis/Table";
import { freeColumnsForTable } from "../../../utils/columns";
import { defaultFreeRow } from "../../../utils/rows";
import { useWatch } from "react-hook-form";

export const FreeQuotationTable = ({
  register,
  errors,
  control,
}: TableComponentProps<FreeOrder>) => {
  const watchedTotalDetailAmount = useWatch({
    control,
    name: "item.total_detail_amount",
  });
  const watchedDetailsQuantity = useWatch({
    control,
    name: `item.free_details.0.quantity`,
  });
  const watchedDetailsCubicMeter = useWatch({
    control,
    name: `item.free_details.0.cubic_meter`,
  });
  const filteredColumns = freeColumnsForTable.filter((col) => {
    if (!watchedTotalDetailAmount) {
      if (watchedDetailsQuantity > 0) {
        return col.field !== "amount" && col.field !== "cubic_meter";
      }
      if (watchedDetailsCubicMeter > 0) {
        return col.field !== "amount" && col.field !== "quantity";
      }
      // 合計値を入力すると、異常発生
      return col.field !== "amount";
    }
    if (watchedDetailsQuantity > 0) {
      return col.field !== "cubic_meter";
    }
    if (watchedDetailsCubicMeter > 0) {
      return col.field !== "quantity";
    }
    return true;
  });
  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="item.free_details"
      columns={filteredColumns as ColumnDefinitionForTable<unknown>[]}
      defaultRow={defaultFreeRow}
      disabled={true}
      control={control}
    />
  );
};
