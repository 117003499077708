import { useMutation, useQuery } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { s3Keys } from "../../utils/query-key";

export interface SignedURLRequestToPut {
  path: string;
  file: File;
}
export function useUploadFileToS3() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (data: SignedURLRequestToPut) => {
      const { token } = await getAccessToken();
      // サーバーから署名付きURLを取得
      const presignedResponse = await fetch(
        `${config.backendUrl}/api/put_presigned_url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            filename: data.file.name,
            filetype: data.file.type,
            path: data.path,
          }),
        },
      );
      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "アップロード中にエラーが発生しました",
        );
      }
      const { url, object_path } = await presignedResponse.json();

      // 画像をS3にアップロード
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": data.file.type,
        },
        body: data.file,
      });
      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "アップロード中にエラーが発生しました",
        );
      }

      return object_path as string; // 画像のキーを取得
    },
  });
}

interface SignedURLRequestToPutById extends SignedURLRequestToPut {
  id: number;
}
export function useUploadFileById() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (data: SignedURLRequestToPutById) => {
      const { token } = await getAccessToken();
      // サーバーから署名付きURLを取得
      const presignedResponse = await fetch(
        `${config.backendUrl}/api/put_presigned_url/id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id: data.id,
            filename: data.file.name,
            filetype: data.file.type,
            path: data.path,
          }),
        },
      );
      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "アップロード中にエラーが発生しました",
        );
      }
      const { url, object_path } = await presignedResponse.json();

      // 画像をS3にアップロード
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": data.file.type,
        },
        body: data.file,
      });
      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "アップロード中にエラーが発生しました",
        );
      }
      return object_path as string; // 画像のキーを取得
    },
  });
}

export interface SignedURLRequestToGet {
  path?: string;
}
export function useGetFileFromS3(data: SignedURLRequestToGet) {
  const { getAccessToken } = useAuth();

  return useQuery({
    queryKey: s3Keys.file(data.path),
    queryFn: async () => {
      const { token } = await getAccessToken();

      // サーバーから署名付きURLを取得
      const presignedResponse = await fetch(
        `${config.backendUrl}/api/get_presigned_url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            path: data.path,
          }),
        },
      );

      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "署名付きURLの取得中にエラーが発生しました",
        );
      }

      const { url } = await presignedResponse.json();

      // S3から画像を取得
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "S3からファイルを取得中にエラーが発生しました",
        );
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);

      return { blob, objectURL }; // Blob URLを返す
    },
    enabled: !!data.path, // data.pathが存在する場合のみクエリを有効にする
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export interface SignedURLRequestToGetById {
  id?: number;
  filetype:
    | "xlsx"
    | "pdf"
    | "normal"
    | "logging_notice_or_forest_management_plan_number";
  category: string;
  enabled?: boolean;
}
export function useDownloadFromS3() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (req: SignedURLRequestToGetById) => {
      const { token } = await getAccessToken();
      // サーバーから署名付きURLを取得
      const presignedResponse = await fetch(
        `${config.backendUrl}/api/get_presigned_url/id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(req),
        },
      );

      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "署名付きURLの取得中にエラーが発生しました",
        );
      }
      const { url } = await presignedResponse.json();

      // 画像をS3にアップロード
      const response = await fetch(url, {
        method: "GET",
      });
      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "S3からファイルを取得中にエラーが発生しました",
        );
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);

      return objectURL; // Blob URLを返す
    },
  });
}
export function useGetFileFromS3ById(data: SignedURLRequestToGetById) {
  const { getAccessToken } = useAuth();

  return useQuery({
    queryKey: s3Keys.file_by_id(data),
    queryFn: async () => {
      const { token } = await getAccessToken();

      // サーバーから署名付きURLを取得
      const presignedResponse = await fetch(
        `${config.backendUrl}/api/get_presigned_url/id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id: data.id,
            filetype: data.filetype,
            category: data.category,
          }),
        },
      );

      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "署名付きURLの取得中にエラーが発生しました",
        );
      }

      const { url } = await presignedResponse.json();

      // S3から画像を取得
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "S3からファイルを取得中にエラーが発生しました",
        );
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);

      return { blob, objectURL }; // Blob URLを返す
    },
    enabled: !!data.enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export type TemplateFileCategory =
  | "wood"
  | "log"
  | "blueprint"
  | "stock_wood"
  | "stock_log"
  | "log_market_price";
export function useDownloadTemplateFileFromS3() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async (category: TemplateFileCategory) => {
      const { token } = await getAccessToken();
      // サーバーから署名付きURLを取得
      const presignedResponse = await fetch(
        `${config.backendUrl}/api/get_presigned_url/template_file`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(category),
        },
      );

      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "署名付きURLの取得中にエラーが発生しました",
        );
      }
      const { url } = await presignedResponse.json();

      // 画像をS3にアップロード
      const response = await fetch(url, {
        method: "GET",
      });
      if (!response.ok) {
        const responseJson = await response.json();
        throw new Error(
          responseJson.error || "S3からファイルを取得中にエラーが発生しました",
        );
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);

      return objectURL; // Blob URLを返す
    },
  });
}
