/**
 * オブジェクトや配列を再帰的に探索し、
 * 指定されたキー (デフォルトは 'created_at', 'updated_at') の値 (文字列) を
 * Date オブジェクトに変換します。
 * 元のオブジェクト/配列は変更せず、新しいものを返します。
 *
 * @param data 変換対象のデータ (任意の型)
 * @param dateKeys Date オブジェクトに変換したいキー名の配列
 * @returns 変換後のデータ
 */
const convertDateStringsInObject = (
  data: unknown,
  dateKeys: string[] = ["created_at", "updated_at"],
): unknown => {
  // プリミティブ型、null、または既に Date オブジェクトの場合はそのまま返す
  // これらは再帰のベースケースとなる
  if (data === null || typeof data !== "object" || data instanceof Date) {
    return data;
  }

  // 配列の場合: 各要素に対して再帰的に関数を適用し、新しい配列を生成
  if (Array.isArray(data)) {
    return data.map((item) => convertDateStringsInObject(item, dateKeys));
  }

  // オブジェクトの場合: 新しいオブジェクトを作成し、各プロパティを処理
  const newObj: { [key: string]: unknown } = {};
  for (const key in data) {
    // オブジェクト自身のプロパティのみを対象とする (プロトタイプチェーンは無視)
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = (data as { [key: string]: unknown })[key];

      // キーが指定された日付キーのいずれかに一致し、値が文字列の場合
      if (dateKeys.includes(key) && typeof value === "string") {
        const potentialDate = new Date(value);
        // new Date() が有効な日付オブジェクトを生成したかチェック
        // isNaN(potentialDate.getTime()) は Invalid Date を判定する常套手段
        if (!isNaN(potentialDate.getTime())) {
          // 有効な日付であれば Date オブジェクトを設定
          newObj[key] = potentialDate;
        } else {
          // 無効な日付文字列だった場合 (例: "", "invalid-date-string")
          // null を設定するか、元の値を保持するか、エラーにするかなどを選択
          // ここでは null を設定する例
          newObj[key] = null;
        }
      } else {
        // キーが日付キーでない場合、または値が文字列でない場合は、
        // 値に対して再帰的に関数を適用して結果を設定
        newObj[key] = convertDateStringsInObject(value, dateKeys);
      }
    }
  }
  return newObj;
};

/**
 * APIレスポンスを処理し、レスポンスボディのJSONをパースした後、
 * JSON内の 'created_at' と 'updated_at' (または指定されたキー) の文字列値を
 * 再帰的に Date オブジェクトに変換します。
 *
 * @param response Fetch API の Response オブジェクト
 * @param dateKeys オプション: Date オブジェクトに変換したいキー名の配列。デフォルトは ['created_at', 'updated_at']。
 * @returns 変換後のデータを含む Promise、またはエラー時に reject される Promise
 */
export const handleResponse = async (
  response: Response,
  dateKeys: string[] = ["created_at", "updated_at"],
) => {
  // レスポンスボディがない場合のハンドリング (例: 204 No Content)
  if (response.status === 204 || !response.body) {
    if (response.ok) {
      // 成功レスポンスでボディがない場合は null や undefined を返すなど、API仕様に合わせる
      return null;
    } else {
      // エラーレスポンスでボディがない場合
      throw new Error(
        `HTTP ${response.status}: ${response.statusText || "エラーが発生しました (No Content)"}`,
      );
    }
  }

  let responseData: unknown;
  try {
    // レスポンスボディを JSON としてパース
    responseData = await response.json();
  } catch (error) {
    // JSON パースに失敗した場合
    console.error("Failed to parse JSON response:", error);
    // response.ok かどうかに関わらず、形式エラーとして扱う
    throw new Error("レスポンスの形式が無効です。");
  }

  if (response.ok) {
    // 成功した場合、パースした JSON データに対して再帰的な日付変換を実行
    // console.log("Original JSON Data:", JSON.stringify(responseData, null, 2)); // デバッグ用
    const convertedData = convertDateStringsInObject(responseData, dateKeys);
    // console.log("Converted Data:", convertedData); // デバッグ用
    return convertedData;
  } else {
    // エラーレスポンスの場合
    let errorMessage = `HTTP ${response.status}: エラーが発生しました`;
    // エラーレスポンスのボディから詳細なメッセージを抽出試行
    if (responseData && typeof responseData === "object") {
      const errorDetail =
        (responseData as any).error ||
        (responseData as any).message ||
        (responseData as any).detail;
      if (typeof errorDetail === "string") {
        errorMessage = `HTTP ${response.status}: ${errorDetail}`;
      } else if (errorDetail) {
        try {
          errorMessage = `HTTP ${response.status}: ${JSON.stringify(errorDetail)}`;
        } catch {
          /* stringify 失敗時はデフォルトメッセージのまま */
        }
      }
    }
    throw new Error(errorMessage);
  }
};
