import {
  ListItem,
  ListItemAvatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  IconButton,
} from "@mui/material";
import { ReadonlyGetUser, User } from "../../hooks/useUserHook";
import Avatar from "../Avatar";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useDownloadOrderMessageFileFromS3 } from "../../hooks/useOrderMessageHook";
import { extractFileName } from "../../utils/file";
import { Image } from "../Image";
import { ItemMessageType } from "../../utils/types/item_type";
import React, { useState } from "react";
import { UserDetailModal } from "../Modals/UserDetailModal";
import { Message } from "../../hooks/Message/useMessageHook";

interface MessageItemProps {
  message: Message;
  user: ReadonlyGetUser | null;
  partner: ReadonlyGetUser;
  type: ItemMessageType | undefined;
}

export const MessageItem: React.FC<MessageItemProps> = ({
  message,
  user,
  partner,
  type,
}: MessageItemProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isCurrentUser = message.user_id === user?.user_id;
  const { mutateAsync, isPending } = useDownloadOrderMessageFileFromS3();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  // コンテンツとファイルが両方存在しない場合は何も表示しない
  if (!message.content && !message.file_path) {
    return null;
  }

  const handleClick = async (id: number, filePath?: string) => {
    try {
      const res = await mutateAsync({ messageId: id, type });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(res);
      // filePathが提供されていればファイル名を設定、なければデフォルト名
      link.download = filePath ? extractFileName(filePath) : "file";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAvatarClick = () => {
    const userToShow = isCurrentUser ? user : partner;
    if (userToShow) {
      setSelectedUser(userToShow);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedUser(undefined);
  };

  return (
    <>
      <ListItem
        key={message.id}
        sx={{
          display: "flex",
          justifyContent: isCurrentUser ? "flex-end" : "flex-start",
          alignItems: "flex-start",
          mb: 1,
        }}
      >
        {!isCurrentUser && message.content && (
          <ListItemAvatar>
            <IconButton onClick={handleAvatarClick}>
              <Avatar user={partner} isMobile={isMobile} />
            </IconButton>
          </ListItemAvatar>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isCurrentUser ? "flex-end" : "flex-start",
            maxWidth: "60%",
          }}
        >
          {/* メッセージ内容 */}
          {message.content && (
            <Box
              sx={{
                bgcolor: isCurrentUser ? "#DCF8C6" : "#FFFFFF",
                borderRadius: "10px",
                p: 1.5,
                m: 0.5,
                boxShadow: 1,
                wordBreak: "break-word",
              }}
            >
              <Typography>{message.content}</Typography>
            </Box>
          )}

          {/* ファイルの表示 */}
          {message.file_path && (
            <Box
              sx={{
                bgcolor: isCurrentUser ? "#e0f7fa" : "#f1f8e9",
                borderRadius: "10px",
                p: 1.5,
                m: 0.5,
                boxShadow: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {/\.(jpg|jpeg|png|gif)$/.test(message.file_path.toLowerCase()) ? (
                <Image filePath={message.file_path} />
              ) : (
                <InsertDriveFileIcon color="action" sx={{ mr: 1 }} />
              )}
              <Button
                onClick={() => handleClick(message.id, message.file_path)}
                disabled={isPending}
              >
                {extractFileName(message.file_path) || "ファイルをダウンロード"}
              </Button>
            </Box>
          )}

          {/* メッセージ送信時間 */}
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              mt: 0.5,
              alignSelf: isCurrentUser ? "flex-end" : "flex-start",
            }}
          >
            {new Date(message.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        </Box>
        {isCurrentUser && message.content && (
          <ListItemAvatar>
            <IconButton onClick={handleAvatarClick}>
              <Avatar user={user} isMobile={isMobile} />
            </IconButton>
          </ListItemAvatar>
        )}
      </ListItem>
      {/* ユーザー詳細モーダル */}
      {selectedUser && (
        <UserDetailModal
          open={modalOpen}
          onClose={handleCloseModal}
          user={selectedUser}
        />
      )}
    </>
  );
};
