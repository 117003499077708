import { ProtectedRoute } from "./ProtectedRoute";
import { LogOrders } from "../utils/industry";

export const LogOrderRoute = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProtectedRoute
      requiredIndustries={LogOrders}
      industryErrorMessage="丸太に関する権限が存在しません。市場、製材所、製材所&プレカットのユーザーのみ権限が存在します。"
    >
      {children}
    </ProtectedRoute>
  );
};
