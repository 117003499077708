// src/uis/Bars/Sidebar.tsx
import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "../svg";
import { Color } from "../../utils/color";
import { menubarAuthenticatedItems, menubarLoginItems } from "./barItems";
import { useBarNavigation } from "../../hooks/useBarNavigationHook";
import { UserContext } from "../../contexts/UserContext";
import { UnreadBadge } from "../Badge";

interface SidebarProps {
  isLoggedIn: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isLoggedIn = true }) => {
  const user = useContext(UserContext);
  const location = useLocation();
  const { handleNavigation } = useBarNavigation();

  return (
    <Box
      sx={{
        width: "20%",
        minWidth: "200px",
        minHeight: "100vh",
        backgroundColor: Color.Main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        boxSizing: "border-box",
      }}
    >
      {/* ロゴとタイトル */}
      <Box
        sx={{ textAlign: "center", marginBottom: "40px", cursor: "pointer" }}
        onClick={() => handleNavigation("/home")}
      >
        <Logo />
        <Typography variant="h6" sx={{ color: "white", marginTop: "10px" }}>
          EFF木材流通システム
        </Typography>
      </Box>
      {/* ナビゲーションメニュー */}
      {isLoggedIn
        ? menubarAuthenticatedItems.map((item, index) => {
            const isActive =
              location.pathname.split("/")[1] === item.link.split("/")[1];
            return (
              <Button
                key={index}
                onClick={() => handleNavigation(item.link)}
                sx={{
                  width: "100%",
                  color: isActive ? "#006747" : "white",
                  backgroundColor: isActive ? "white" : "#006f3d",
                  borderTop: "0.5px solid white",
                  borderBottom: "0.5px solid white",
                  justifyContent: "start",
                  padding: "1.5rem 1rem",
                  fontSize: "1rem",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: isActive ? "white" : "#54c291",
                  },
                }}
              >
                {item.children}
                {item.label}
                {item.label === "トーク" &&
                  (user?.unread_message_count ?? 0) +
                    (user?.unread_order_message_count ?? 0) >
                    0 && (
                    <UnreadBadge
                      badgeContent={
                        (user?.unread_message_count ?? 0) +
                        (user?.unread_order_message_count ?? 0)
                      }
                      color="error"
                      overlap="circular"
                    />
                  )}
                {item.label === "注文" &&
                  (user?.is_received_is_pending_is_denied_all_false ?? 0) +
                    (user?.is_received_true_only ?? 0) +
                    (user?.is_pending_true_only ?? 0) +
                    (user?.is_denied_true_only ?? 0) +
                    (user?.log_order_applying_count ?? 0) +
                    (user?.wood_order_applying_count ?? 0) +
                    (user?.blueprint_order_applying_count ?? 0) +
                    (user?.free_order_applying_count ?? 0) >
                    0 && (
                    <UnreadBadge
                      badgeContent={
                        (user?.is_received_is_pending_is_denied_all_false ??
                          0) +
                        (user?.is_received_true_only ?? 0) +
                        (user?.is_pending_true_only ?? 0) +
                        (user?.is_denied_true_only ?? 0) +
                        (user?.log_order_applying_count ?? 0) +
                        (user?.wood_order_applying_count ?? 0) +
                        (user?.blueprint_order_applying_count ?? 0) +
                        (user?.free_order_applying_count ?? 0)
                      }
                      color="error"
                      overlap="circular"
                    />
                  )}
              </Button>
            );
          })
        : menubarLoginItems.map((item, index) => {
            const isActive = location.pathname === item.link;
            return (
              <Button
                key={index}
                onClick={() => handleNavigation(item.link)}
                sx={{
                  width: "100%",
                  color: isActive ? "#006747" : "white",
                  backgroundColor: isActive ? "white" : "#006f3d",
                  borderTop: "0.5px solid white",
                  borderBottom: "0.5px solid white",
                  justifyContent: "start",
                  padding: "1.5rem 1rem",
                  fontSize: "1rem",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: isActive ? "white" : "#54c291",
                  },
                }}
              >
                {item.children}
                {item.label}
              </Button>
            );
          })}
      {/* フッターリンク類 */}
      <Box sx={{ margin: "0.5rem auto" }}>
        <Box>
          <Link
            to="/inquiry"
            style={{ color: "white", textDecoration: "none" }}
          >
            ヘルプ・問合せ
          </Link>
        </Box>
        <Box>
          <Link
            to="/terms_of_service"
            style={{ color: "white", textDecoration: "none" }}
          >
            利用規約
          </Link>
        </Box>
        <Box>
          <Link
            to="/specified_commercial_transaction_act"
            style={{ color: "white", textDecoration: "none" }}
          >
            特定商取引法に基づく表記
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
