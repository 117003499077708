/**
 * e（指数表記）を使わず、小さい値でも通常の0.xxxxx表記にするためのフォーマッタ
 * - 例: 2.7e-7 → 0.00000027
 * - 例: 123.400000 → 123.4
 * - 小数点以下最大12桁まで表示し、それ以上は四捨五入
 */
export function formatNumberNoExponential(value: number): string {
  if (!isFinite(value)) return String(value); // InfinityやNaNの場合

  // 1) 12桁固定で文字列化 (指数表記にならないように toFixed 使用)
  let str = value.toFixed(12);

  // 2) 末尾の不要な0を削除 (例: "0.000000270000" → "0.00000027")
  //    また、小数点自体が余る場合(例: "123.0"→"123")にも対応
  str = str.replace(/(\.\d*?[1-9])0+$/, "$1").replace(/\.0+$/, "");

  return str;
}

/**
 * 指数表記 (e表記) を小数表記に変換したあと、
 * 余分な小数点以下の0を取り除く関数
 */
export function toDecimalString(num: number, fractionDigits = 12): string {
  // 非数(Infinity, -Infinity, NaNなど)はそのまま返す
  if (!Number.isFinite(num)) {
    return String(num);
  }

  // ほぼ0とみなせるほど小さい場合は "0" を返す
  if (Math.abs(num) < Number.EPSILON) {
    return "0";
  }

  // JavaScriptの標準的な浮動小数点誤差を考慮しつつ
  // まずは指数表記の文字列を得る
  const expString = num.toExponential(fractionDigits); // 例: 6.9e-8 → "6.900000000000e-8"

  const [mantissa, exponentStr] = expString.split("e");
  let exponent = parseInt(exponentStr, 10);

  // 符号を切り分け
  const sign = mantissa.startsWith("-") ? "-" : "";
  const mantissaAbs = sign ? mantissa.slice(1) : mantissa;
  // 例: mantissaAbs="6.900000000000"

  // 整数部と小数部に分割
  let [intPart, fracPart = ""] = mantissaAbs.split(".");

  // ----------- ここから指数を手動でシフトする -----------
  let result: string;

  if (exponent > 0) {
    // e+ の場合（小数点を右に exponent 桁動かす）
    if (fracPart.length <= exponent) {
      // 小数部が exponent に足りない場合 → 末尾に0を足す
      // 例: intPart="6", fracPart="9", exponent=1 → "69"
      fracPart = fracPart.padEnd(exponent, "0");
      result = sign + intPart + fracPart;
    } else {
      // 例: intPart="6", fracPart="900000000000", exponent=2 → "690.0000000000"
      const move = fracPart.slice(0, exponent);
      const remain = fracPart.slice(exponent);
      result = sign + intPart + move + (remain ? "." + remain : "");
    }
  } else if (exponent < 0) {
    // e- の場合（小数点を左に |exponent| 桁動かす）
    exponent = -exponent;
    // 整数部を上位桁側へ0埋め
    // 例: intPart="6", exponent=1 → "06"
    intPart = intPart.padStart(intPart.length + exponent, "0");

    const idx = intPart.length - exponent;
    const left = intPart.slice(0, idx) || "0";
    const right = intPart.slice(idx) + fracPart; // 小数点以下に残り全部を入れる
    result = sign + left + "." + right;
  } else {
    // exponent=0 の場合はそのまま
    result = sign + intPart + (fracPart ? "." + fracPart : "");
  }

  // ----------- ここで末尾の不要なゼロを削除する -----------
  result = removeTrailingZeros(result);

  return result;
}

/**
 * 小数点以下の末尾に連続する0を削除し、
 * 最終的に小数点そのものが不要なら削除するヘルパー
 */
function removeTrailingZeros(value: string): string {
  // 小数点が無ければそのまま返す
  if (!value.includes(".")) {
    return value;
  }

  // 符号と絶対値部分で分割
  const sign = value.startsWith("-") ? "-" : "";
  const unsignedValue = sign ? value.slice(1) : value;

  // 小数点の左右に分割
  const [intPart, fracPart] = unsignedValue.split(".");

  // 末尾の0を削除
  const trimmedFrac = fracPart.replace(/0+$/g, "");

  // 全て削除された場合は小数点不要 → 整数とみなす
  if (trimmedFrac === "") {
    return sign + intPart;
  }

  return sign + intPart + "." + trimmedFrac;
}
