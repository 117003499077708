import { useContext, useEffect } from "react";
import {
  LogMarketPrice,
  LogMarketPriceDetail,
} from "../../../../../hooks/useLogMarketPriceHook";
import {
  TableComponent,
  TableComponentExtendedProps,
} from "../../../../../uis/Table";
import { UserContext } from "../../../../../contexts/UserContext";
import { logMarketPriceColumnsForTable } from "../../../../../utils/columns";
import { defaultLogMarketPriceRow } from "../../../../../utils/rows";

export const LogMarketPriceTable = ({
  register,
  errors,
  control,
  watchedDetails,
  setValue,
}: TableComponentExtendedProps<LogMarketPrice, LogMarketPriceDetail>) => {
  const user = useContext(UserContext);
  useEffect(() => {
    watchedDetails?.forEach((detail, index) => {
      if (!detail) return;
      const top_diameter = detail.top_diameter ?? 0; // cm
      const length = detail.length ?? 0; // mm

      // 単材積(㎥) = (mm^3) / (100,000,000)
      const newUnitVolume =
        (top_diameter * top_diameter * length) / 100_000_000;
      if (detail.unit_volume !== newUnitVolume) {
        setValue(
          `log_market_price_details.${index}.unit_volume`,
          newUnitVolume,
          {
            shouldValidate: true,
            shouldDirty: true,
          },
        );
      }

      // 材積(㎥) = 単材積(㎥) × 本数
      const newTotalVolume = newUnitVolume;
      if (detail.total_volume !== newTotalVolume) {
        setValue(
          `log_market_price_details.${index}.total_volume`,
          newTotalVolume,
          {
            shouldValidate: true,
            shouldDirty: true,
          },
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDetails]);

  return (
    <TableComponent
      register={register}
      errors={errors}
      fieldArrayName="log_market_price_details"
      columns={logMarketPriceColumnsForTable}
      defaultRow={defaultLogMarketPriceRow}
      disabled={!user?.is_admin} // Pass the disabled prop
      control={control}
    />
  );
};
