import { MouseEvent, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  useMediaQuery,
  ListItemText,
  useTheme,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { UnifiedItem, useGetItemUsers } from "../../hooks/useUserHook";
import Avatar from "../../uis/Avatar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { OrderDetailModal } from "../../uis/Modals/OrderDetailModal";
import { useReceiveOrder } from "../../hooks/useOrderHook";
import { ItemType, OrderDetailModalType } from "../../utils/types/item_type";
import { useDownloadImages } from "../../hooks/S3/useS3ImageHook";
import {
  OrderStatusEnum,
  OrderStatusType,
  OrderStatusWithApplying,
} from "../../utils/types/general_type";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Talk } from "../../uis/Talk";
import { Title } from "../../uis/Title";
import { PaginationComponent } from "../../uis/Pagination";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";
import { ORDER_BASE_PATH } from "../../utils/constant";
import { Industry } from "../../utils/industry";

interface CommonRequestListProps {
  filterType: OrderStatusWithApplying;
}
export const CommonRequestList = ({ filterType }: CommonRequestListProps) => {
  // カスタムフック（ページング操作など）
  const { page, handlePageChange } = useSearchAndPaginationHook();

  // API リクエスト（注文情報を取得）
  const { data, error, isPending } = useGetItemUsers({
    filterType: filterType,
    page,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // 注文の承諾/保留/辞退
  const { mutateAsync: mutateReceiveOrderAsync } = useReceiveOrder();
  // 設計図などのダウンロード
  const { mutateAsync: mutateDownloadAsync } = useDownloadImages();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<UnifiedItem | null>(null);

  // 各注文ごとのdisabled状態を管理するためのステート
  const [disabledOrders, setDisabledOrders] = useState<Record<string, boolean>>(
    {},
  );
  const [order, setOrder] = useState<OrderDetailModalType | null>(null);

  // メニューの開閉状態
  const open = Boolean(anchorEl);

  const handleMenuOpen = (
    event: MouseEvent<HTMLElement>,
    item: UnifiedItem,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  // 注文を承諾/保留/辞退するハンドラ
  const handleClick = async (
    key: string,
    status: OrderStatusType,
    userId?: string,
    id?: number,
    type?: ItemType,
  ) => {
    // ボタンを連打できないように disabled 状態に
    setDisabledOrders((prev) => ({ ...prev, [key]: true }));

    if (!userId) {
      toast.error("ユーザーが存在しません");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    if (!id || !type) {
      toast.error("エラーが発生しました。");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    try {
      await mutateReceiveOrderAsync({
        id,
        type,
        status,
        pageStatus: filterType,
      });
    } catch (error) {
      console.error(error);
    } finally {
      // API 終了後にボタンを再び有効に
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
    }
  };

  // 設計図等のダウンロード
  const handleDownload = async (id?: number) => {
    if (!id) {
      toast.error("idが存在しません");
      return;
    }
    try {
      await mutateDownloadAsync({ id, category: "blueprint" });
    } catch (error) {
      console.error(error);
    }
  };
  // ロード中やエラーの表示
  if (isPending) {
    return <Loading />;
  }
  if (error) {
    return <Error message={error?.message || "エラーが発生しました。"} />;
  }
  if (!data) {
    return (
      <Typography variant="h6" color="textSecondary">
        データが存在しません。
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        // 全体の外枠: 縦に並べて高さ固定
        width: "95%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        height: "90vh", // 画面の高さに合わせて調整
      }}
    >
      <Title
        title={`${filterType === "applying" ? "依頼" : filterType === "receiving" ? "承諾" : filterType === "pending" ? "保留" : "辞退"}リスト一覧`}
      />

      {/* リスト全体をスクロール可能にする */}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          overflowY: "auto", // 縦方向にスクロールバーを表示
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        {/* <SearchBox
        placeholder="会社を検索"
        isMobile={isMobile}
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      /> */}
        <List sx={{ width: "100%" }}>
          {data.unifiedItems.map((it) => (
            <ListItem
              key={`${it.type}-${it.my_order.id}`}
              sx={{
                borderBottom: "1px solid black",
                width: "100%",
              }}
            >
              {/* 左カラム: ボタンやステータス表示 */}
              {filterType !== "denying" && (
                <Box width={{ width: "10%" }}>
                  {it.my_order.is_denied_sended && (
                    <Typography>一度辞退した依頼</Typography>
                  )}
                  {it.isButtonShown && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      endIcon={<KeyboardArrowDownIcon />}
                      onClick={(e) => handleMenuOpen(e, it)}
                      sx={{
                        borderColor: "black",
                        color: "black",
                        "&:hover": {
                          borderColor: "black",
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      選択
                    </Button>
                  )}
                </Box>
              )}

              {/* アバター */}
              <ListItemAvatar sx={{ width: "5%" }}>
                <Avatar user={it.item.orderer} isMobile={isMobile} />
              </ListItemAvatar>

              {/* 中央カラム: 会社名、タイトル、金額など */}
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box sx={{ width: "70%" }}>
                  <ListItemText
                    primary={
                      it.item.orderer.company_name || "会社名がありません"
                    }
                  />
                  <Box sx={{ mt: 1 }}>
                    <Box>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {it.item.title || "タイトルが存在しません"}
                      </Typography>
                    </Box>
                    {((it.type === "free" &&
                      it.item.orderer.industry === Industry.Market) ||
                      (it.type === "log" &&
                        it.item.orderer.industry === Industry.Market)) && (
                      <Box>
                        {it.item.total_item_and_detail_amount &&
                        it.item.total_item_and_detail_amount !== null ? (
                          <Typography variant="body2" color="text.secondary">
                            相手側の見積もり金額（合計）: ¥
                            {it.item.total_item_and_detail_amount.toLocaleString()}
                            {it.item.total_item_and_detail_amount < 50 && (
                              <span>
                                （見積もり金額は50円以上でなければなりません）
                              </span>
                            )}
                          </Typography>
                        ) : (
                          <Typography variant="body2" color="text.secondary">
                            相手側の見積もり金額のデータが存在しません
                          </Typography>
                        )}
                      </Box>
                    )}
                    <Box>
                      {it.my_order.total_order_and_detail_amount &&
                      it.my_order.total_order_and_detail_amount !== null ? (
                        <Typography variant="body2" color="text.secondary">
                          自分の見積もり金額（合計）: ¥
                          {it.my_order.total_order_and_detail_amount.toLocaleString()}
                          {it.my_order.total_order_and_detail_amount < 50 && (
                            <span>
                              （見積もり金額は50円以上でなければなりません）
                            </span>
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          自分の見積もり金額のデータが存在しません
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                {/* トークボタン */}
                <Talk userId={it.item.orderer.user_id} />
              </Box>
              {/* 右カラム: 詳細確認、見積もり作成など */}
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "30%",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      mr: 1,
                      color: "black",
                      borderColor: "black",
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderColor: "black",
                      },
                    }}
                    onClick={() => {
                      setOrder({
                        itemType: it.type,
                        item: it.item,
                        order: it.my_order,
                        detail: {
                          log_details: it.log_details,
                          wood_details: it.wood_details,
                          free_details: it.free_details,
                          blueprint_details: it.blueprint_details,
                        },
                      });
                    }}
                  >
                    詳細確認
                  </Button>
                  {(filterType === "applying" || filterType === "pending") && (
                    <Box sx={{ display: "flex" }}>
                      {/* 木材の場合は見積もりを作成するボタン */}
                      {(it.type === "wood" ||
                        it.type === "free" ||
                        it.type === "log") && (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            mr: 1,
                            color: "red",
                            borderColor: "red",
                            backgroundColor: "white",
                            "&:hover": {
                              backgroundColor: "rgba(255, 0, 0, 0.1)",
                              borderColor: "red",
                            },
                          }}
                          onClick={() =>
                            navigate(
                              `${ORDER_BASE_PATH}/${it.type}_quotation/${it.my_order.id}`,
                            )
                          }
                        >
                          見積もり金額を作成する
                        </Button>
                      )}
                      {/* 設計図の場合 */}
                      {it.type === "blueprint" && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() =>
                              navigate(
                                `${ORDER_BASE_PATH}/create_blueprint_detail_list/${it.my_order.id}`,
                              )
                            }
                          >
                            木材リストを作成する
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => handleDownload(it.item.id)}
                          >
                            設計図面をダウンロードする
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* ページネーションはスクロール領域の外へ配置 */}
      {data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isMobile={isMobile}
        />
      )}
      {/* メニュー (承諾 / 保留 / 辞退) */}
      {filterType !== "denying" && (
        <Menu
          anchorEl={anchorEl}
          open={open && selectedItem !== null}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            elevation: 3,
            sx: {
              mt: 1,
              minWidth: 150,
            },
          }}
        >
          {selectedItem?.my_order.status !== OrderStatusEnum.is_received && (
            <MenuItem
              onClick={() => {
                if (selectedItem) {
                  handleClick(
                    `${selectedItem.type}-${selectedItem.item.id}`,
                    "receiving",
                    selectedItem.item.orderer?.user_id,
                    selectedItem.item.id,
                    selectedItem.type,
                  );
                }
                handleMenuClose();
              }}
              sx={{ color: "black" }}
              disabled={
                selectedItem
                  ? disabledOrders[
                      `${selectedItem.type}-${selectedItem.item.id}`
                    ] || false
                  : false
              }
            >
              注文を承諾する
            </MenuItem>
          )}
          {selectedItem?.my_order.status !== OrderStatusEnum.is_pending && (
            <MenuItem
              onClick={() => {
                if (selectedItem) {
                  handleClick(
                    `${selectedItem.type}-${selectedItem.item.id}`,
                    "pending",
                    selectedItem.item.orderer?.user_id,
                    selectedItem.item.id,
                    selectedItem.type,
                  );
                }
                handleMenuClose();
              }}
              sx={{ color: "black" }}
              disabled={
                selectedItem
                  ? disabledOrders[
                      `${selectedItem.type}-${selectedItem.item.id}`
                    ] || false
                  : false
              }
            >
              注文を保留する
            </MenuItem>
          )}
          {selectedItem?.my_order.status !== OrderStatusEnum.is_denied && (
            <MenuItem
              onClick={() => {
                if (selectedItem) {
                  handleClick(
                    `${selectedItem.type}-${selectedItem.item.id}`,
                    "denying",
                    selectedItem.item.orderer?.user_id,
                    selectedItem.item.id,
                    selectedItem.type,
                  );
                }
                handleMenuClose();
              }}
              sx={{ color: "black" }}
              disabled={
                selectedItem
                  ? disabledOrders[
                      `${selectedItem.type}-${selectedItem.item.id}`
                    ] || false
                  : false
              }
            >
              注文を辞退する
            </MenuItem>
          )}
        </Menu>
      )}
      {/* 詳細確認モーダル */}
      {order !== null && order.item && (
        <OrderDetailModal
          open={order !== null}
          onClose={() => setOrder(null)}
          itemType={order.itemType}
          item={order.item}
          order={order.order}
          detail={order.detail}
        />
      )}
    </Box>
  );
};
