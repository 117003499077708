import { Box } from "@mui/material";
import { FileDownload } from "../../uis/File/FileDownload";
import { RenderDetails } from "../../uis/RenderDetails";
import { ReadonlyOrderMessageRoom } from "../../hooks/useOrderMessageRoomHook";
import { ItemType } from "../../utils/types/item_type";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

interface DetailsTabProps {
  data: ReadonlyOrderMessageRoom;
  type?: ItemType;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ data, type }) => {
  const user = useContext(UserContext);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "95%",
        margin: "0 auto",
        bgcolor: "background.paper",
      }}
    >
      <Box sx={{ width: "100%", mt: 1 }}>
        <RenderDetails
          item={data.order.item}
          detail={{
            log_details: data.log_details,
            free_details: data.free_details,
            blueprint_details: data.blueprint_details,
            wood_details: data.wood_details,
          }}
          order={data.order}
        />
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-around" }}>
          <FileDownload
            file={{ id: data.order.quotation?.id, itemType: type }}
            category="quotation"
          />
          <FileDownload
            file={{ id: data.order.order_form?.id, itemType: type }}
            category="order_form"
          />
          {data.order.delivery_note && (
            <FileDownload
              file={{
                id: data.order.delivery_note.id,
                itemType: type,
                isLegalityVerifiedChecked:
                  data.order.delivery_note.is_legality_verified_checked,
              }}
              category="delivery_note"
              isLegalityVerifiedShown={
                Boolean(
                  data.order.logging_notice_or_forest_management_plan_number ||
                    data.order
                      .logging_notice_or_forest_management_plan_number_file_path,
                ) && data.order.item.orderer_id === user?.user_id
              }
            />
          )}
          {data.order.invoice && (
            <FileDownload
              file={{ id: data.order.invoice.id, itemType: type }}
              category="invoice"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
