import {
  Box,
  TextField,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  UseFormRegister,
  FieldErrors,
  Path,
  FieldValues,
  ArrayPath,
  FieldArray,
  Control,
  useWatch,
  useFieldArray,
  UseFormSetValue,
} from "react-hook-form";
import { InputAdornment } from "@mui/material";
import { formatNumberNoExponential } from "../utils/number";
import {
  LogMarketPriceDetail,
  LogMarketPriceDetails,
} from "../hooks/useLogMarketPriceHook";
import { LogDetail } from "../hooks/Log/type";

/**
 * 列サイズから、TableCellの最小幅を返すヘルパー
 */
function getCellMinWidth(colSize: ColumnDefinitionForTable<any>["colSize"]) {
  switch (colSize) {
    case "small":
      return 90; // 幅・高さ・長さ・本数など(整数向け)
    case "medium":
      return 100; // 名称・樹種・素材など(文字列)
    case "large":
      return 120; // 単材積/材積(表示のみで大きめ)
  }
}

// 共通のプロパティを持つ基本型
export interface TableComponentProps<TFormValues extends FieldValues> {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrors<TFormValues>;
  disabled?: boolean;
  control: Control<TFormValues>;
  logMarketPriceDetails?: LogMarketPriceDetails; // LogMarketPriceDetailの配列を追加
}

export interface TableComponentExtendedProps<
  TFormValues extends FieldValues,
  TFormDetailValues,
> extends TableComponentProps<TFormValues> {
  watchedDetails?: ReadonlyArray<TFormDetailValues>;
  watchedTotalAmount?: number;
  setValue: UseFormSetValue<TFormValues>;
}

// カラム定義
export interface ColumnDefinitionForTable<TFieldValues> {
  label: string;
  field: keyof TFieldValues & string;
  type?: "text" | "number";
  step?: string;
  displayOnly?: true;
  unitLabel?: "mm" | "本" | "円" | "m³" | "cm" | "m";
  colSize: "small" | "medium" | "large"; // 列幅を指定
  canBeEdited?: true;
}

// TableComponentPropsWithValue が TableComponentProps を継承
interface TableComponentPropsWithValue<
  TFormValues extends FieldValues,
  TFieldArrayName extends ArrayPath<TFormValues>,
> extends TableComponentProps<TFormValues> {
  fieldArrayName: TFieldArrayName;
  columns: ReadonlyArray<
    ColumnDefinitionForTable<TFormValues[TFieldArrayName][number]>
  >;
  additionalColumns?: ReadonlyArray<
    ColumnDefinitionForTable<TFormValues[TFieldArrayName][number]>
  >;
  defaultRow: FieldArray<TFormValues, TFieldArrayName>;
  control: Control<TFormValues>;
}

const EXCLUDED_FIELDS = [
  "id",
  "log_id",
  "unit_volume",
  "total_volume",
  "quantity",
  "amount",
  "log_quotation_amount",
  "created_at",
  "updated_at",
] as const;

/**
 * 除外フィールドの型を定義（型安全性のため）
 */
type ExcludedField = (typeof EXCLUDED_FIELDS)[number];

/**
 * 値が空または0かどうかを判定する型安全な関数
 */
function isEmptyOrZero(value: unknown): boolean {
  return value === "" || value === 0;
}

/**
 * フィールドが比較対象から除外すべきかを判定する関数
 */
function shouldExcludeField(key: string, value: unknown): boolean {
  return isEmptyOrZero(value) || EXCLUDED_FIELDS.includes(key as ExcludedField);
}

/**
 * getUniqueFieldValues - 一致するマーケット価格詳細を検索する関数
 *
 * logDetailオブジェクトの有効なフィールド値と一致するlogMarketPriceDetailsの要素を返します。
 *
 * @param logDetail - 比較元となるログ詳細オブジェクト
 * @param logMarketPriceDetails - 比較対象となるマーケット価格詳細の配列
 * @returns 一致したマーケット価格詳細の配列
 */
function getUniqueFieldValues(
  logDetail: LogDetail | null | undefined,
  logMarketPriceDetails: LogMarketPriceDetails = [],
): LogMarketPriceDetails {
  // 入力値の検証: logDetailが存在しない場合は空配列を返す
  if (!logDetail) {
    return [];
  }

  // パフォーマンス最適化: 早期リターンのループを使用
  let hasNonEmptyValues = false;
  for (const value of Object.values(logDetail)) {
    if (!isEmptyOrZero(value)) {
      hasNonEmptyValues = true;
      break;
    }
  }

  if (!hasNonEmptyValues) {
    return logMarketPriceDetails;
  }

  // 比較に使用する有効なフィールドのみをフィルタリング
  const validEntries = Object.entries(logDetail).filter(
    ([key, value]) => !shouldExcludeField(key, value),
  );

  // 有効なエントリがない場合は、そのままlogMarketPriceDetailsを返す
  if (validEntries.length === 0) {
    return logMarketPriceDetails;
  }

  // 一致するマーケット価格詳細を検索
  return logMarketPriceDetails.filter((marketPriceDetail) => {
    // nullまたはundefinedのチェック
    if (!marketPriceDetail) {
      return false;
    }

    // すべてのフィルタリングされたフィールドが一致するかを確認
    for (const [key, value] of validEntries) {
      // 型安全にキーの存在を確認
      if (!(key in marketPriceDetail)) {
        return false;
      }

      // 値の比較
      if (marketPriceDetail[key as keyof LogMarketPriceDetail] !== value) {
        return false;
      }
    }

    // すべてのチェックをパスした場合は一致と判断
    return true;
  });
}
export const TableComponent = <
  TFormValues extends FieldValues,
  TFieldArrayName extends ArrayPath<TFormValues>,
>({
  register,
  errors,
  fieldArrayName,
  columns,
  additionalColumns = [],
  defaultRow,
  disabled = false,
  control,
  logMarketPriceDetails,
}: TableComponentPropsWithValue<TFormValues, TFieldArrayName>) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  const watchedFieldValues = useWatch({
    control,
    // ↓ 重要: 「as Path<TFormValues>」で型アサーション
    name: fieldArrayName as Path<TFormValues>,
  }) as TFormValues[TFieldArrayName] | undefined;

  const unifiedColumns = [...columns, ...additionalColumns];

  return (
    <>
      {/* テーブル入力 */}
      <Box sx={{ overflowX: "auto", marginBottom: 3 }}>
        <Table
          size="small"
          sx={{
            width: "100%",
            tableLayout: "auto",
            borderCollapse: "collapse",
          }}
        >
          <TableHead>
            <TableRow>
              {unifiedColumns.map((col) => (
                <TableCell
                  key={col.field}
                  sx={{
                    padding: "2px 4px", // marginを詰める: padding小さめ
                    minWidth: getCellMinWidth(col.colSize), // 列幅
                  }}
                >
                  {col.label}
                </TableCell>
              ))}
              {!disabled && (
                <TableCell sx={{ padding: "2px 4px" }}>操作</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => {
              // ★ watchedFieldValues から各行の最新状態を取得
              const rowValue = watchedFieldValues?.[index];
              return (
                <TableRow key={item.id}>
                  {unifiedColumns.map((col) => {
                    // フィールド名
                    const fieldPath =
                      `${fieldArrayName}.${index}.${col.field}` as Path<TFormValues>;

                    // バリデーションエラーを取得
                    const error = (errors as any)[fieldArrayName]?.[index]?.[
                      col.field
                    ];

                    // rowValue が undefined の場合もあり得るので安全に取り出す
                    const cellValue = rowValue ? rowValue[col.field] : "";

                    // highlightCell ロジック（必須チェックなど）
                    const highlightCell =
                      !error &&
                      ((typeof cellValue === "string" && cellValue === "") ||
                        (typeof cellValue === "number" && cellValue === 0));

                    // displayOnly の場合
                    if (col.displayOnly) {
                      // 小数を指数表記(e)ではなく通常表記に
                      const formattedValue =
                        typeof cellValue === "number"
                          ? formatNumberNoExponential(cellValue)
                          : (cellValue ?? "");

                      return (
                        <TableCell
                          key={col.field}
                          sx={{
                            padding: "2px 4px",
                            verticalAlign: "center",
                            minWidth: getCellMinWidth(col.colSize),
                          }}
                        >
                          <span style={{ color: "#333", fontSize: "1rem" }}>
                            {formattedValue}
                            {col.unitLabel ? col.unitLabel : ""}
                          </span>
                        </TableCell>
                      );
                    }

                    const isSelectField =
                      logMarketPriceDetails?.length &&
                      col.field !== "quantity" &&
                      col.field !== "amount" &&
                      !disabled;
                    const filteredLogMarketPriceDetails = getUniqueFieldValues(
                      rowValue,
                      logMarketPriceDetails,
                    );

                    // 通常の入力欄 (TextField) を表示
                    return (
                      <TableCell
                        key={col.field}
                        sx={{
                          padding: "2px 4px",
                          verticalAlign: "center",
                          minWidth: getCellMinWidth(col.colSize),
                        }}
                      >
                        {disabled && !col.canBeEdited ? (
                          // 読み取り専用モード
                          <Box
                            sx={{
                              border: highlightCell
                                ? "1px solid red"
                                : "1px solid transparent",
                              padding: "2px",
                              borderRadius: "4px",
                              "& span": { color: "gray", fontSize: "1rem" },
                            }}
                          >
                            <span>
                              {col.type === "number"
                                ? formatNumberNoExponential(
                                    Number(cellValue) || 0,
                                  )
                                : cellValue}
                              {col.unitLabel ? col.unitLabel : ""}
                            </span>
                          </Box>
                        ) : isSelectField ? (
                          // セレクトボックス表示
                          <FormControl
                            fullWidth
                            size="small"
                            margin="dense"
                            error={!!error}
                            sx={{ m: 0 }}
                          >
                            <Select
                              {...register(fieldPath)}
                              value={cellValue}
                              displayEmpty
                              sx={{ minHeight: "40px" }}
                            >
                              <MenuItem value={col.type === "number" ? 0 : ""}>
                                <em>選択してください</em>
                              </MenuItem>
                              {filteredLogMarketPriceDetails?.map(
                                (detail: LogMarketPriceDetail) => {
                                  return Object.entries(detail)
                                    .filter(([key, _]) => key === col.field)
                                    .map(([key, value]) => (
                                      <MenuItem key={key} value={value}>
                                        {value}
                                        {col.unitLabel
                                          ? ` ${col.unitLabel}`
                                          : ""}
                                      </MenuItem>
                                    ));
                                },
                              )}
                            </Select>
                            {error && (
                              <FormHelperText>
                                {error.type === "validate"
                                  ? error.message
                                  : "入力エラー"}
                              </FormHelperText>
                            )}
                          </FormControl>
                        ) : (
                          // 入力欄
                          <TextField
                            {...register(fieldPath, {
                              valueAsNumber: col.type === "number",
                              validate: (value) =>
                                col.type !== "number" ||
                                value >= 0 ||
                                "0以上の数値を入力してください",
                            })}
                            type={col.type}
                            size="small"
                            margin="dense"
                            fullWidth
                            sx={{ m: 0 }}
                            inputProps={{
                              step: col.step ?? "any",
                              min: col.type === "number" ? 0 : undefined,
                            }}
                            // 例: unitLabel="mm" とか "本" とか
                            InputProps={
                              col.unitLabel
                                ? {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {col.unitLabel}
                                      </InputAdornment>
                                    ),
                                  }
                                : undefined
                            }
                            error={!!error}
                            helperText={
                              error && error.type === "validate"
                                ? error.message
                                : error && "入力エラー"
                            }
                            FormHelperTextProps={{
                              sx: { margin: 0, padding: 0 },
                            }}
                            style={{
                              borderColor: highlightCell ? "red" : undefined,
                            }}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  {!disabled && (
                    <TableCell sx={{ padding: "4px 8px" }}>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {!disabled && (
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 3 }}
        >
          <Button variant="contained" onClick={() => append(defaultRow)}>
            行を追加
          </Button>
        </Box>
      )}
    </>
  );
};
