import { useLocation, useNavigate } from "react-router-dom";
import { SearchTransaction, SearchTransactionSchema } from "../../utils/schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Box, Button, Grid, Paper } from "@mui/material";
import FormField from "../Forms/FormField";

interface SearchTransactionBoxProps {
  setSearchParams: React.Dispatch<
    React.SetStateAction<{
      startDate: string;
      endDate: string;
      type: "order" | "receive" | "both";
    }>
  >;
  getQueryParams: () => SearchTransaction;
}

export const SearchTransactionBox = ({
  setSearchParams,
  getQueryParams,
}: SearchTransactionBoxProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  // フォームの状態
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SearchTransaction>({
    resolver: zodResolver(SearchTransactionSchema),
    defaultValues: getQueryParams(),
  });
  // URLのクエリパラメータが変更された場合にフォームを更新
  useEffect(() => {
    const newParams = getQueryParams();
    setSearchParams(newParams);
    reset(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, reset]);

  // フォーム送信ハンドラ
  const onSubmit = (data: SearchTransaction) => {
    setSearchParams(data);

    // クエリパラメータを更新
    const params = new URLSearchParams();
    params.append("startDate", data.startDate);
    params.append("endDate", data.endDate);
    params.append("type", data.type);
    navigate({ search: params.toString() });
  };

  // ヘルパー関数で動的に月を生成（現在の月から10年前まで）
  const generateMonthOptions = () => {
    const options = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // 1-12
    const startYear = currentYear - 10;

    for (let year = currentYear; year >= startYear; year--) {
      for (let month = 12; month >= 1; month--) {
        // 現在の年の場合、現在の月まで
        if (year === currentYear && month > currentMonth) continue;
        const value = `${year}-${String(month).padStart(2, "0")}`;
        const label = `${year}年 ${month}月`;
        options.push({ label, value });
      }
    }
    return options;
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} mb={4}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Grid container spacing={2} alignItems="center">
          {/* 開始月 */}
          <Grid item xs={12} sm={4}>
            <FormField
              control={control}
              register={register}
              name="startDate"
              isError={!!errors.startDate}
              errorMessage={errors.startDate?.message}
              formType="select"
              options={generateMonthOptions()}
              placeholder="開始月を選択してください"
              labelName="開始月"
            />
          </Grid>
          {/* 終了月 */}
          <Grid item xs={12} sm={4}>
            <FormField
              control={control}
              register={register}
              name="endDate"
              isError={!!errors.endDate}
              errorMessage={errors.endDate?.message}
              formType="select"
              options={generateMonthOptions()}
              placeholder="終了月を選択してください"
              labelName="終了月"
            />
          </Grid>
          {/* タイプ */}
          <Grid item xs={12} sm={4}>
            <FormField
              control={control}
              register={register}
              name="type"
              isError={!!errors.type}
              errorMessage={errors.type?.message}
              formType="select"
              options={[
                {
                  label: "支払い側",
                  value: "order",
                },
                {
                  label: "受け取り側",
                  value: "receive",
                },
                {
                  label: "両方",
                  value: "both",
                },
              ]}
              placeholder="タイプを選択してください"
              labelName="タイプ"
            />
          </Grid>
          {/* 送信ボタン */}
          <Grid item xs={12} sm={12} textAlign="right">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              sx={{ minWidth: "120px" }}
            >
              検索
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
