import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SearchBox } from "../../uis/SearchBox/SearchBox";
import Avatar from "../../uis/Avatar";
import { Error } from "../../uis/Error";
import { useState } from "react";
import {
  ReadonlyNotificationResponse,
  SearchTextWIthPaginationParamsWithIsTemporallyStored,
} from "../../hooks/useNotificationHook";
import { PaginationComponent } from "../../uis/Pagination";
import { UseQueryResult } from "@tanstack/react-query";
import { useSearchAndPaginationHook } from "../../hooks/useSearchAndPaginationHook";
import { useNavigate } from "react-router-dom";
import { ChangeButtonsModal } from "../../uis/Modals/ChangeButtonsModal";
import { formatJapaneseDate } from "../../utils/time";

interface NotificationListProps {
  useGetNotifications: ({
    searchText,
    page,
    pageSize,
    isTemporarilyStored,
    isPublished,
  }: SearchTextWIthPaginationParamsWithIsTemporallyStored) => UseQueryResult<
    ReadonlyNotificationResponse,
    Error
  >;
  isHome?: boolean;
  isTemporarilyStored?: boolean;
  isPublished?: boolean;
}

export const NotificationList = ({
  useGetNotifications,
  isHome = true,
  isTemporarilyStored = false,
  isPublished = true,
}: NotificationListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const [showModal, setShowModal] = useState(false); // 検索ボックスがクリックされたかを管理
  const navigate = useNavigate();

  // カスタムフック
  const { page, searchText, handlePageChange, handleSearchChange } =
    useSearchAndPaginationHook();

  // APIフック（ここで通知データを取得）
  const { data, error: notificationsError } = useGetNotifications({
    searchText,
    page,
    isTemporarilyStored,
    isPublished,
  });

  // エラー時の処理
  if (notificationsError) {
    return <Error message={notificationsError?.message} />;
  }

  // 検索ボックスのクリックイベント
  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  // 通知クリック時のナビゲーション関数
  const handleNotificationClick = (notificationId: string | number) => {
    const path = isTemporarilyStored
      ? `/home/notification/${notificationId}`
      : `/home/notification/detail/${notificationId}`;
    navigate(path);
  };

  // 通知アイテムのキーダウンイベントハンドラ (アクセシビリティ)
  const handleNotificationKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    notificationId: string | number,
  ) => {
    // Enter または Space キーが押されたらクリックと同様の処理を実行
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault(); // Space キーでのページスクロールを抑制
      handleNotificationClick(notificationId);
    }
  };
  return (
    <Box
      sx={{
        width: "95%",
        height: "100%", // 全体コンテナの高さを固定
        display: "flex",
        flexDirection: "column", // 垂直に配置
        margin: "0 auto", // 横中央寄せ
      }}
    >
      {/* 検索ボックス */}
      <SearchBox
        isMobile
        placeholder="お知らせを検索(会社名・タイトル)"
        onClick={handleSearchBoxClick}
        onChange={handleSearchChange}
        value={searchText}
        searchClicked={searchClicked}
        showMenubar={isMobile}
        onMenuClick={() => setShowModal(true)}
      />
      {/* 通知リスト部分（スクロール可能領域） */}
      <Box
        sx={{
          flex: 1, // 残りの縦スペースをすべて使用
          width: "100%",
          marginTop: isMobile ? 1 : 2,
          overflowY: "auto", // 縦方向にスクロールバーを表示
        }}
      >
        {data && data.notifications && data.notifications.length > 0 ? (
          data.notifications.map((notification) => (
            <Box
              key={notification.id}
              role="button" // アクセシビリティ: この要素がボタンのように動作することを示す
              tabIndex={0} // アクセシビリティ: Tab キーでフォーカス可能にする
              aria-label={`お知らせ: ${notification.user.company_name} - ${notification.title}`} // スクリーンリーダー用のラベル
              sx={{
                display: "flex",
                alignItems: "flex-start", // アイコンとテキストを上端揃えに
                gap: 2, // 要素間のスペース
                marginBottom: isMobile ? 1.5 : 2, // 間隔を少し調整
                padding: isMobile ? 1.5 : 2, // パディングを少し調整
                border: `1px solid ${theme.palette.divider}`, // theme の divider 色を使用
                borderRadius: "8px",
                cursor: "pointer",
                backgroundColor: theme.palette.background.paper, // 背景色を設定
                transition:
                  "background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out", // スムーズな遷移効果
                // ホバー時のスタイル
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  boxShadow: theme.shadows[1], // 軽い影を追加
                },
                // フォーカス時のスタイル (キーボード操作向け)
                "&:focus-visible": {
                  outline: `2px solid ${theme.palette.primary.main}`,
                  outlineOffset: "2px",
                  backgroundColor: theme.palette.action.hover, // ホバーと同じ背景色
                },
              }}
              onClick={() => handleNotificationClick(notification.id)}
              onKeyDown={(e) => handleNotificationKeyDown(e, notification.id)}
            >
              {/* 会社アイコン */}
              <Box sx={{ flexShrink: 0, mt: 0.5 }}>
                {" "}
                {/* アイコンが縮まないようにし、少し上にマージン */}
                <Avatar user={notification.user} isMobile={isMobile} />
              </Box>
              {/* 会社名とお知らせ内容 */}
              <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                {/* flexGrowで幅を伸ばし、minWidth: 0 でテキストの省略を可能に */}
                <Typography
                  variant={isMobile ? "subtitle1" : "h6"} // 少し大きめのフォントに
                  component="div" // セマンティックな理由で div に変更（任意）
                  sx={{ fontWeight: "bold", mb: 0.5 }} // 太字にして、下に少しマージン
                >
                  {notification.user.company_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary" // theme のセカンダリテキスト色を使用
                  sx={{
                    // 長いタイトルを考慮して複数行許容しつつ、最大行数を設定（任意）
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2, // 例: 最大2行まで表示
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minHeight: "2.5em", // 2行分の高さを確保（概算）
                  }}
                >
                  {notification.title}
                </Typography>
              </Box>
              {/* 右端の更新日時エリア */}
              <Box
                sx={{
                  marginLeft: "auto",
                  textAlign: "right",
                  flexShrink: 0,
                  pt: 0.5,
                }}
              >
                {" "}
                {/* 右寄せ、縮小防止、上パディング */}
                <Typography
                  variant="caption" // 小さめのフォント
                  color="text.secondary"
                  sx={{ whiteSpace: "nowrap" }} // 日時が改行されないように
                >
                  {/* updated_at をフォーマットして表示 */}
                  {formatJapaneseDate(notification.updated_at)}
                </Typography>
              </Box>
            </Box>
          ))
        ) : isHome ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "200px", // 高さは適切に調整
              border: `1px dashed ${theme.palette.divider}`,
              borderRadius: "8px",
              backgroundColor: theme.palette.background.default, // theme のデフォルト背景色
              textAlign: "center",
              padding: 3, // パディングを調整
              mt: 2, // 上にマージン
            }}
          >
            <Typography variant="h6" color="text.primary" gutterBottom>
              お知らせはありません
            </Typography>
            <Typography variant="body2" color="text.secondary">
              新しいお知らせが投稿されると、ここに表示されます。
            </Typography>
          </Box>
        ) : null}
      </Box>
      {/* ページネーション（スクロール領域外に配置） */}
      {data && data.totalPages > 1 && (
        <PaginationComponent
          totalPages={data.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isMobile={isMobile}
        />
      )}
      {showModal && (
        <ChangeButtonsModal
          open={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};
